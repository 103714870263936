import React from 'react'
import UnionSmall from './../../images/ArrowBottomWhite.svg'


const CapturingTheTacticCard = (props) => {
  return (
    <>
      <div className='captureTacticMain'>
        <p className='captureTxtMainPara'>{props.firstPara} <span className='spCaptureTact'>{props.firstSp}</span> 
        
        {props.secondPara}<span className='spCaptureTact'> {props.secondSp}</span> {props.thirdPara}<span></span></p>
         <img src={UnionSmall} alt="" className="unionSmall" />
         <img src={props.DSC04888} alt="" className="DSC04" />

    </div>
    </>
  )
}

export default CapturingTheTacticCard