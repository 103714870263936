import React from "react";
import "./ContextualModule.css";
import dropArrowPic from "./../../images/ArrowBottomBlackSmall.svg";
import mainPicModule from "./../../images/mono_front.png";
import UnionMainModule from "./../../images/ArrowBottomBlack.svg";
import circleModulePic from "./../../images/orangePointer.svg";
import upperPeep from "./../../images/upperPeep1.svg";
import lowerPeep from "./../../images/lowerPeepSmall.svg";
import lowerPeep1 from "./../../images/lowerPeepLong.svg";
import upperPeep1 from "./../../images/upperPeep1.svg";

const ContextualModule = () => {
  return (
    <>
      <div className="mainContainerModuleContext">
        <div>
          {/* <img src={summaryPic} alt="" className="summaryPicModule" /> */}
          <div className="topTextModulePic">
            <div className="topFirstRowConText">
              <div className="firstRowHarmonic">
                <p className="moduleTxt">
                  Pure <span className="spTxtModule">Harmonic</span> <br />
                  Tone{" "}
                </p>
                <a href="#monosoundengine" className="discoverTxt">
                  Discover{" "}
                  <img src={dropArrowPic} alt="" className="dropArrowImg" />{" "}
                </a>
              </div>

              <div className="parasFirstRowCont">
                <p className="moduleTxt">
                  <span className="spTxtModule">Contextual </span>
                  <br /> Modulation
                </p>
                <a href="#monocontextual" className="discoverTxt">
                  Discover{" "}
                  <img src={dropArrowPic} alt="" className="dropArrowImg" />
                </a>
              </div>
            </div>
          </div>
          <div className="mainPicPartModule">
            <div className="topImgMain">
              <img src={mainPicModule} alt="" className="mainImg" />
            </div>

            <div className="leftPurseHarmonicPic">
              <img src={lowerPeep} alt="" className="peepTopleftModuleImg" />
              <img src={circleModulePic} alt="" className="circleTopModule" />
            </div>

            <div className="rightPurseHarmonicPic">
              <img src={lowerPeep1} alt="" className="peepTopRightModuleImg" />

              <img src={circleModulePic} alt="" className="circleTopModule2" />
            </div>

            <div className="bottomPurseHarmonicPic">
              <img
                src={upperPeep}
                alt=""
                className="peepTBottomRightModuleImg"
              />
              <img
                src={circleModulePic}
                alt=""
                className="circleBottomModule2"
              />
            </div>
            <div className="bottomPeepCirclContMod">
              <div className="">
                <img src={upperPeep1} alt="" className="upperPeepBottomMod" />
                <img
                  src={circleModulePic}
                  alt=""
                  className="moduleCircleCont3"
                />
              </div>
            </div>
          </div>
          <div className="bottomTextModulePic">
            <div>
              <p className="moduleTxt">
                Randomize <br />
                <span className="spTxtModule">Anything</span>{" "}
              </p>
              <a href="#monorandomize" className="discoverTxt">
                Discover{" "}
                <img src={dropArrowPic} alt="" className="dropArrowImg" />{" "}
              </a>
            </div>
            <div className="simDiscDiv">
              <p className="moduleTxt">
                Simple <span className="spTxtModule">Yet Powerful</span>{" "}
                Sequencer
              </p>
              <a href="#monosequencer" className="discoverTxt">
                Discover{" "}
                <img src={dropArrowPic} alt="" className="dropArrowImg" />
              </a>
            </div>
          </div>
        </div>

        <div className="secondPartDivModule">
          <p className="secondPartTxtModule">
            <span className="spSecondParaModule">MONO</span> makes complex sound
            synthesis simple,{" "}
            <span className="spSecondParaModule">
              easily enabling a level of creativity yet not seen
            </span>{" "}
            in other hardware synthesizers.
          </p>

          <img src={UnionMainModule} alt="" className="moduleMainArrowImg" />
        </div>
      </div>
    </>
  );
};

export default ContextualModule;
