import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Row, Col } from "react-bootstrap";
import Lottie from "lottie-react";

import "./MorhphosTab.css";
import video from "./../../videos/Contextual_Mod_FINAL_S.mp4";
import arrow from "./../../images/ArrowRightWhite.svg";
import arrowLong from "./../../images/Arrow-LongIntr.svg";
import picHowWorks from "./../../images/howWorks.svg";
import VideoPlayer from "react-video-js-player";

import morphoContext1 from "./../../Lotties/morphos_context_0.json";
import morphoContext2 from "./../../Lotties/morphos_context_1.json";
import morphoContext3 from "./../../Lotties/morphos_context_2.json";
import morphoContext4 from "./../../Lotties/morphos_context_3.json";

const pageData = {
  pages: [
    {
      number: "01.",
      title: "Attenuverter Mode",
      text:
        "In the classic attenuverter mode, the CV input value is multiplied by the amount knob, and the result modulates the parameter. The rest position of the amount knob is dead center:, in this position no CV modulation is applied;, going right will increase the modulation by a positive amount;, while going left createsby a negative amount (i.e a reversed version of your input signal).",
      anim: morphoContext1,
      manualLink: "/morphos/manual#modulation-attenuverter-mode",
    },
    {
      number: "02.",
      title: "Special V/oct functions",
      text:
        "On the V/Oct inputs, the attenuverter mode acts differently, it has 5 mode selected with the amount knob. At the center position, the input is the classic 1V/Oct. On the left, you get a 0.5V/Oct and 0.25V/Oct mode, and on the right, 2V/Oct and 4V/Oct. Time to try out some microtonal melodies !",
      anim: morphoContext2,
      manualLink: "/morphos/manual#modulation-voct-mode",
    },
    {
      number: "03.",
      title: "Modulator Mode",
      text:
        "In the modulator mode, the internal modulation oscillator is enabled on the selected parameter. Adjust rate and amount, and waveform to your taste, and toggle between low frequency and high frequency modulation with the type switch. You can use this mode with or without CV, if a CV is connected it will scale the amount parameter.",
      anim: morphoContext3,
      manualLink: "/morphos/manual#modulation-modulator-mode",
    },
    {
      number: "04.",
      title: "Ping Envelope Mode",
      text:
        "In this mode, the module expect a trig envelope at its input. When triggered, it'll send a ping envelope to the parameter. Adjust the decay with the rate knob, the amount controls the amplification. Get powerful drum timbre in an instant !",
      anim: morphoContext4,
      manualLink: "/morphos/manual#modulation-ping-envelope-mode",
    },
  ],
};

class MorhphosTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentPage: 0 };
  }

  SlideInOut(page) {
    this.setState({
      slideOut: true,
      slideIn: false,
    });
    setTimeout(() => {
      this.setState({ currentPage: page });
      this.setState({
        slideOut: false,
        slideIn: true,
      });
    }, 500);
  }

  render() {
    return (
      <>
        <div className="mainMorTab">
          <div className="txtMainTabRand">
            <div className="tabDivLineTxt" onClick={() => this.SlideInOut(0)}>
              <p
                className={
                  this.state.currentPage === 0 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[0].number}{" "}
                <span className="tabDivLineFullTxt">
                  {pageData.pages[0].title}
                </span>
              </p>
              <div
                className={
                  this.state.currentPage === 0
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
            <div className="tabDivLineTxt" onClick={() => this.SlideInOut(1)}>
              <p
                className={
                  this.state.currentPage === 1 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[1].number}{" "}
                <span className="tabDivLineFullTxt">
                  {pageData.pages[1].title}
                </span>
              </p>
              <div
                className={
                  this.state.currentPage === 1
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
            <div className="tabDivLineTxt" onClick={() => this.SlideInOut(2)}>
              <p
                className={
                  this.state.currentPage === 2 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[2].number}{" "}
                <span className="tabDivLineFullTxt">
                  {pageData.pages[2].title}
                </span>
              </p>
              <div
                className={
                  this.state.currentPage === 2
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
            <div className="tabDivLineTxt" onClick={() => this.SlideInOut(3)}>
              <p
                className={
                  this.state.currentPage === 3 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[3].number}{" "}
                <span className="tabDivLineFullTxt">
                  {pageData.pages[3].title}
                </span>
              </p>
              <div
                className={
                  this.state.currentPage === 3
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
          </div>

          <Row className="pageRandDetail">
            <Col
              xl={6}
              lg={12}
              className={`randLeftElements ${
                this.state.slideIn ? "fadeIn" : ""
              } ${this.state.slideOut ? "fadeOut" : ""}`}
            >
              <p className="oneTxt">
                {pageData.pages[this.state.currentPage].number}
              </p>
              <div className="topDetailOnClick">
                <p className="pageRandTxtHeading">
                  {pageData.pages[this.state.currentPage].title}
                </p>
                <p className="mainParaPagRand">
                  {pageData.pages[this.state.currentPage].text}
                </p>
                <Link
                  to={pageData.pages[this.state.currentPage].manualLink}
                  className="txtLearnPageRand"
                >
                  Learn more in the manual{" "}
                  <img src={arrow} alt="" className="arrowImgSmall" />
                </Link>
              </div>
            </Col>
            <Col
              xl={6}
              lg={12}
              className={`randRightElements ${
                this.state.slideIn ? "slideIn" : ""
              } ${this.state.slideOut ? "slideOut" : ""}`}
            >
              <div className="randAnimContainer">
                <div className="randAnimEleMorpos">
                  <Lottie
                    animationData={pageData.pages[this.state.currentPage].anim}
                    loop={true}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <div className="howToWorkMainTabRand">
            {/* <img src={Video1} alt="" className="video1" /> */}

            <p className="howSWorkTxt">Here's how it works</p>
            <img src={picHowWorks} alt="" className="dropDownPointer" />

            <VideoPlayer className="videorandomizer" src={video} />
          </div>

          <div className="letsMakePart">
            <img src={arrowLong} alt="" className="downArrow" />
            <p className="letTxtRandTab">A full song with Morphos</p>
          </div>
        </div>
      </>
    );
  }
}

export default MorhphosTab;
