import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import btnArrow from "./../../images/ArrowRightWhite.svg";

const ThreeDMorphinHomeCard = props => {
  return (
    <>
      <Container fluid className="ThreeDMorphicContainer">
        <div className="backdrop-blur">
          <div className="anim-bg">
            <div className="anim-form-1"></div>
            <div className="anim-form-2"></div>
            <div className="anim-form-3"></div>
            <div className="anim-form-4"></div>
            <div className="anim-form-5"></div>
            <div className="anim-form-6"></div>
            <div className="anim-form-7"></div>
          </div>
        </div>
        <Row className="innerDataContainer3D">
          <Col lg={6} md={12} sm={12}>
            <div className="leftPic3dMor">
              <img src={props.leftPic} alt="" className="leftImg3Mor" />
            </div>
          </Col>
          <Col lg={6} md={12} sm={12} className="rightPartMorph">
            <p className="d3MorphingTxt">
              3D Morphing{" "}
              <span hidden className="tmTxt">
                TM
              </span>{" "}
              At Your Fingertips
            </p>
            <p className="txtNorand">NORAND</p>
            <img src={props.picNew} alt="" className="newPic" />
            <p className="txtMorphic">MORPHOS</p>

            <Link to={"/morphos"} className="btnDisMor">
              Discover MORPHOS
              <img src={btnArrow} alt="" className="imgArrow" />
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ThreeDMorphinHomeCard;
