import React from 'react'
import   UnionDigi from './../../images/ArrowDownBlack.svg'
const DigiBrainCardMK2 = (props) => {
  return (
    <>
    
    <div className='mainContainerDigitBrain'>

              


<div className='paraArrowPartDigi'>
<p className='digiBrainPara'>{props.firstParaDigi} <span className='spTxtDigi'>{props.firstSpDigi}</span> {props.secondParaDigi} 
<span className='spTxtDigi'>{props.secondSpDigi}</span>
    {props.thirdParaDigi}
</p>
<img src={UnionDigi} alt="" className="dropArrowImgDigi" />
</div>

</div>



<img src={props.DSC04771} alt="" className="backPicDSC04771" />

    </>
  )
}

export default DigiBrainCardMK2