import React from "react";
import { Nav, Row, Col } from "react-bootstrap";

import trigger from "../../ScrollTrigger";

class ManualMenu extends React.Component {
  render() {
    let items = [];
    for (var i = 0; i < this.props.manualData.chapterLinks.length; i++) {
      items.push(
        <li>
          <a
            key={i}
            href={this.props.manualData.chapterLinks[i].name}
            className={this.props.manualData.chapterLinks[i].class}
            style={
              this.props.currentSection === i
                ? { "font-weight": "700" }
                : { "font-weight": "inherit" }
            }
          >
            {this.props.manualData.chapterLinks[i].title}
          </a>
        </li>
      );
    }
    return items;
  }
}

class ManualContent extends React.PureComponent {
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }
  render() {
    return this.props.data;
  }
}

class ManualPage extends React.Component {
  constructor(props) {
    super(props);
    // Set initial state (ONLY ALLOWED IN CONSTRUCTOR)
    this.state = {
      currentSection: 0
    };
    this.pageContent = this.props.manualData.content;
  }

  componentDidMount() {
    trigger.add("[manual-section-trigger]", {
      toggle: {
        callback: {
          in: trigger => {
            return new Promise((resolve, reject) => {
              let currentId = trigger.element.getAttribute("id");
              for (
                var i = 0;
                i < this.props.manualData.chapterLinks.length;
                i++
              ) {
                if (
                  `#${currentId}` === this.props.manualData.chapterLinks[i].name
                ) {
                  this.setState({
                    currentSection: i
                  });
                }
              }
            });
          },
          out: trigger => {
            return new Promise((resolve, reject) => {});
          }
        }
      }
    });
  }

  render() {
    return (
      <Row>
        <Col lg={3} md={12} className="manual-rm-padding d-none d-lg-block">
          <Nav className="manual-nav" pills>
            <ManualMenu
              manualData={this.props.manualData}
              currentSection={this.state.currentSection}
            />
          </Nav>
        </Col>
        <Col className="manual-main-content" lg={9} md={12}>
          <ManualContent data={this.pageContent} />
        </Col>
      </Row>
    );
  }
}

export default ManualPage;
