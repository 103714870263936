import React from "react";
import ThreeMorphingProductCard from "../ThreeDMorphingProduct/ThreeMorphingProductCard";
import CCPic from "./../../images/mono_front_color_bg.png";
import "./NorandMonoMorphosProd.css";
const NorandMonoMorphosProd = () => {
  const DiscoverTxt = "Discover MONO";

  const d3Text = "Innovative Tools Inspire Creativity";
  const mTxt = "MONO";
  const mLink = "/mono";
  return (
    <>
      <div className="norandMonoMoProTop">
        <ThreeMorphingProductCard
          mTxt={mTxt}
          mLink={mLink}
          d3Text={d3Text}
          leftPic={CCPic}
          DiscoverTxt={DiscoverTxt}
        />
      </div>
    </>
  );
};

export default NorandMonoMorphosProd;
