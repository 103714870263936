import React from "react";
import _ from "lodash";
import arrowLongIntr from "./../../images/Arrow-LongIntr.svg";

import animMorphos0 from "./../../Lotties/morphos_morphing_0.json";
import animMorphos0_1 from "./../../Lotties/morphos_morphing_0_1.json";
import animMorphos1 from "./../../Lotties/morphos_morphing_1.json";
import animMorphos1_2 from "./../../Lotties/morphos_morphing_1_2.json";
import animMorphos2 from "./../../Lotties/morphos_morphing_2.json";
import animMorphos2_3 from "./../../Lotties/morphos_morphing_2_3.json";
import animMorphos3 from "./../../Lotties/morphos_morphing_3.json";
import animMorphos3_4 from "./../../Lotties/morphos_morphing_3_4.json";
import animMorphos4 from "./../../Lotties/morphos_morphing_4.json";
import animMorphos4_5 from "./../../Lotties/morphos_morphing_4_5.json";
import animMorphos5 from "./../../Lotties/morphos_morphing_5.json";
import animMorphos5_6 from "./../../Lotties/morphos_morphing_5_6.json";
import animMorphos6 from "./../../Lotties/morphos_morphing_6.json";

import emptyAnim from "./../../Lotties/mono_context_osc_empty.json";

import Lottie from "lottie-react";

import "./../IntroContextualModule/IntroContextualModule.css";
import "./Intro3DMorphing.css";
import trigger from "../../ScrollTrigger";

const pageData = {
  pages: [
    {
      number: "01",
      text: "Each oscillator has a touch sensitive strip of 4 presets",
      subtext: (
        <>
          MORPHOS' preset strips are groups of four{" "}
          <strong>specially developed FSR sensors</strong> that can precisely
          detect vertical, horizontal and pressure information from the pressure
          distribution of your finger.
        </>
      ),
      linkToManual: null,
    },
    {
      number: "02",
      text: "You can morph adjacent presets with your finger",
      subtext: (
        <>
          That is traditional <strong>single dimmension morphing</strong>,
          between 2 presets.
        </>
      ),
      linkToManual: null,
    },
    {
      number: "03",
      text: "There are 3 layers - X, Y and Z - of 4 presets each.",
      subtext: (
        <>
          Each preset group is called <strong>a layer </strong>.
        </>
      ),
      linkToManual: null,
    },
    {
      number: "04",
      text: "Enter 3D Morphing mode to morph between layers",
      subtext: (
        <>
          <strong>Slide vertically</strong> to morph between the{" "}
          <strong>X preset </strong>
          you're touching and the related <strong>Y preset</strong>.
        </>
      ),
      linkToManual: "#vertical-morphing",
    },
    {
      number: "05",
      text: "Enter 3D Morphing mode to morph between layers",
      subtext: (
        <>
          The touch strip also <strong>detects pressure</strong> to morph
          between the <strong>X preset</strong> you're touching and the related{" "}
          <strong>Z preset</strong>.
        </>
      ),
      linkToManual: "#pressure-morphing",
    },
    {
      number: "06",
      text: "You have 48 user presets to morph between.",
      subtext: (
        <>
          Distributed across <strong>4 banks</strong> containing 3 layers of 4
          presets each.
        </>
      ),
      linkToManual: null,
    },
    {
      number: "07",
      text: "Automate and sequence your morphing with CV",
      subtext: (
        <>
          There are <strong>3 dedicated CV</strong> inputs for the X, Y and Z
          direction.
        </>
      ),
      linkToManual: null,
    },
    {
      number: "",
      text: "",
      subtext: "",
      linkToManual: null,
    },
  ],
  anim: [
    {
      animMorphos: animMorphos0,
      animOsc: emptyAnim,
      nextState: 0,
      currentText: 0,
      shouldLoop: true,
    },
    {
      animMorphos: animMorphos0,
      animOsc: emptyAnim,
      nextState: 2,
      currentText: 0,
      shouldLoop: false,
    },
    {
      animMorphos: animMorphos0,
      animOsc: emptyAnim,
      nextState: 2,
      currentText: 0,
      shouldLoop: true,
    },
    {
      animMorphos: animMorphos0_1,
      animOsc: emptyAnim,
      nextState: 4,
      currentText: 1,
      shouldLoop: false,
    },
    {
      animMorphos: animMorphos1,
      animOsc: emptyAnim,
      nextState: 4,
      currentText: 1,
      shouldLoop: true,
    },
    {
      animMorphos: animMorphos1_2,
      animOsc: emptyAnim,
      nextState: 6,
      currentText: 2,
      shouldLoop: false,
    },
    {
      animMorphos: animMorphos2,
      animOsc: emptyAnim,
      nextState: 6,
      currentText: 2,
      shouldLoop: true,
    },
    {
      animMorphos: animMorphos2_3,
      animOsc: emptyAnim,
      nextState: 8,
      currentText: 3,
      shouldLoop: false,
    },
    {
      animMorphos: animMorphos3,
      animOsc: emptyAnim,
      nextState: 8,
      currentText: 3,
      shouldLoop: true,
    },
    {
      animMorphos: animMorphos3_4,
      animOsc: emptyAnim,
      nextState: 10,
      currentText: 4,
      shouldLoop: false,
    },
    {
      animMorphos: animMorphos4,
      animOsc: emptyAnim,
      nextState: 10,
      currentText: 4,
      shouldLoop: true,
    },
    {
      animMorphos: animMorphos4_5,
      animOsc: emptyAnim,
      nextState: 12,
      currentText: 5,
      shouldLoop: false,
    },
    {
      animMorphos: animMorphos5,
      animOsc: emptyAnim,
      nextState: 12,
      currentText: 5,
      shouldLoop: true,
    },
    {
      animMorphos: animMorphos5_6,
      animOsc: emptyAnim,
      nextState: 14,
      currentText: 6,
      shouldLoop: false,
    },
    {
      animMorphos: animMorphos6,
      animOsc: emptyAnim,
      nextState: 14,
      currentText: 6,
      shouldLoop: true,
    },
  ],
};

class Intro3DMorphing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentText: 0,
      currentAnimState: 0,
      slideOut: false,
      slideIn: false,
      animEnd: false,
    };
  }

  componentDidMount() {
    trigger.add("[resetanimtriggermorphos]", {
      toggle: {
        callback: {
          in: (trigger) => {
            return new Promise((resolve, reject) => {
              this.setState({
                currentAnimState: 0,
                slideOut: false,
                slideIn: false,
              });
            });
          },
          out: (trigger) => {
            return new Promise((resolve, reject) => {});
          },
        },
      },
    });
    trigger.add("[morphoscontextanimtrigger]", {
      toggle: {
        callback: {
          in: (trigger) => {
            return new Promise((resolve, reject) => {
              this.setState({
                currentAnimState: _.toNumber(
                  trigger.element.getAttribute("nextanim")
                ),
                animEnd: false,
              });
              this.SlideInOut();
            });
          },
          out: (trigger) => {
            return new Promise((resolve, reject) => {});
          },
        },
      },
    });
    trigger.add("[morphoscontextendanim]", {
      toggle: {
        callback: {
          in: (trigger) => {
            return new Promise((resolve, reject) => {
              this.setState({ slideOut: false, slideIn: true, animEnd: true });
            });
          },
        },
        out: (trigger) => {
          return new Promise((resolve, reject) => {});
        },
      },
    });
  }

  onAnimComplete() {
    this.setState({
      currentAnimState: pageData.anim[this.state.currentAnimState].nextState,
    });
  }

  SlideInOut() {
    if (this.state.currentAnimState === 14) return;
    if (
      this.state.currentAnimState === 1 ||
      this.state.currentAnimState === 0
    ) {
      this.setState({ slideOut: false, slideIn: true });
      this.setState({
        currentText: pageData.anim[this.state.currentAnimState].currentText,
      });
    } else {
      this.setState({ slideOut: true, slideIn: false });
      setTimeout(() => {
        this.setState({
          currentText: pageData.anim[this.state.currentAnimState].currentText,
        });

        this.setState({ slideOut: false, slideIn: true });
      }, 500);
    }
  }

  render() {
    return (
      <>
        <div resetanimtriggermorphos="true"></div>
        <div className="mainIntroMorphingModule">
          <p className="introTxtModule">Introducing</p>

          <p className="contextualIntroHeadTxt">
            3D
            <br />
            <span className="spModuleTxt">Morphing</span>
          </p>

          <p className="monoIntroParaTxt">
            3D Morphing creates entirely new soundscapes by{" "}
            <span className="spMonoIntroPara">
              morphing between multiple user presets,{" "}
            </span>
            in three different dimensions. All at the reach of your fingertips,
            it opens up a whole new world for
            <span className="spMonoIntroPara"> live jamming!</span>
          </p>
          <div
            className={`morphosAnimContainer monoAnimContainer  ${
              this.state.animEnd ? "animEndContainerMorphos" : ""
            } `}
          >
            <div className="moprhosSvgContainer">
              <div className="morphosAnimComponent">
                <Lottie
                  animationData={
                    pageData.anim[this.state.currentAnimState].animMorphos
                  }
                  onComplete={() => this.onAnimComplete()}
                  loop={pageData.anim[this.state.currentAnimState].shouldLoop}
                />
              </div>
            </div>
            <div className="hideOverflowMorphos hideOverflow">
              <div
                className={`slideInOut ${this.state.slideIn ? "slideIn" : ""} ${
                  this.state.slideOut ? "slideOut" : ""
                }`}
              >
                {/* <div className="animComponentOsc">
                  <Lottie
                    animationData={
                      pageData.anim[this.state.currentAnimState].animOsc
                    }
                    loop={pageData.anim[this.state.currentAnimState].shouldLoop}
                  />
                </div> */}
                <div className="animText">
                  <p>{pageData.pages[this.state.currentText].text}</p>
                  <p className="animSubtext">
                    {pageData.pages[this.state.currentText].subtext}
                  </p>
                </div>
                <div className="animNumber">
                  <p>{pageData.pages[this.state.currentText].number}</p>
                </div>
              </div>
            </div>
          </div>

          <div
            morphoscontextanimtrigger="true"
            nextanim={1}
            className="animTrigger"
            style={{ transform: "translateY(-80%)" }}
          ></div>
          <div
            morphoscontextanimtrigger="true"
            nextanim={3}
            className="animTrigger"
          ></div>
          <div
            morphoscontextanimtrigger="true"
            nextanim={5}
            className="animTrigger"
          ></div>
          <div
            morphoscontextanimtrigger="true"
            nextanim={7}
            className="animTrigger"
          ></div>
          <div
            morphoscontextanimtrigger="true"
            nextanim={9}
            className="animTrigger"
          ></div>
          <div
            morphoscontextanimtrigger="true"
            nextanim={11}
            className="animTrigger"
          ></div>
          <div
            morphoscontextanimtrigger="true"
            nextanim={13}
            className="lastAnimTriggerMorphos"
          ></div>
          <div morphoscontextendanim="true"></div>
          <div className="seeArrowDiv">
            <p className="seeInActionTxt">See it in action</p>
            <img src={arrowLongIntr} alt="" className="arrowDropIntro" />
          </div>
        </div>
      </>
    );
  }
}

export default Intro3DMorphing;
