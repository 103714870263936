import React from "react";
import "./AudioMonoBlackish.css";
import Waveform from "./../../Components/ClaudeInspiration/Waveform";
import audioPic from "./../../images/audioPic.png";
const AudioMonoBlackish = () => {
  const tracks = [
    {
      id: 1,
      title: "Franz Schubert's Ständchen - Voice (Clarinet) & Piano",
      url:
        "https://www.mfiles.co.uk/mp3-downloads/franz-schubert-standchen-serenade.mp3"
    }
  ];
  return (
    <>
      <div className="mainContainerAudioMonoB">
        <div className="mainTopTextPartAudio">
          <p className="mainParaAudioMonoB">
            «  The Mono is a kind of 21st century bridge between the best of the
            past and the best of the now. »
          </p>
          <div className="picTopAudioSur">
            <img src={audioPic} alt="" className="surgeonImg" />
            <div className="txtSUAudio">
              <p className="surgenTxt">
                Surgeon <br />
                <span className="ukTxtAudioMono">UK</span>
              </p>
            </div>
          </div>
        </div>
        <div className="audioTopMainMBl">
          <Waveform url={tracks[0].url} id="yoric-track" />
          <div className="topParaTxtAudioMB">
            <p className="surEverBurTxt">
              Surgeon - Everything Is Burning
              <br />
              <span className="surEverSpTxt">2022 © Dirty Bird Recs</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AudioMonoBlackish;
