import React from "react";
import Client from "shopify-buy";
import LoadingSpinner from "../LoadingSpinner";
import { UserContext } from "../../lib/context";
import { Link } from "react-router-dom";

class NorandMonoProductCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  static contextType = UserContext;

  orderNow = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    console.log(this.country);
    this.redirectToCheckout = true;
    const client = Client.buildClient({
      storefrontAccessToken: "4afd7898ce2dc3f58b7ff3407f8d1b7b",
      domain: "norandstore.myshopify.com",
    });
    client.checkout.create().then((checkout) => {
      const checkoutId = checkout.id;
      const lineItemsToAdd = [
        {
          variantId:
            "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzg1NDEyMTg4NTg1MQ==",
          quantity: 1,
        },
      ];

      const shippingAddress = {
        address1: "",
        address2: "",
        city: "",
        company: null,
        country: this.context.country,
        firstName: "",
        lastName: "",
        phone: "",
        province: "",
        zip: "",
      };

      client.checkout
        .updateShippingAddress(checkoutId, shippingAddress)
        .then((checkout) => {});

      client.checkout
        .addLineItems(checkoutId, lineItemsToAdd)
        .then((checkout) => {
          window.location.href = checkout.webUrl;
        });
    });
  };
  render() {
    return (
      <>
        {this.state.loading && <LoadingSpinner />}
        <div className="creativityMonoContainer">
          <div className="backdrop-blur">
            <div className="anim-bg">
              <div className="anim-form-1"></div>
              <div className="anim-form-2"></div>
              <div className="anim-form-3"></div>
              <div className="anim-form-4"></div>
              <div className="anim-form-5"></div>
              <div className="anim-form-6"></div>
              <div className="anim-form-7"></div>
            </div>
          </div>
          <div className="mainCreativity">
            <p className="p1Creativity">{this.props.mainText}</p>
            <p className="headerCreativityNorand">
              {this.props.NORANDTxt}
              <span className="headerCreativityMono">{this.props.MONO} </span>
            </p>
            <img src={this.props.picCut} alt="" className="cutMonoImg" />

            {/* <button onClick={this.orderNow} className="btnOrderNow"> */}
            <Link to={"/join-waitlist"}>
              <button className="btnOrderNow">
                {/* {this.props.btnText}
                <UserContext.Consumer>
                  {(priceInEur) => (priceInEur ? "€" : "$")}
                </UserContext.Consumer> */}
                Out of stock. Join Waitlist
                <img
                  src={this.props.buttArrowPic}
                  alt=""
                  className="arrowButtonMonoProduct"
                />
              </button>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default NorandMonoProductCard;
