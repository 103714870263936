import React from "react";
import Header from "../../Components/Header/Header";
import FooterNorand1 from "../../Components/FooterNorand/FooterNorand1";
import ManualPage from "../../Components/ManualPage/ManualPage";
import MorphosManual from "../../Components/ManualPage/MorphosManual";

class MorphosManualPage extends React.Component {
  render() {
    return (
      <>
        <div className="termsCondTop">
          <Header />
          <ManualPage manualData={MorphosManual} currentSection={0} />
          <FooterNorand1 />
        </div>
      </>
    );
  }
}

export default MorphosManualPage;
