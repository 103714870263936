import React from "react";
import "./RandomizeAnything.css";
import picPlus from "./../../images/BigPlus.svg";

const RandomizeAnything = () => {
  return (
    <>
      <div className="mainContainer">
        <img src={picPlus} alt="" className="plusRandomImg" />

        <p className="unlockTxtRandom">Unlock Your Creativity</p>

        <p className="randomAnyTxt">
          Randomize
          <br /> <span className="spAnythingTxt">anything</span>
        </p>

        <p className="mainParaTxtRandAny">
          Yes, you really can randomize anything you want on MONO. Hold down the
          Randomization{" "}
          <span className="spMainParaRandomAny">two-buttons combo</span> and
          select the functionality you want to randomize and you’re done. Oh,
          and{" "}
          <span className="spMainParaRandomAny">
            it stays quantized, so you’ll never be off key!
          </span>
        </p>
      </div>
    </>
  );
};

export default RandomizeAnything;
