import React from "react";
import Header from "../../Components/Header/Header";
import ContextualModulation from "../../Components/ContextualModulation/ContextualModulation";
import Innovativetools from "../../Components/Innovativetools/Innovativetools";
import Nornandmono from "../../Components/Norandmono/Nornandmono";
import ThreeDMorphingHome from "../../Components/ThreeDMorphingHome/ThreeDMorphingHome";
import FreeShipping from "./../../Components/FreeShipping/FreeShipping";
import Footer from "./../../Components/FooterNorand/FooterNorand1";

class Norandhomne extends React.Component {
  render() {
    return (
      <div>
        <div backdrop-trigger="true"></div>
        <Header />
        <ThreeDMorphingHome />
        <Nornandmono />
        <ContextualModulation />
        <Innovativetools />
        <FreeShipping />
        <Footer />
      </div>
    );
  }
}

export default Norandhomne;
