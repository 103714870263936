import React from "react";
import _ from "lodash";
import NorandMonoMK2Product from "../../Components/NorandMonoMK2Product/NorandMonoMK2Product";
import Header from "../../Components/Header/Header";
import TabMonoMK2Product from "../../Components/TabMonoMK2Product/TabMonoMK2Product";
import MK2Whatsnew from "../../Components/MK2Whatsnew/MK2Whatsnew";
import ContextualModule from "../../Components/ContextualModule/ContextualModule";
import IntroContextualModule from "../../Components/IntroContextualModule/IntroContextualModule";
import ThreeDMorphingProduct from "../../Components/ThreeDMorphingProduct/ThreeDMorphingProduct";
import ApplyingModulation from "../../Components/ApplyingModulation/ApplyingModulation";
import FooterNorand1 from "./../../Components/FooterNorand/FooterNorand1";
import DigitalBrainMonoMK2 from "../../Components/DigitalBrainMonoMK2/DigitalBrainMonoMK2";
import SimplePowerfulMK2 from "./../../Components/SimplePowerfulMK2/SimplePowerfulMK2";
import RandomizeAnything from "../../Components/RandomizeAnything/RandomizeAnything";
import TabRandomizer from "../../Components/TabRandomizer/TabRandomizer";
import TabVideoMK2 from "../../Components/TabVideoMK2/TabVideoMK2";
import CaptueringTheTacticMK2 from "../../Components/CaptueringTheTacticMK2/CaptueringTheTacticMK2";
import ClaudeInspiration from "../../Components/ClaudeInspiration/ClaudeInspiration";
import PureHarmonic from "../../Components/PureHarmonic/PureHarmonic";
import FrontBackAngMK2 from "../../Components/frontBackAngMK2/FrontBackAngMK2";
import TechinalSpecificationMK2 from "../../Components/TechinalSpecificationMK2/TechinalSpecificationMK2";
import ExpressiveKeyboard from "../../Components/ExpressiveKeyboard/ExpressiveKeyboard";

import trigger from "../../ScrollTrigger";

class MonoMK2Product extends React.Component {
  constructor(props) {
    super(props);
    // Set initial state (ONLY ALLOWED IN CONSTRUCTOR)
    this.state = {
      switchHeader: false,
      currentSection: 0
    };
  }

  SwitchHeader(bb) {
    this.setState({
      switchHeader: bb
    });
  }

  componentDidMount() {
    trigger.add("[switch-header-trigger-mk2]", {
      toggle: {
        callback: {
          in: trigger => {
            return new Promise((resolve, reject) => {
              this.SwitchHeader(false);
            });
          },
          out: trigger => {
            return new Promise((resolve, reject) => {
              this.SwitchHeader(true);
            });
          }
        }
      }
    });
    trigger.add("[section-trigger]", {
      toggle: {
        callback: {
          in: trigger => {
            return new Promise((resolve, reject) => {
              this.setState({
                currentSection: _.toNumber(
                  trigger.element.getAttribute("currentsection")
                )
              });
            });
          },
          out: trigger => {
            return new Promise((resolve, reject) => {});
          }
        }
      }
    });
  }

  render() {
    return (
      <>
        <div backdrop-trigger="true"></div>
        <Header fixed={this.state.switchHeader} />
        <div id="monohead" section-trigger="true" currentsection={0}>
          <NorandMonoMK2Product />
        </div>
        <div switch-header-trigger-mk2="true"></div>
        <TabMonoMK2Product
          currentSection={this.state.currentSection}
          fixed={this.state.switchHeader}
        />
          <MK2Whatsnew />
          <TabVideoMK2 />
        
        <div id="monocontextual" section-trigger="true" currentsection={1}>
          <IntroContextualModule />
        </div>
        <ApplyingModulation />
        <DigitalBrainMonoMK2 />
        <div id="monosequencer" section-trigger="true" currentsection={2}>
          <SimplePowerfulMK2 />
        </div>
        <div id="monorandomize" section-trigger="true" currentsection={3}>
          <RandomizeAnything />
          <TabRandomizer />
        </div>
        <ClaudeInspiration />
        <CaptueringTheTacticMK2 />
        <div id="monosoundengine" section-trigger="true" currentsection={4}>
          <PureHarmonic />
        </div>
        <ExpressiveKeyboard />
        <FrontBackAngMK2 />
        {/* <AudioMonoBlackish /> */}
        <TechinalSpecificationMK2 />
        {/* <ThreeDMorphingProduct /> */}
        <FooterNorand1 />
      </>
    );
  }
}

export default MonoMK2Product;
