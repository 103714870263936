import React from "react";

import "./AboutUsPage.css";
import Header from "../../Components/Header/Header";
import FooterNorand1 from "../../Components/FooterNorand/FooterNorand1";
import picPrimitive from "../../images/primitive.jpg";
import picLive from "../../images/biglive.jpg";

import ContactUs from "../../Components/ContactUs/ContactUs";

import Morphos_front from "./../../images/Face_cut_2.png";
import Mono_front from "./../../images/mono_front.png";

import techEngine from "./../../images/aboutElectronic.svg";
import techMorhping from "./../../images/aboutMorphing.svg";
import techFPGA from "./../../images/aboutFPGA.svg";
import techArch from "./../../images/aboutArchitecture.svg";

import paulFace from "./../../images/paulface.png";
import mathieuFace from "./../../images/DSCF31711.png";
import jbFace from "./../../images/DSCF31691.png";

import circledLine from "./../../images/circledLineAbout.svg";

const AboutUsPage = () => {
  return (
    <div>
      <div backdrop-trigger="true"></div>
      <Header />
      <div className="main-bg">
        <h1 className="discover-heading">
          ABOUT
          <span className="discover-heading-bold"> US</span>
        </h1>
      </div>
      <div className="about-section-1">
        <div className="about-section-1-left">
          <div>
            <p>Background</p>
          </div>
          <div className="about-section-1-mid">
            <p>
              Before his engineering carrier, Norand's founder, Mathieu, was a
              professional live musician and tested dozens of vintage and modern
              synthesizers.{" "}
            </p>
            <p>
              While the selection is huge and the market crowded, something was
              missing. Synthesizers were either studio or live oriented, not
              both.
            </p>
          </div>
        </div>

        <div className="about-section-1-right">
          <img src={picPrimitive} alt="" className="about-sectoin-primitive" />
          <p className="about-section-1-right-txt">
            Mathieu performing live as Primitive
          </p>
        </div>
      </div>
      <div className="about-section-2">
        <img src={Morphos_front} alt="" className="morphos-floating-img" />
        <div className="about-section-2-mid">
          <p>
            Norand aims{" "}
            <span style={{ color: "#20EAD6" }}>to bridge the gap</span> between
            studio and live with{" "}
            <span style={{ color: "#20EAD6" }}>
              feature rich, yet intuitive
            </span>{" "}
            instruments.
          </p>
        </div>
        <div className="hide-overflow">
          <img src={Mono_front} alt="" className="mono-floating-img" />
        </div>
      </div>
      <div className="about-section-3">
        <div className="about-section-1-right">
          <img src={picLive} alt="" className="about-sectoin-biglive" />
        </div>
        <div className="about-section-3-left">
          <div>
            <p>The heart and soul of electronic music</p>
          </div>
          <div className="about-section-3-mid">
            <p>
              Much like acoustic instruments, synthesizers are a means of
              expression and an open door to creativity. Electronic musicians
              share a tight bond with the synthesizers they love.
            </p>
            <p>
              In the last 10 years the essence of what synthesizers should be
              seems to be fading. They are becoming what non-musicians think
              they are: complicated tools with lots of buttons.
            </p>
          </div>
        </div>
      </div>
      <div className="about-section-4">
        <p className="p1Creativity">Discover</p>
        <p className="headerCreativityNorand">
          OUR
          <span className="headerCreativityMono"> TECH </span>
        </p>
        <div className="about-section-4-cards">
          <div className="about-section-4-2cards">
            <div className="about-section-4-card">
              <div className="card-pic">
                <img src={techEngine} alt=" " className="card-pic-for-real" />
              </div>
              <div className="card-text">
                <p className="card-text-title">Custom analog engine</p>
                <p>
                  Norand analog synthesis building block (oscillator & filter)
                  are custom built and have a unique sound signature.
                </p>
              </div>
            </div>
            <div className="about-section-4-card">
              <div className="card-pic">
                <img src={techMorhping} alt="" className="card-pic-for-real" />
              </div>
              <div className="card-text">
                <p className="card-text-title">3D morphing</p>
                <p>
                  We developped a unique multi-dimmensional FSR swhitch,
                  allowing for 3D modulation and morphing of audio and control
                  signals.
                </p>
              </div>
            </div>
          </div>
          <div className="about-section-4-2cards">
            <div className="about-section-4-card">
              <div className="card-pic">
                <img src={techFPGA} alt="" className="card-pic-for-real" />
              </div>
              <div className="card-text">
                <p className="card-text-title">FPGA-based DSP & DAC</p>
                <p>
                  We developed a technology to both generate and convert
                  High-definition digital control & audio signal via FPGA.
                </p>
              </div>
            </div>
            <div className="about-section-4-card">
              <div className="card-pic">
                <img src={techArch} alt="" className="card-pic-for-real" />
              </div>
              <div className="card-text">
                <p className="card-text-title">Unified architecture</p>
                <p>
                  The software has been developed with interoperability in mind.
                  Projects will always be compatible acros all our instruments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-section-5">
        <p className="unlockTxtRandom">Meet</p>
        <p className="randomAnyTxt">
          THE
          <span className="spAnythingTxt"> TEAM </span>
        </p>
        <div className="about-section-5-cards">
          <div className="about-section-5-card">
            <img src={mathieuFace} alt=" " className="face-for-real" />
            <img src={circledLine} alt=" " className="tiret-for-real" />
            <p className="team-text">Mathieu Frohlich</p>
            <p className="team-subtext">CEO - Engineering</p>
          </div>
          <div className="about-section-5-card">
            <img src={paulFace} alt=" " className="face-for-real" />
            <img src={circledLine} alt=" " className="tiret-for-real" />
            <p className="team-text">Paul Jeszensky</p>
            <p className="team-subtext">CMO - Sales</p>
          </div>
          <div className="about-section-5-card">
            <img src={jbFace} alt=" " className="face-for-real" />
            <img src={circledLine} alt=" " className="tiret-for-real" />
            <p className="team-text">JB Maruffy</p>
            <p className="team-subtext">Content - Customer rel.</p>
          </div>
        </div>
      </div>
      <ContactUs />
      <FooterNorand1 />
    </div>
  );
};

export default AboutUsPage;
