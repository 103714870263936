import React from "react";
import "./ContextualMorphosMod.css";
import dropArrowCon from "./../../images/ArrowBottomWhite.svg";
import groupPic88 from "./../../images/Group882.svg";

const ContextualMorphosMod = () => {
  return (
    <>
      <div className="mainContainerContextualMorphos">
        <div className="textPartConModMor">
          <p className="contextMorTxt1">Everything you need inside the box</p>
          <p className="contextModMorHeading">
            Contextual
            <br />
            <span className="contextModMorHeadingSp"> Modulation</span>
          </p>

          <p className="mainParaTxtMorphCont">
            Using Norand’s
            <span className="mainParaTxtMorphContSp">
              {" "}
              Contextual Modulation technology
            </span>
            , it is possible to modulate{" "}
            <span className="mainParaTxtMorphContSp">every parameter</span> on
            MORPHOS. The module also provides 3 Modulation Modes -
            <span className="mainParaTxtMorphContSp">
              {" "}
              Attenuverter, Modulator{" "}
            </span>{" "}
            and <span className="mainParaTxtMorphContSp">Ping Envelope</span> -
            allowing you to modulate timbre and create drum sounds.
          </p>
        </div>
        <div className="picTextBottomConMod">
          <div>
            <img src={groupPic88} alt="" className="puamPicCont" />
          </div>

          <div className="txtModParaMorPart">
            <p className="paraTxtCvInput">
              To modulate any parameter, simply tap the button related to its CV
              input
            </p>
            <p className="txtButtLed">
              The button's LED color will reflect the enabled modulation mode.
            </p>
            <img src={dropArrowCon} alt="" className="dropArrowContMor" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContextualMorphosMod;
