import React from "react";
import TabMonoMorphCard from "../TabMonoProduct/TabMonoMorphCard";

const price = (
  <>
    420
  </>
);

const tabData = {
  tabs: [
    {
      name: "Morphos",
      link: "#morphoshead"
    },
    {
      name: "3D Morphing",
      link: "#morphostdmorphing"
    },
    {
      name: "Contextual Modulation",
      link: "#morphoscontextual"
    },
    {
      name: "TZFM Oscillators",
      link: "#morphososcillators"
    },
    {
      name: "Manual",
      link: "#"
    }
  ]
};

class MorphosProductTab extends React.Component {
  render() {
    return (
      <>
        <TabMonoMorphCard
          price={price}
          fixed={this.props.fixed}
          currentSection={this.props.currentSection}
          tabData={tabData}
          product="morphos"
          shopifyLink="Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MjIwODI4OTcxODQyNw=="
        />
      </>
    );
  }
}

export default MorphosProductTab;
