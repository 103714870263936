import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import "./TabVideoMK2.css";
import arrow from "./../../images/ArrowRightBlack.svg";
import arrowLong from "./../../images/Arrow-LongIntr.svg";
import picHowWorks from "./../../images/howWorks.svg";
import VideoPlayer from "react-video-js-player";
import videoDrive from "./../../videos/mk2_drive.mp4";
import videoDrumBreak from "./../../videos/mk2_drumbreak.mp4";
import videoKick from "./../../videos/mk2_kick.mp4";
import videoExpressivity from "./../../videos/mk2_expressivity.mp4";
import videoAutonation from "./../../videos/mk2_automation.mp4";
import videoMorph from "./../../videos/mk2_morph.mp4";
import mono_cool from "./../../images/mk2_pic-2_cropped.jpg";


import { Row, Col } from "react-bootstrap";

const pageData = {
  pages: [
    {
      number: "01.",
      title: "Drive",
      text: "Push Mono in harsher territory with the on-board output drive",
      video: videoDrive,
    },
    {
      number: "02.",
      title: "Drum Break",
      text: "Mod note is more powerful than ever, create intricate patterns with the powerful fearure set!",
      video: videoDrumBreak,
    },
    {
      number: "03.",
      title: "Kick Drum",
      text: "Mono as the ultimate kick drum synth ? Yes, it's possible !",
      video: videoKick,
    },
    {
      number: "04.",
      title: "Expressivity",
      text: "Expressivity can be played and recorded on the mini-keyboard, and assigned to any and all parameters.",
      video: videoExpressivity,
    },
    {
      number: "05.",
      title: "Automation",
      text: "Smooth automation is now possible on the modulation parameters, recorded at 1024PPQN, allowing for subtle and precise variations.",
      video: videoAutonation,
    },
    {
      number: "06.",
      title: "Morphing",
      text: "Morphing feature has been improved for natural, or crazy transitions between patterns.",
      video: videoMorph,
    },
  ],
};

function Clip({ url }) {
  return (
    <video key={url}>
      <source src={url} />
    </video>
  );
}


class TabVideoMK2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentPage: 0 };
  }

  SlideInOut(page) {
    this.setState({
      slideOut: true,
      slideIn: false,
    });
    setTimeout(() => {
      this.setState({ currentPage: page });
      this.setState({
        slideOut: false,
        slideIn: true,
      });
    }, 500);
  }

  render() {
    return (
      <>
        <div className="mainTabBarPgRand">
          <div className="txtMainTabRand">
            <div className="tabDivLineTxt" onClick={() => this.SlideInOut(0)}>
              <p
                className={
                  this.state.currentPage === 0 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[0].number}{" "}
                <span className="tabDivLineFullTxt">
                  {pageData.pages[0].title}
                </span>
              </p>
              <div
                className={
                  this.state.currentPage === 0
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
            <div className="tabDivLineTxt" onClick={() => this.SlideInOut(1)}>
              <p
                className={
                  this.state.currentPage === 1 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[1].number}{" "}
                <span className="tabDivLineFullTxt">
                  {pageData.pages[1].title}
                </span>
              </p>
              <div
                className={
                  this.state.currentPage === 1
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
            <div className="tabDivLineTxt" onClick={() => this.SlideInOut(2)}>
              <p
                className={
                  this.state.currentPage === 2 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[2].number}{" "}
                <span className="tabDivLineFullTxt">
                  {pageData.pages[2].title}
                </span>
              </p>
              <div
                className={
                  this.state.currentPage === 2
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
            <div className="tabDivLineTxt" onClick={() => this.SlideInOut(3)}>
              <p
                className={
                  this.state.currentPage === 3 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[3].number}{" "}
                <span className="tabDivLineFullTxt">
                  {pageData.pages[3].title}
                </span>
              </p>
              <div
                className={
                  this.state.currentPage === 3
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
            <div className="tabDivLineTxt" onClick={() => this.SlideInOut(4)}>
              <p
                className={
                  this.state.currentPage === 4 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[4].number}{" "}
                <span className="tabDivLineFullTxt">
                  {pageData.pages[4].title}
                </span>
              </p>
              <div
                className={
                  this.state.currentPage === 4
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
            <div className="tabDivLineTxt" onClick={() => this.SlideInOut(5)}>
              <p
                className={
                  this.state.currentPage === 5 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[5].number}{" "}
                <span className="tabDivLineFullTxt">
                  {pageData.pages[5].title}
                </span>
              </p>
              <div
                className={
                  this.state.currentPage === 5
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
          </div>
        </div>
        <div className="pageTabVideoDetail">
        <Row className="pageTabRowVideoDetail">
          <Col
            xl={5}
            lg={12}
            className={`randLeftElements ${
              this.state.slideIn ? "fadeIn" : ""
            } ${this.state.slideOut ? "fadeOut" : ""}`}
          >
            <p className="oneTxt">
              {pageData.pages[this.state.currentPage].number}
            </p>
            <div className="topDetailOnClick">
              <p className="pageRandTxtHeading">
                {pageData.pages[this.state.currentPage].title}
              </p>
              <p className="mainParaPagRand">
                {pageData.pages[this.state.currentPage].text}
              </p>
            </div>
          </Col>
          <Col
            xl={7}
            lg={12}
            className={`randRightElements ${
              this.state.slideIn ? "slideIn" : ""
            } ${this.state.slideOut ? "slideOut" : ""}`}
          >
              <div className="randVideoContainer">
                {/* <VideoPlayer className="TabVideo" src={pageData.pages[this.state.currentPage].video} /> */}
                <video className="TabVideo" src={pageData.pages[this.state.currentPage].video} controls>
                </video>
              </div>
          </Col>
        </Row>
        </div>
        <img src={mono_cool} alt="" className="backPicDSC04771" />
      </>
    );
  }
}

export default TabVideoMK2;
