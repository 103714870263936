import React from "react";
import { GrPlayFill } from "react-icons/gr";
import { MdPause } from "react-icons/md";

import WaveSurfer from "wavesurfer.js";

class Waveform extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false, playing: false, volume: 0.5 };
  }

  componentDidMount() {
    this.wavesurfer = WaveSurfer.create({
      container: `#${this.props.id}`,
      waveColor: "#eee",
      progressColor: "OrangeRed",
      cursorColor: "OrangeRed",
      barWidth: 3,
      barRadius: 3,
      responsive: true,
      height: 66,
      normalize: true,
      partialRender: false
    });
    this.wavesurfer.load(this.props.url);
    this.wavesurfer.on("ready", function() {
      if (this.wavesurfer) {
        this.setState({ loaded: true });
      }
    });
  }

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
    this.wavesurfer.playPause();
  };

  render() {
    return (
      <div className="mainAudioWave">
        <div
          id={this.props.id}
          style={{ width: "350px", marginLeft: "15px", paddingTop: "25px" }}
        />
        <div className="controls">
          <button className="playButtonClau" onClick={this.handlePlayPause}>
            {!this.state.playing ? (
              <GrPlayFill className="playIconReact" />
            ) : (
              <MdPause className="pauseIconReact" />
            )}
          </button>
        </div>
      </div>
    );
  }
}

export default Waveform;
// export default function Waveform({ url }) {
//   const waveformRef = useRef(null);
//   const wavesurfer = useRef(null);
//   const [playing, setPlay] = useState(false);
//   const [volume, setVolume] = useState(0.5);
//   const [loaded, setLoaded] = useState(false);

//   useEffect(() => {
//     const options = formWaveSurferOptions(waveformRef.current);
//     if (!loaded) {
//       wavesurfer.current = WaveSurfer.create(options);
//       wavesurfer.current.load(url);
//       wavesurfer.current.on("ready", function() {
//         if (wavesurfer.current) {
//           setLoaded(true);
//         }
//       });
//     }
//   });

//   const handlePlayPause = () => {
//     if (!loaded) {
//       const options = formWaveSurferOptions(waveformRef.current);
//       wavesurfer.current = WaveSurfer.create(options);
//       wavesurfer.current.load(url);
//       wavesurfer.current.on("ready", function() {
//         // https://wavesurfer-js.org/docs/methods.html
//         // wavesurfer.current.play();
//         // setPlay(true);

//         // make sure object stillavailable when file loaded
//         if (wavesurfer.current) {
//           setLoaded(true);
//           wavesurfer.current.setVolume(volume);
//           setVolume(volume);
//           setPlay(!playing);
//           wavesurfer.current.playPause();
//         }
//       });
//     } else {
//       setPlay(!playing);
//       wavesurfer.current.playPause();
//     }
//   };

//   return (
//     <div className="mainAudioWave">
//       <div id="waveform" ref={waveformRef} />
//       <div className="controls">
//         <button className="playButtonClau" onClick={handlePlayPause}>
//           {!playing ? (
//             <GrPlayFill className="playIconReact" />
//           ) : (
//             <MdPause className="pauseIconReact" />
//           )}
//         </button>
//         {/* <input
//           type="range"
//           id="volume"
//           name="volume"

//           min="0.01"
//           max="1"
//           step=".025"
//           onChange={onVolumeChange}
//           defaultValue={volume}
//         />
//         <label htmlFor="volume">Volume</label> */}
//       </div>
//     </div>
//   );
// }
