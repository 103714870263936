import React from "react";
import { Link } from "react-router-dom";

import btnArrow from "./../../images/ArrowRightWhite.svg";
import { Container } from "react-bootstrap";

const ThreeMorphingProductCard = props => {
  return (
    <>
      <Container fluid className="ThreeDMorphicContainerPro">
        <div className="innerDataContainer3DPro">
          <div className="col1MainPic3d">
            <div className="leftPic3dMorPro">
              <img src={props.leftPic} alt="" className="leftImg3MorPro" />
            </div>
            <div className="buttonTopDiv">
              <Link to={props.mLink} className="btnDisMorPro">
                Discover {props.mTxt}
                <img src={btnArrow} alt="" className="imgArrow" />
              </Link>
            </div>
          </div>
          <div className="rightPartMorphPro">
            <p className="d3MorphingTxtPro">{props.d3Text}</p>

            <div className="norMorHeadingTxt">
              <img
                style={{ top: ".65em", left: "4.2em" }}
                src={props.picNew}
                alt=""
                className="newPic"
              />
              <p>
                <span className="txtNorandPro">NORAND</span>
                {props.mTxt}
              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ThreeMorphingProductCard;
