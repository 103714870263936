import React from "react";
import Header from "../../Components/Header/Header";
import FooterNorand1 from "../../Components/FooterNorand/FooterNorand1";
import ManualPage from "../../Components/ManualPage/ManualPage";
import MonoManual from "../../Components/ManualPage/MonoManual";

class MonoManualPage extends React.Component {
  render() {
    return (
      <>
        <div className="termsCondTop">
          <Header />
          <ManualPage manualData={MonoManual} currentSection={0} />
          <FooterNorand1 />
        </div>
      </>
    );
  }
}

export default MonoManualPage;
