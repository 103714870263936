import React from "react";
import "./FreeShipping.css";
import btnArrow from "./../../images/ArrowRightWhite.svg";
import { Link } from "react-router-dom";

const FreeShipping = () => {
  return (
    <>
      <div className="freeShippingContainer">
        <p className="headingFreeShipping">Free shipping worldwide.</p>
        <Link to={"/learn"} className="btnArrowFreeShip">
          Discover our instruments{" "}
          <img src={btnArrow} alt="" className="arrowFreeShip" />
        </Link>
      </div>
    </>
  );
};

export default FreeShipping;
