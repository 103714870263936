import React from "react";
import "./ContactUs.css";
import btnArrow from "./../../images/ArrowRightWhite.svg";
import headerPic1 from "./../../images/headerPicRight1black.svg";
import { AiOutlineMail } from "react-icons/ai";
import { firestore, serverTimestamp } from "../../lib/firebase";
import { Alert } from "react-bootstrap";

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showAlert: false };
  }

  submitMessage = async event => {
    event.preventDefault();
    firestore
      .collection("tickets")
      .add({
        email: event.target.email.value,
        name: event.target.name.value,
        message: event.target.text.value,
        timestamp: serverTimestamp(),
        subbedToNewsletter: true
      })
      .then(ref => {
        this.setState({ showAlert: true });
        setTimeout(() => {
          this.setState({ showAlert: false });
        }, 4000);
      });
  };

  render() {
    return (
      <>
        <Alert
          className="my-alert-success"
          onClose={() => this.setState({ showAlert: false })}
          dismissible
          show={this.state.showAlert}
        >
          <p>Thank you for your message, we will reply as soon as possible.</p>
        </Alert>
        <form onSubmit={this.submitMessage} className="mainContainerContacts">
          <h1 className="headingContactUs">Contact Us</h1>
          <div className="topInputFieldContact">
            <p className="inputTxt">Email address:</p>
            <div>
              <AiOutlineMail className="iconcontact" />
              <input
                className="inputFieldContact"
                type="email"
                name="email"
                placeholder="Enter email"
              />
            </div>
          </div>
          <div className="topInputFieldContact">
            <p className="inputTxt">Name:</p>
            <div>
              <img
                src={headerPic1}
                style={{ paddingBottom: "8px" }}
                alt=""
                className="iconcontact"
              />
              <input
                className="inputFieldContact"
                type="text"
                name="name"
                placeholder="Enter name"
              />
            </div>
          </div>
          <div className="topInputFieldContact">
            <p className="inputTxt">Message:</p>
            <textarea
              className="inputFieldContact"
              type="text"
              name="text"
              placeholder="Enter message"
              rows={5}
              cols={5}
            />
          </div>
          <div className="buttonTopContact">
            <button className="btnContactSubmit">
              Submit
              <img src={btnArrow} alt="" className="arrowLeftBtnContact" />
            </button>
          </div>
        </form>
      </>
    );
  }
}

export default ContactUs;
