import React from "react";
import _ from "lodash";
import NorandMonoProduct from "../../Components/NorandMonoProduct/NorandMonoProduct";
import Header from "../../Components/Header/Header";
import TabMonoProduct from "../../Components/TabMonoProduct/TabMonoProduct";
import ContextualModule from "../../Components/ContextualModule/ContextualModule";
import IntroContextualModule from "../../Components/IntroContextualModule/IntroContextualModule";
import ThreeDMorphingProduct from "../../Components/ThreeDMorphingProduct/ThreeDMorphingProduct";
import ApplyingModulation from "../../Components/ApplyingModulation/ApplyingModulation";
import FooterNorand1 from "./../../Components/FooterNorand/FooterNorand1";
import DigitalBrainMono from "../../Components/DigitalBrainMono/DigitalBrainMono";
import SimplePowerful from "./../../Components/SimplePowerful/SimplePowerful";
import RandomizeAnything from "../../Components/RandomizeAnything/RandomizeAnything";
import TabRandomizer from "../../Components/TabRandomizer/TabRandomizer";
import CaptueringTheTactic from "../../Components/CaptueringTheTactic/CaptueringTheTactic";
import ClaudeInspiration from "../../Components/ClaudeInspiration/ClaudeInspiration";
import PureHarmonic from "../../Components/PureHarmonic/PureHarmonic";
import FrontBackAng from "../../Components/frontBackAng/FrontBackAng";
import TechinalSpecification from "../../Components/TechinalSpecification/TechinalSpecification";
import ExpressiveKeyboard from "../../Components/ExpressiveKeyboard/ExpressiveKeyboard";

import trigger from "../../ScrollTrigger";

class MonoProduct extends React.Component {
  constructor(props) {
    super(props);
    // Set initial state (ONLY ALLOWED IN CONSTRUCTOR)
    this.state = {
      switchHeader: false,
      currentSection: 0
    };
  }

  SwitchHeader(bb) {
    this.setState({
      switchHeader: bb
    });
  }

  componentDidMount() {
    trigger.add("[switch-header-trigger]", {
      toggle: {
        callback: {
          in: trigger => {
            return new Promise((resolve, reject) => {
              this.SwitchHeader(false);
            });
          },
          out: trigger => {
            return new Promise((resolve, reject) => {
              this.SwitchHeader(true);
            });
          }
        }
      }
    });
    trigger.add("[section-trigger]", {
      toggle: {
        callback: {
          in: trigger => {
            return new Promise((resolve, reject) => {
              this.setState({
                currentSection: _.toNumber(
                  trigger.element.getAttribute("currentsection")
                )
              });
            });
          },
          out: trigger => {
            return new Promise((resolve, reject) => {});
          }
        }
      }
    });
  }

  render() {
    return (
      <>
        <div backdrop-trigger="true"></div>
        <Header fixed={this.state.switchHeader} />
        <div id="monohead" section-trigger="true" currentsection={0}>
          <NorandMonoProduct />
        </div>
        <TabMonoProduct
          currentSection={this.state.currentSection}
          fixed={this.state.switchHeader}
        />
        <div switch-header-trigger="true">
          <ContextualModule />
        </div>
        <div id="monocontextual" section-trigger="true" currentsection={1}>
          <IntroContextualModule />
        </div>
        <ApplyingModulation />
        <DigitalBrainMono />
        <div id="monosequencer" section-trigger="true" currentsection={2}>
          <SimplePowerful />
        </div>
        <div id="monorandomize" section-trigger="true" currentsection={3}>
          <RandomizeAnything />
          <TabRandomizer />
        </div>
        <ClaudeInspiration />
        <CaptueringTheTactic />
        <div id="monosoundengine" section-trigger="true" currentsection={4}>
          <PureHarmonic />
        </div>
        <ExpressiveKeyboard />
        {/* <AudioMonoBlackish /> */}
        <FrontBackAng />
        <TechinalSpecification />
        <ThreeDMorphingProduct />
        <FooterNorand1 />
      </>
    );
  }
}

export default MonoProduct;
