import React from "react";
import VideoPlayer from "react-video-js-player";

import video from "./../../videos/mono_contextual_mod.mp4";
const ApplyModuleCard = (props) => {
  return (
    <>
      <div className="mainApplyModule">
        <div className="mainContainerApplyingMod">
          <div className="txtPartApplyMod">
            <p className="paraApplyModTxt">{props.para}</p>
            <div className="picPartUserAppMod">
              <img src={props.picJaqu} alt="" className="imgJaqu" />
              <p className="nameApplyModHolder">
                {" "}
                {props.name}
                <br />
                <span className="spCountryAppMod">{props.country}</span>
              </p>
            </div>
          </div>
          <div className="mainVideoApplyMod">
            <VideoPlayer src={props.video} width="640" height="360" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyModuleCard;
