import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";

import AcceptCookies from "./Components/AcceptCookies/AcceptCookies";

import Norandhome from "./Pages/Norandhome/Norandhome";
import Norandlearn from "./Pages/Norandlearn/Norandlearn";
import MonoProduct from "./Pages/MonoProduct/MonoProduct";
import MonoMK2Product from "./Pages/MonoMK2Product/MonoMK2Product";
import MorphosProduct from "./Pages/MorphosProduct/MorphosProduct";
import MonoManualPage from "./Pages/MonoManualPage/MonoManualPage";
import MonoV2ManualPage from "./Pages/MonoV2ManualPage/MonoV2ManualPage";
import MonoMK2ManualPage from "./Pages/MonoMK2ManualPage/MonoMK2ManualPage";
import MorphosManualPage from "./Pages/MorphosManualPage/MonoManualPage";

import RetailersPage from "./Pages/RetailersPage/RetailersPage";

import ContactUs from "./Pages/ContactsUs/ContactUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./Pages/TermsConditions/TermsConditions";
import LegalInformation from "./Pages/LegalInformationPage/LegalInformationPage";
import AboutUsPage from "./Pages/AboutUs/AboutUsPage";
import JoinWaitlist from "./Pages/JoinWaitlist/JoinWaitlist";
import JoinWaitlistMK2 from "./Pages/JoinWaitlistMK2/JoinWaitlistMK2";
import Downloads from "./Pages/Downloads/Downloads";

function App() {
  return (
    <>
      {/* <PrivacyPolicy/>
     <ContactUs/>
     <FooterNorand/> */}

      {/* <Header/>
     <ThreeDMorphingHome/>
     <Nornandmono />
     <Innovativetools /> */}
      {/* <FooterNorand1/> */}
      <Router>
        <ScrollToTop />
        <AcceptCookies />
        <Routes>
          <Route path="/" exact element={<Norandhome />} />
          <Route path="/learn" exact element={<Norandlearn />} />
          <Route path="/mono" exact element={<MonoProduct />} />
          <Route path="/monomk2" exact element={<MonoMK2Product />} />
          <Route path="/monomk2/manual" exact element={<MonoMK2ManualPage />} />
          <Route path="/morphos" exact element={<MorphosProduct />} />
          <Route path="/mono/manualV1" exact element={<MonoManualPage />} />
          <Route path="/mono/manual" exact element={<MonoV2ManualPage />} />
          <Route path="/morphos/manual" exact element={<MorphosManualPage />} />
          <Route path="/about" exact element={<AboutUsPage />} />
          <Route path="/contact-us" exact element={<ContactUs />} />
          <Route path="/join-waitlist" exact element={<JoinWaitlist />} />
          <Route path="/join-waitlist-mk2" exact element={<JoinWaitlistMK2 />} />
          <Route path="/retailers" exact element={<RetailersPage />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          <Route path="/downloads" exact element={<Downloads />} />
          <Route
            path="/terms-and-conditions"
            exact
            element={<TermsConditions />}
          />
          <Route
            path="/legal-information"
            exact
            element={<LegalInformation />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
