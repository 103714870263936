import React from "react";
import arrowLongIntr from "./../../images/Arrow-LongIntr.svg";
import monoSVG from "./../../images/mono_filaire_final.svg";
import animMono0 from "./../../Lotties/mono_context_0.json";
import animMono0_1 from "./../../Lotties/mono_context_0_1.json";
import animMono1 from "./../../Lotties/mono_context_1.json";
import animMono1_2 from "./../../Lotties/mono_context_1_2.json";
import animMono2 from "./../../Lotties/mono_context_2.json";
import animMono2_3 from "./../../Lotties/mono_context_2_3.json";
import animMono3 from "./../../Lotties/mono_context_3.json";
import animMono3_4 from "./../../Lotties/mono_context_3_4.json";
import animMono4 from "./../../Lotties/mono_context_4.json";
import animMono4_5 from "./../../Lotties/mono_context_4_5.json";

import emptyAnim from "./../../Lotties/mono_context_osc_empty.json";
import animOsc1_2 from "./../../Lotties/mono_context_1_2_osc.json";
import animOsc2 from "./../../Lotties/mono_context_2_osc.json";
import animOsc2_3 from "./../../Lotties/mono_context_2_3_osc.json";
import animOsc3 from "./../../Lotties/mono_context_3_osc.json";
import animOsc3_4 from "./../../Lotties/mono_context_3_4_osc.json";
import animOsc4 from "./../../Lotties/mono_context_4_osc.json";
import animOsc4_5 from "./../../Lotties/mono_context_4_5_osc.json";

import Lottie from "lottie-react";

import "./IntroContextualModule.css";
import trigger from "../../ScrollTrigger";

const pageData = {
  pages: [
    {
      number: "01",
      text: "Turn any parameter knob to select a parameter."
    },
    {
      number: "02",
      text: "X-MOD controls a dedicated oscillator modulating the parameter."
    },
    {
      number: "03",
      text: "X-ENV modulates the parameter with a dedicated AD envelope."
    },
    {
      number: "04",
      text:
        "The knob's LED now reflects the modulation applied to the parameter. "
    },
    {
      number: "",
      text: ""
    }
  ],
  anim: [
    {
      animMono: animMono0,
      animOsc: emptyAnim,
      nextState: 0,
      currentText: 4,
      shouldLoop: true
    },
    {
      animMono: animMono0_1,
      animOsc: emptyAnim,
      nextState: 2,
      currentText: 0,
      shouldLoop: false
    },
    {
      animMono: animMono1,
      animOsc: emptyAnim,
      nextState: 2,
      currentText: 0,
      shouldLoop: true
    },
    {
      animMono: animMono1_2,
      animOsc: animOsc1_2,
      nextState: 4,
      currentText: 1,
      shouldLoop: false
    },
    {
      animMono: animMono2,
      animOsc: animOsc2,
      nextState: 4,
      currentText: 1,
      shouldLoop: true
    },
    {
      animMono: animMono2_3,
      animOsc: animOsc2_3,
      nextState: 6,
      currentText: 2,
      shouldLoop: false
    },
    {
      animMono: animMono3,
      animOsc: animOsc3,
      nextState: 6,
      currentText: 2,
      shouldLoop: true
    },
    {
      animMono: animMono3_4,
      animOsc: animOsc3_4,
      nextState: 8,
      currentText: 3,
      shouldLoop: false
    },
    {
      animMono: animMono4,
      animOsc: animOsc4,
      nextState: 8,
      currentText: 3,
      shouldLoop: true
    },
    {
      animMono: animMono4_5,
      animOsc: animOsc4_5,
      nextState: 9,
      currentText: 3,
      shouldLoop: false
    }
  ]
};

class IntroContextualModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentText: 0,
      currentAnimState: 0,
      slideOut: false,
      slideIn: false,
      animEnd: false
    };
  }

  componentDidMount() {
    trigger.add("[resetanimtrigger]", {
      toggle: {
        callback: {
          in: trigger => {
            return new Promise((resolve, reject) => {
              this.SlideInOut();
              this.setState({
                currentAnimState: 0,
                slideOut: true,
                slideIn: false
              });
            });
          },
          out: trigger => {
            return new Promise((resolve, reject) => {});
          }
        }
      }
    });
    trigger.add("[monocontextanimtrigger]", {
      toggle: {
        callback: {
          in: trigger => {
            return new Promise((resolve, reject) => {
              this.setState({
                currentAnimState: trigger.element.getAttribute("nextanim"),
                animEnd: false
              });
              this.SlideInOut();
            });
          },
          out: trigger => {
            return new Promise((resolve, reject) => {});
          }
        }
      }
    });
    trigger.add("[monocontextendanim]", {
      toggle: {
        callback: {
          in: trigger => {
            return new Promise((resolve, reject) => {
              this.setState({ slideOut: false, slideIn: true, animEnd: true });
            });
          },
          out: trigger => {
            return new Promise((resolve, reject) => {});
          }
        }
      }
    });
  }

  onAnimComplete() {
    this.setState({
      currentAnimState: pageData.anim[this.state.currentAnimState].nextState
    });
  }

  SlideInOut() {
    if (this.state.currentAnimState === 9) return;
    if (this.state.currentAnimState === 2) {
      this.setState({ slideOut: false, slideIn: true });
    } else {
      this.setState({ slideOut: true, slideIn: false });
      setTimeout(() => {
        this.setState({
          currentText: pageData.anim[this.state.currentAnimState].currentText
        });

        this.setState({ slideOut: false, slideIn: true });
      }, 500);
    }
  }

  render() {
    return (
      <>
        <div resetanimtrigger="true"></div>
        <div className="mainIntroContextModule">
          <p className="introTxtModule">Introducing</p>

          <p className="contextualIntroHeadTxt">
            CONTEXTUAL
            <br />
            <span className="spModuleTxt">MODULATION</span>
          </p>

          <p className="monoIntroParaTxt">
            MONO introduces contextual modulation, a unique modulation system
            that gives you
            <span className="spMonoIntroPara">
              full control over every parameter.{" "}
            </span>
            Each has its own <span className="spMonoIntroPara">envelope</span>
            and{" "}
            <span className="spMonoIntroPara">
              audio-frequency modulator.
            </span>{" "}
          </p>
          <div
            className={`monoAnimContainer ${
              this.state.animEnd ? "animEndContainer" : ""
            } `}
          >
            <div className="svgContainer">
              <img src={monoSVG} alt="" className="monoSVG" />
              <div className="animComponent">
                <Lottie
                  animationData={
                    pageData.anim[this.state.currentAnimState].animMono
                  }
                  onComplete={() => this.onAnimComplete()}
                  loop={pageData.anim[this.state.currentAnimState].shouldLoop}
                />
              </div>
            </div>
            <div className="hideOverflow">
              <div
                className={`slideInOut ${this.state.slideIn ? "slideIn" : ""} ${
                  this.state.slideOut ? "slideOut" : ""
                }`}
              >
                <div className="animComponentOsc">
                  <Lottie
                    animationData={
                      pageData.anim[this.state.currentAnimState].animOsc
                    }
                    loop={pageData.anim[this.state.currentAnimState].shouldLoop}
                  />
                </div>
                <div className="animText">
                  <p>{pageData.pages[this.state.currentText].text}</p>
                </div>
                <div className="animNumber">
                  <p>{pageData.pages[this.state.currentText].number}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            monocontextanimtrigger="true"
            nextanim={1}
            className="animTrigger"
            style={{ transform: "translateY(-80%)" }}
          ></div>
          <div
            monocontextanimtrigger="true"
            nextanim={3}
            className="animTrigger"
          ></div>
          <div
            monocontextanimtrigger="true"
            nextanim={5}
            className="animTrigger"
          ></div>
          <div
            monocontextanimtrigger="true"
            nextanim={7}
            className="lastAnimTrigger"
          ></div>

          <div monocontextendanim="true" className="seeArrowDiv">
            <p className="seeInActionTxt">See it in action</p>
            <img src={arrowLongIntr} alt="" className="arrowDropIntro" />
          </div>
        </div>
      </>
    );
  }
}

export default IntroContextualModule;
