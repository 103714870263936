import React from "react";
import "./ThreeDMorphingHome.css";
import leftPic from "./../../images/Morphic3DPic1.png";



import picNew from './../../images/NEW.svg'
import ThreeDMorphinHomeCard from "./ThreeDMorphinHomeCard";

const ThreeDMorphingHome = () => {
  return (
    <>
    <ThreeDMorphinHomeCard  leftPic={leftPic} picNew={picNew}/>
     
    </>
  );
};

export default ThreeDMorphingHome;
