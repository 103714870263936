import React from "react";
import { AiFillFilePdf, AiOutlineCloudDownload } from "react-icons/ai";
import { Row, Col, Card } from "react-bootstrap";
import "./../ManualPage/ManualPage.css";

import manualPanel from "./../../images/morphos_front_manual.svg";
import soundArchitecture from "./../../images/morphos_sound_architecture.svg";
import dataArchitecture from "./../../images/morphos_data_architecture.svg";

import oscSync from "./../../images/mono_manual_osc_sync.svg";

const morphosManualData = {
  chapterLinks: [
    {
      name: "#quick-start",
      title: "Quick Start",
      class: "title-1"
    },
    {
      name: "#quick-start-panel-layout",
      title: "Panel layout",
      class: "title-2"
    },
    {
      name: "#quick-start-sound-arch",
      title: "Sound architecture",
      class: "title-2"
    },
    {
      name: "#quick-start-data-arch",
      title: "Data architecture",
      class: "title-2"
    },
    {
      name: "#intro",
      title: "Intro",
      class: "title-1"
    },
    {
      name: "#synth-engine",
      title: "Synthesis Engine",
      class: "title-1"
    },
    {
      name: "#synth-engine-intro",
      title: "Intro",
      class: "title-2"
    },
    {
      name: "#synth-engine-frequency-control",
      title: "Frequency control",
      class: "title-2"
    },
    {
      name: "#synth-engine-waveform-control",
      title: "Waveform control",
      class: "title-2"
    },
    {
      name: "#synth-engine-TZFM-control",
      title: "TZFM control",
      class: "title-2"
    },
    {
      name: "#synth-engine-follow-track",
      title: "Follow & Track",
      class: "title-2"
    },

    {
      name: "#cv-modulation",
      title: "CV modulation",
      class: "title-1"
    },
    {
      name: "#modulation-contextual",
      title: "Contextual modulation",
      class: "title-2"
    },
    {
      name: "#modulation-programming-modulation",
      title: "Programming modulation",
      class: "title-2"
    },
    {
      name: "#modulation-attenuverter-mode",
      title: "Attenuverter mode",
      class: "title-2"
    },
    {
      name: "#modulation-voct-mode",
      title: "V/oct - microtonal mode",
      class: "title-2"
    },
    {
      name: "#modulation-modulator-mode",
      title: "Modulator mode",
      class: "title-2"
    },
    {
      name: "#modulation-ping-envelope-mode",
      title: "Ping envelope mode",
      class: "title-2"
    },
    {
      name: "#presets",
      title: "Presets",
      class: "title-1"
    },
    {
      name: "#presets-bank-layer",
      title: "Bank and layer selection",
      class: "title-2"
    },
    {
      name: "#presets-save-delete",
      title: "Save and delete",
      class: "title-2"
    },
    {
      name: "#presets-copy-paste",
      title: "Copy and paste",
      class: "title-2"
    },
    {
      name: "#presets-tare",
      title: "Tare",
      class: "title-2"
    },
    {
      name: "#morphing",
      title: "Morphing",
      class: "title-1"
    },
    {
      name: "#morphing-morph-strips",
      title: "Morph strips",
      class: "title-2"
    },
    {
      name: "#morphing-xyz",
      title: "X/Y/Z strips",
      class: "title-2"
    },
    {
      name: "#morphing-1d",
      title: "1D morphing",
      class: "title-2"
    },
    {
      name: "#morphing-3d",
      title: "3D morphing",
      class: "title-2"
    }
  ],
  content: (
    <>
      <div className="download-link-container">
        {/* <a
          className="download-link"
          href="https://storage.googleapis.com/norand_public_files/Mono_Shortcuts_Sheet.pdf"
        >
          <AiFillFilePdf size={"40px"} style={{ "padding-right": "10px" }} />
          Dowload Cheat sheet
        </a>
        <a
          className="download-link"
          href="https://storage.googleapis.com/norand_public_files/Norand%20Mono%20Manual.pdf"
        >
          <AiFillFilePdf size={"40px"} style={{ "padding-right": "10px" }} />
          Dowload as PDF
        </a>
        <a
          className="download-link"
          href="https://storage.googleapis.com/norand_public_files/Firmware_V1.2.3r.zip"
          hidden
        >
          <AiOutlineCloudDownload
            size={"40px"}
            style={{ "padding-right": "10px" }}
          />
          Download Firmare V1.0.0
        </a> */}
      </div>
      <h2 id="quick-start" manual-section-trigger="true">
        Quick-Start
      </h2>

      <p>
        If you are eager to start making music with Morphos right now, this
        couple of pages should get you started.
      </p>
      <h3 id="quick-start-panel-layout" manual-section-trigger="true">
        Panel Layout
      </h3>
      <img
        className="manual-img"
        id="panel-img"
        src={manualPanel}
        alt="mono panel"
        style={{ "max-width": "80%" }}
      />
      <ol id="panel-layout">
        <li>
          <span className="strong-1">A. Mophing strip :</span> Norand's
          specially developped FSR sensor. Decode the X,Y and Z position of the
          finger in contact with high accuracy. Each strip has 4 “slots”.
          <ul>
            <li>
              Hold Save and tap a slot to save a presetHold Delete and tap a
              slot to clear a preset
            </li>
            <li>Tap a slot to load a preset</li>
            <li>
              Hold and slide your finger horizontally to morph between the 4
              slots
            </li>
            <li>
              In 3D Morphing mode (LED layer white), slide your finger in every
              direction to Morph in 3 dimmension
            </li>
          </ul>
        </li>
        <li>
          <span className="strong-1">B. Oscillator Control :</span> Controls the
          oscillators parameters.
          <ul>
            <li>
              <span className="strong-2">Frequency: </span> Semitone-quantized
              pitch control
            </li>
            ={" "}
            <li>
              <span className="strong-2">Detune:</span> Detune control ±2
              semitone range
            </li>
            <li>
              <span className="strong-2">Wave:</span> Continuously variable
              waveform control. Sine - Triangle - Square - Sawtooth
            </li>
            <li>
              <span className="strong-2">TZFM: </span> Controls the amount of
              TZFM applied to the oscillator. (Bipolar)
            </li>
          </ul>
        </li>
        <li>
          <span className="strong-1">C. Parameter CV controls :</span> Each
          synthesis parameter has a similar CV control path. The V/Oct acts
          slightly differently. Tap the switch to select a parameter, tap again
          to cycle through the 3 modes of CV modulation : Attenuverter in white,
          Modulator in Red, and ping envelope in green. Input range is ±5 volt.
        </li>
        <li>
          <span className="strong-1">D. 3D Morphing CV inputs :</span> Controls
          the morphing under CV. X and Y input range is ±5Volt, Z input range is
          0-5V. When CV are connected, the strips can't be used for morphing.
        </li>
        <li>
          <span className="strong-1">E. Mod section :</span> Set up the selected
          synthesis parameter's modulation.
          <ul>
            <li>
              <span className="strong-2">Attenuverter Mode: </span>Use the
              amount pot as an attenuverter. On the V/Oct input, the
              attenuverter allows to select micro/macro tonal modes.
            </li>
            <li>
              <span className="strong-2">Modulator Mode:</span> This mode works
              without CV controls. Rate sets the frequency of the modulator,
              amount sets the modulation level, multiplied by the CV input when
              contected.
            </li>
            <li>
              <span className="strong-2">Ping Envelope:</span> A trigger is
              expected at the CV input, it'll trig a ping envelope on the
              parameter. Rate sets the decay, amount the level.
            </li>
          </ul>
        </li>
        <li>
          <span className="strong-1">Switches:</span>
          <ul>
            <li>
              <span className="strong-2">Bank: </span> select one of the four
              banks on each oscillator.
            </li>
            <li>
              <span className="strong-2">Layer:</span> cycle between the X, Y,
              Z, and 3D morphing layers. In 3D morphing mode you cannot edit the
              presets, only morph on the 3 dimmensions.
            </li>
            <li>
              <span className="strong-2">Bank + Layer:</span> “Tare” the
              synthesis engine, resets all the parameter to their potentiometer
              physical position, resets all CV modulations as well.
            </li>
            <li>
              <span className="strong-2">Sync:</span> Toggle "soft" sync, osc1
              syncs osc2.
            </li>
            <li>
              <span className="strong-2">Track:</span> Toggle oscillator
              tracking, osc2 tracks osc1.
            </li>
            <li>
              <span className="strong-2">Track + Sync:</span> Toggle dual
              morphing mode, a morphing event, either on the strip or on CV,
              will morph both oscillators.
            </li>
          </ul>
        </li>
      </ol>

      <h3 id="quick-start-sound-arch" manual-section-trigger="true">
        Morphos sound architecture
      </h3>
      <img
        className="manual-img"
        style={{ "max-width": "80%" }}
        src={soundArchitecture}
        alt="sound architecture"
      />
      <h3 id="quick-start-data-arch" manual-section-trigger="true">
        Morphos data architecture
      </h3>
      <img
        className="manual-img"
        style={{ "max-width": "80%" }}
        src={dataArchitecture}
        alt="data architecture"
      />

      <h2 id="intro" manual-section-trigger="true">
        Intro
      </h2>

      <p>
        Morphos is a digitally controlled dual VCO. The analog synthesis engine
        is not directly controlled by the knobs and CV inputs, but is fed to a
        powerful MCU, which in turns outputs control voltage via a high-speed,
        high-definition digital-to-analog converter.
      </p>
      <p>
        We dropped a lot of acronyms here but the important part is that this
        allows for a ton of interesting things to be done because you can more
        or less have the same power over your analog oscillator as you would
        with a digital one!
      </p>
      <p>
        For example you can have very precise calibration, internal digital
        modulation, presets, morphing capabilities, etc.… which as you no doubt
        guessed, Morphos can do it all! Let's dive in.
      </p>
      <p>
        This pair of oscillators and its 3 continuously variable parameters
        allows for a huge landscape of timbre, ranging from subtle bell-like
        sounds to very harsh saturated noise. All this without even touching the
        CV modulation and morphing system.
      </p>
      <h2 id="synth-engine" manual-section-trigger="true">
        Synthesis Engine
      </h2>
      <h3 id="synth-engine-intro" manual-section-trigger="true">
        Intro
      </h3>
      <p>
        Morphos core is a pair of analog oscillators with continuously variable
        waveforms, internally routed through-zero frequency modulation (TZFM),
        and soft sync. For the electronic geeks among us, the oscillators are
        based around a unique, discrete asymmetric integrator. In “layman’s”
        terms, it means that the triangle core waveform is not perfect, is
        slightly “skewed”, which gives it and the sine wave slightly richer
        harmonic content.
      </p>
      <h3 id="synth-engine-frequency-control" manual-section-trigger="true">
        Frequency Control
      </h3>
      <p>
        Each oscillator has 3 parameters. The big Freq(uency) knob adjusts the
        pitch in semitones, from C0 to C9. The Detune knob has a range of ±2
        semitones, with pitch perfect tuning in the middle.
      </p>
      <h3 id="synth-engine-waveform-control" manual-section-trigger="true">
        Waveform control
      </h3>
      <p>
        The Wave parameters smoothly fade from sine, to triangle, to square and
        finally to sawtooth wave. You basically go from the dullest of sounds to
        the richest in terms of harmonics. Don’t take it wrong though, in terms
        of utility, we love all four wave forms and that’s why they are
        included. The low-harmonic waveform, sine and triangle, transforms into
        very rich and interesting timbre when frequency-modulated !
      </p>
      <h3 id="synth-engine-TZFM-control" manual-section-trigger="true">
        TZFM
      </h3>
      <p>
        The TZFM parameter controls the amount of through-zero frequency
        modulation via a four-quadrant multiplier (or VC-polarizer), going in
        the positive direction will increase the modulation amount as would a
        normal VCA, going in the negative direction will increase the modulation
        amount with a reversed version of the modulator signal.
      </p>
      <h3 id="synth-engine-follow-track" manual-section-trigger="true">
        Sync and Track
      </h3>
      <p>
        The sync parameter synchronizes the wave cycle of the right oscillator
        (osc2) to the left one (osc1). Morphos' sync is quite unique due to the 
        nature of the custom oscillator, it cannot go over a certain synchronizer frequency. 
        The follow parameter makes the pitch of
        the right oscillator (osc2) follow the one on the left (osc1).
      </p>

      <Row className="row-bubble">
        <Col xl={6} lg={12} className="card-col">
          <Card>
            <Card.Title>Oscillator Sync</Card.Title>
            <img
              className="manual-img"
              style={{ "margin-top": "20px", maxHeight: "200px" }}
              src={oscSync}
              alt="osc sync"
            />
            <Card.Text>
              I'm lazy so here is the wikipedia definition : As one oscillator
              <span className="emphasis">· the master ·</span> finishes a cycle,
              it resets the period of another oscillator
              <span className="emphasis">· the slave ·</span>, forcing the
              latter to have the same base frequency. This can produce a
              harmonically rich sound, the timbre of which can be altered by
              varying the synced oscillator's frequency. And here is a nice
              schematic with sawtooth wave example.
            </Card.Text>
          </Card>
        </Col>
        <Col xl={6} lg={12} className="card-col">
          <Card>
            <Card.Title>Thru-Zero Frequency modulation</Card.Title>
            <Card.Text>
              FM (Frequency Modulation) is a very deep subject with whole books
              written about it, I'll do my best to explain it shortly. Let's
              start with what FM is : the output of one oscillator
              <span className="emphasis">· the modulator ·</span> is used to
              control the frequency of another oscillator
              <span className="emphasis">· the carrier ·</span>. This will in
              turn enrich the harmonic content of the carrier, by effectively
              “spliting” each of it's base harmonic. There are two main type of
              FM : exponential and linear. Unlike exponential FM, linear FM can
              reach zero hertz, which basically stops the oscillators, which is
              not very pleasing in term of musicality. Thru-zero FM alleviate
              this problem by introducing “negative” frequency. When the
              oscilator reaches zero hertz, it will reverse it's cycle and start
              oscillating backward, and when it crosses zero hertz, it will
              reverse again back to “positive” frequencies. Note that I put
              negative and positive in quote, in the real world there is no such
              thing as negative frequency.
            </Card.Text>
          </Card>
        </Col>
      </Row>

      <h2 id="cv-modulation" manual-section-trigger="true">
        CV Modulation
      </h2>
      <h3 id="modulation-contextual-modulation" manual-section-trigger="true">
        Contextual modulation
      </h3>
      <p>
        It would have made perfect sense to just do CV modulation with an
        attenuverter, but that's not really Norand's philosophy! Each parameters
        on morphos has its own modulator and ping envelope. The CV modulation
        system in Morphos expands its sound design capabilities with 3 different
        modes: attenuverter, modulation, and ping envelope. The central section
        allows contextual adjustment of each parameter's modulation, and each
        switch on top of the CV inputs allows the user to select the parameter
        to be edited, and cycle between the modes.
      </p>
      <h3 id="modulation-programming-modulation" manual-section-trigger="true">
        Programming modulation
      </h3>
      <p>
        To edit one of the parameter modulations, you need to select it first.
        To do that, simply tap the related switch on top of the CV input’s
        parameter. The selected parameter is indicated by a bright LED. Once you
        have selected the parameter, the same switch can be used to cycle
        between the 3 modes of modulations, the LED color reflects the current
        mode: white is attenuverter mode; red is modulator mode; and blue is
        ping envelope mode.
      </p>

      <h3 id="modulation-attenuverter-mode" manual-section-trigger="true">
        Attenuverter mode
      </h3>
      <p>
        In the classic attenuverter mode, the CV input value is multiplied by
        the amount knob, and the result modulates the parameter. The rest
        position of the amount knob is dead center: in this position no CV
        modulation is applied; going right will increase the modulation by a
        positive amount; while going left creates a negative amount (i.e a
        reversed version of your input signal).
      </p>
      <h3 id="modulation-voct-mode" manual-section-trigger="true">
        V/Oct & microtonal
      </h3>
      <p>
        The V/Oct CV input acts a little bit differently from the other inputs:
        when the amount knob is dead center you get the classic 1V per octave
        response on the CV input to control the oscilltor's pitch. However,
        there are 4 additional quantized modes, which allow for interesting
        progression: either microtonal or bigger than semitone steps.
      </p>
      <ul>
        <li>
          When the amount knob is between center position and full right
          position, you get a 1/2V per octave response → 1 semitone step becomes
          a full tone.
        </li>
        <li>
          When the amount knob is full right, you get 1/4V per octave response →
          1 semitone becomes 2 tone.
        </li>
        <li>
          When the amount knob is between full left and center, you get 2V per
          octave course → 1 semitone step becomes a quarter tone
        </li>
        <li>
          When the amount knob full is left you get 4V per octave → 1 semitone
          step becomes an heighth of a tone.
        </li>
      </ul>
      <h3 id="modulation-modulator-mode" manual-section-trigger="true">
        Modulator mode
      </h3>
      <p>
        In modulator mode, an internal modulator oscillator (LFO or VCO) is
        applied to the parameter. There are four modulation waveforms : sine,
        triangle, square and sawtooth. Just like the actual oscillator, you can
        cycle the waveform with the wave switch, the brightness of the LED show
        the current waveform.
      </p>
      <p>
        This mode doesn't require a CV input: when there is no jack connected,
        the amount of modulation is controlled solely with the amount knob; when
        there's a jack connected to the CV input, the amount knobs is multiplied
        with the CV to yield the actual modulation amount.
      </p>
      <p>
        The modulator oscillator can be either set in LFO or VCO mode with the
        type switch (LED off is LFO, LED on is VCO), the rate knob sets the
        actual modulator frequency. In LFO mode: the oscillation frequency
        ranges between 40s and 0.1s, in VCO mode the oscillation frequency
        ranges between 8Hz and 8kHz.
      </p>
      <p>
        Note that in VCO mode, the modulator frequency follows the frequency of
        the analog oscillator. This yields some very interesting sound design
        possibilities.
      </p>

      <h3 id="modulation-ping-envelope-mode" manual-section-trigger="true">
        Ping Envelope mode
      </h3>
      <p>
        The ping envelope mode transform the CV modulator into a Decay envelope
        generator that can be triggered by 5V trigs into the CV input. You can
        set the amount of the envelope with the amount knob, and the decay rate
        with the rate knob.
      </p>
      <p>
        This mode can be used to ping parameter such as the frequency to create
        drums, or ping the TZFM amount for bell-like sounds!
      </p>

      <h2 id="presets" manual-section-trigger="true">
        Presets
      </h2>
      <p>
        Morphos preset system is at the heart of the morphing system: it allows
        users to create snapshots of a patch to be recalled later. Each
        oscillator has four banks: in these banks there are 3 layers, layer X, Y
        and Z; and each of these layers have 4 preset slots, for a total of 48
        presets per oscillator. Everything is stored inside presets: knob
        position and all modulation parameters.
      </p>

      <h3 id="presets-bank-layer" manual-section-trigger="true">
        Bank and layer selection
      </h3>
      <p>
        To select the active bank you need to press Bank once, and then use one
        of the four slots to select the desired bank on the left or right
        oscillator.
      </p>
      <p>
        You can use the layer switch to cycle between the layers: red is layer
        X, green is layer Y, and blue is layer Z. There is actually a fourth
        white layer: this is the multidimensional morphing layer. In the white
        layer you can't save, load or delete a preset, it is purely a
        performance layer… more on that later!
      </p>
      <h3 id="presets-save-delete" manual-section-trigger="true">
        Save and Delete
      </h3>
      <p>
        When you are happy with your patch, you can store it by holding Save and
        pressing one of the slots. Now everytime you press that slot, the
        oscillator will go back to the saved state. There is no concept of slot
        selection on morphos: to edit a slot you need to recall it, make your
        change and save again otherwise your change won't be stored. To clear a
        preset, hold delete and press the desired slot.
      </p>
      <h3 id="presets-copy-paste" manual-section-trigger="true">
        Copy and paste preset
      </h3>
      <p>
        Copy pasting comes in very handy when you want to morph between 2
        slightly different presets.t. Simply hold BANK and press the slot you
        want to copy, and then hold LAYER and press the destination slot.
      </p>
      <h3 id="presets-tare" manual-section-trigger="true">
        Tare
      </h3>
      <p>
        At any point in time you can reset both oscillators parameter to their
        knob's physical position by pressing the BANK and LAYER button
        simultaneously. When you do that while morphing it will work as well and
        stop the morphing.
      </p>
      <h2 id="morphing" manual-section-trigger="true">
        Morphing
      </h2>
      <p>
        Now we get to the most interesting part: morphing! The morphing system
        allows to continuously morph between multiple presets. Most people are
        used to 1-dimensional morphing, where every parameter value morphs
        continuously between value A and B. Well morphos can do that… but it can
        also do 3-dimensionnal morphing thanks to the 3-axis morph strips.
        Imagine your presets in a 3D space, each parameter (knob position,
        modulation rate and amount …) will be calculated as a morph between the
        6 closest neighboring presets in that 3D space, at each time step.
      </p>
      <h3 id="morphing-morph-strips" manual-section-trigger="true">
        Morph Strips
      </h3>
      <p>
        Morphos morph strips are groups of four specially developed FSR sensors
        that can precisely detect vertical, horizontal and pressure information
        from the pressure distribution of a finger. This data can be extracted
        to generate morphing.
      </p>
      <p>
        So every time you press and hold one of the morph strips, rather than
        sending simple On/Off information from a button, morphos actually
        detects the exact position of your finger on said strip, allowing it to
        smoothly morph between the presets in every direction.
      </p>
      <h3 id="morphing-xyz" manual-section-trigger="true">
        X/Y/Z CV
      </h3>
      <p>
        You can morph using the CV input as well, as opposed to the morph
        strips, which are more a performance oriented feature. The CVs allow you
        to “sequence” the morphing. Or you could use a 3-Axis joystick to
        control your position in the morph space. Note that when jacks are
        connected to the morph inputs,the CV will take over from the morph
        strips so it is recommended to use that when you have “built” your morph
        space! Note that the X and Y position CV inputs are scaled from -5V to
        +5V, so the X position is 1.0 at -5V and 4.0 at +5V, the Y position is
        -1.0 at -5V and +1.0 at +5V. The Z position is only positive: 0.0 at 0V
        and 1.0 at 5V
      </p>
      <h3 id="morphing-1d" manual-section-trigger="true">
        1D Morphing
      </h3>
      <p>
        In any of the 3 classic layers (X, Y and Z), when you have two adjacent
        slots filled with presets, you can very easily morph between those 2
        presets by sliding your finger between the 2, or by sending a CV to the
        X input. This axis works at any point in time, except in bank select
        mode.
      </p>
      <h3 id="morphing-3d" manual-section-trigger="true">
        3D Morphing
      </h3>
      <p>
        To be able to enjoy 3D morphing, you need to select the fourth layer
        (the white one). Now things get interesting: each parameter of the
        oscillator is calculated as a ratio of the 6 closest presets. Say you
        are not moving your finger on the horizontal axis (your X position is
        1), pressing the first slot will morph between slot 1 of layer X, and
        slot 1 of layer Z. Now if you move the pressure of your finger slightly
        higher on the slot, you will morph between X and Y, the more you move
        the pressure on the top part of the switch, the farther away you move
        from slot 1 of layer X and the closer you get to slot 1 of layer Y. You
        can of course still change the pressure applied to morph between the
        slot 1 of X, Y and Z. Now you can go even further, and move your finger
        on the horizontal axis, between the slots, and you get 3-dimensional
        morphing. Welcome to Space! We don't think this is the final frontier
        though…maybe just the extent of what can be imagined so far.
      </p>
      <p>
        Applying force on the bottom part of the slot is a little tricky: the Y
        morph is actually calculated as an offset from X and Y preset parameters
        value, applying negative Y-value (aka applying force on the bottom side
        of the slot) will apply the calculated offset negatively. An easy
        example: on slot 1 of X you store a preset with a C4 pitch, on slot 1 of
        Y you store a preset with a C5 pitch. Morphing from middle position to
        top position will morph between C4 and C5, morphing from middle to
        bottom position will morph between C4 and C3!
      </p>
    </>
  )
};

export default morphosManualData;
