import React from "react";
import ApplyModuleCard from "../ApplyingModulation/ApplyModuleCard";
import pic from "./../../images/expressPicPro.png";
import "./ExpressiveKeyboard.css";

const ExpressiveKeyboard = () => {
  const para = "« Mono quickly became my go-to synth for anything monophonic »";

  const name = "  Jeremy P. Caulfield";
  const country = "Canada";
  const src = "https://media.w3.org/2010/05/sintel/trailer_hd.mp4";
  const poster =
    "https://cdn.vox-cdn.com/thumbor/DtTWyS2ie9T4qlmJ-y_Wn_Y4QL4=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/19603652/MPCONE_angle_web.jpg";
  return (
    <>
      {/* <div className="expressiveCard">
        <ApplyModuleCard
          para={para}
          name={name}
          country={country}
          src={src}
          picJaqu={pic}
          poster={poster}
        />
      </div> */}
    </>
  );
};

export default ExpressiveKeyboard;
