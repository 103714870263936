import React from "react";

import "./../ClaudeInspiration/ClaudeInspiration.css";
import Waveform from "./../ClaudeInspiration/Waveform";
import picJaqu from "./../../images/picJaqu.png";
import AudioJaq from "./../../audio/jaquarius_morph.mp3";

export default function MorphosInspiration() {
  return (
    <>
      <div className="main">
        <div className="mainContainerClaudeInsTop">
          <div className="mainContainerClaudeIns">
            <div className="musicAudioTop">
              <Waveform url={AudioJaq} id="yoric-mono" />
            </div>

            <div className="txtPartClaude">
              <p className="paraTxtClauseIntro">
                Jaquarius
                <span className="birdRecTxt"> © 2022</span>
              </p>
            </div>
          </div>

          <div className="txtUsaClaude">
            <p className="paraUsaClaude">
              « Morphos' modulation and morphing features makes it easy to
              instantly get a wide range of timbre ! »
            </p>
            <div className="picPartInsClaud">
              <img src={picJaqu} alt="" className="imgClaude" />
              <p className="nameInsClaude">
                {" "}
                Yoric Spick aka Jaquarius
                <br />
                <span className="countryIns">France</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
