import React from "react";
import CapturingTheTacticCard from "../CaptueringTheTactic/CapturingTheTacticCard";
import DSCPic from "./../../images/DSC.png";

const LoremIpsumMor = () => {
  const firstPara = "Digital technology";
  const firstSp = " serving analog synthesis";
  const secondPara = " to its full extent.";
  const secondSp = "";
  const thirdPara = "";

  return (
    <>
      <CapturingTheTacticCard
        DSC04888={DSCPic}
        firstPara={firstPara}
        firstSp={firstSp}
        secondSp={secondSp}
        secondPara={secondPara}
        thirdPara={thirdPara}
      />
    </>
  );
};

export default LoremIpsumMor;
