import React from "react";
import ReactPlayer from "react-player";
import { Card } from "react-bootstrap";
import btnArrow from "./../../images/ArrowRightBlack.svg";

import "./LearnYoutube.css";

const YOUTUBE_PLAYLIST_ITEMS_API =
  "https://www.googleapis.com/youtube/v3/playlistItems";

const YOUTUBE_API_KEY = "AIzaSyC4GQUiIynKUrDZ6F0OaJMtheB9OA4xITI";

class YoutubeLearn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playlistItems: null,
      currentVideo: null,
      numItemToShow: 3,
      currentVideoPage: 0
    };
    this.getServerSideProps();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
    if (this.state.width < 768) {
      this.setState({ numItemToShow: 2 });
    } else {
      this.setState({ numItemToShow: 3 });
    }
  }

  getServerSideProps = async () => {
    const res = await fetch(
      `${YOUTUBE_PLAYLIST_ITEMS_API}?part=snippet&maxResults=50&playlistId=PLl7bvAIBEalkdQ1U6DxkBkEJJ0wbUBcUF&key=${YOUTUBE_API_KEY}`
    );
    const data = await res.json();
    this.setState({
      playlistItems: data.items,
      currentVideo: data.items[0].snippet.resourceId.videoId
    });
  };

  setVideoPage = page => {
    if (page >= 0 && page < this.state.playlistItems.length) {
      this.setState({
        currentVideoPage: page
      });
    }
  };

  truncate(str) {
    return str.length > 25 ? str.substring(0, 22) + "..." : str;
  }

  setCurrentVideo = videoId => {
    this.setState({
      currentVideo: videoId
    });
  };

  render() {
    return (
      <div className="learnYoutube">
        <h5 className="center-heading-tutorials">Video tutorials</h5>
        <div className="youtube-grid">
          <div className="youtube-grid-arrow">
            {" "}
            <img
              onClick={() => this.setVideoPage(this.state.currentVideoPage - 1)}
              style={{
                transform: "scale(-1)",
                WebkitTransform: "scale(-1)",
                width: "50px",
                marginRight: "15px",
                cursor: "pointer"
              }}
              src={btnArrow}
              alt=""
            />
          </div>
          {this.state.playlistItems != null ? (
            this.state.playlistItems
              .slice(
                this.state.currentVideoPage,
                this.state.currentVideoPage + this.state.numItemToShow
              )
              .map(item => (
                <Card
                  style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                  key={item.snippet.resourceId.videoId}
                  onClick={() =>
                    this.setCurrentVideo(item.snippet.resourceId.videoId)
                  }
                >
                  <Card.Img
                    src={item.snippet.thumbnails.medium.url}
                    alt={item.snippet.title}
                  />
                  <Card.Title>{this.truncate(item.snippet.title)}</Card.Title>
                  <div
                    className={
                      this.state.currentVideo ===
                      item.snippet.resourceId.videoId
                        ? "bottomTxtRand1"
                        : "bottomTxtRand"
                    }
                  ></div>
                </Card>
              ))
          ) : (
            <p>Loading...</p>
          )}
          <div className="youtube-grid-arrow">
            <img
              onClick={() => this.setVideoPage(this.state.currentVideoPage + 1)}
              style={{
                width: "50px",
                marginLeft: "15px",
                cursor: "pointer"
              }}
              src={btnArrow}
              alt=""
            />
          </div>
        </div>
        <div className="player-wrapper">
          <div className="player-container">
            <ReactPlayer
              className="react-player"
              url={`https://www.youtube.com/watch?v=${this.state.currentVideo}`}
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default YoutubeLearn;
