import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import "../MK2Whatsnew/MK2Whatsnew.css";

import dropArrow from "./../../images/Arrow-LongIntr.svg";
import VideoPlayer from "react-video-js-player";
import video from "./../../videos/MK2_trailer.mp4";

import overdrive_1 from "./../../images/overdrive_1.svg";
import D_KEYBOARD from "./../../images/3D_KEYBOARD.svg";
import D_SEQUENCER from "./../../images/3D_SEQUENCER.svg";
import autmation from "./../../images/autmation.png";
import aluminum from "./../../images/mk2_pic-1.jpg";
import pic2 from "./../../images/mk2_pic-3.jpg";
import thirdCircleLineMK2 from "./../../images/thirdCircleLineMK2.svg";


const MK2Whatsnew = () => {
  const videoSrx = video;

  return (
    <>
      <div className="mainContainerMK2">
        <div className="MK2FirstRow">
          <p className="mostStableMK2Txt">What's new</p>
          <p className="mainHeadingMK2">
            <span className="spMainHeadingMK2">PUSHING</span>THE<br />{" "}
            ENVELOPE<span className="spMainHeadingMK2">WITH <br />{" "} MK2</span> 
            
          </p>
          <p className="mainParaMK2">
            Mono was always meant to be the <strong>ultimate monophonic synthesiser</strong>. 
            After 3 years of existence and hundreds of exchanges with our <strong>vibrant 
            community of users</strong>, it felt logical to work on a new hardware version, 
            capable of handling the many <strong>new features</strong> we have in mind.
          </p>
        </div>

        <div className="MK2SecondRow">
          <div className="MK2VideoTop">
            <VideoPlayer src={videoSrx} width="720" height="720" />
          </div>
        </div>

        <div className="MK2upperDivSim">
          <div className="MK2lowerDivSim">
            <div className="MK2mainPicPart">
              <div className="MK2imgageLeftSimp">
                <img src={overdrive_1} alt="" className="MK2firstInputSimpPow" />
                <img
                  hidden
                  src={thirdCircleLineMK2}
                  alt=""
                  className="MK2lineCopy1"
                  id="lineCopy1Id"
                />
              </div>
              <img src={thirdCircleLineMK2} alt="" className="MK2cirlceLinePow1" />
              <div className="MK2txtParaRightSimp">
                <p className="MK2txtSimpPowFirstRow">
                  Analog overdrive and refined analog path
                </p>
                <p className="MK2subTxtSimpPowFirstRow">
                  MK2 implement 4 years of internal update over our 
                  appraised discrete analog path, with the addition of 
                  a custom soft overdrive circuit at the output, 
                  multiplying mono’s sonic abilities.
                </p>
              </div>
            </div>

            <div className="MK2mainPicPart">
              <div className="MK2imgageLeftSimp">
                <img src={D_KEYBOARD} alt="" className="MK2firstInputSimpPow3" />
                <img
                  hidden
                  src={thirdCircleLineMK2}
                  alt=""
                  className="MK2lineCopy1"
                  id="lineCopy1Id"
                />
              </div>
              <img src={thirdCircleLineMK2} alt="" className="MK2cirlceLinePow1" />
              <div className="MK2txtParaRightSimp">
                <p className="MK2txtSimpPowFirstRow">
                  Expressive 3D Keyboard
                </p>
                <p className="MK2subTxtSimpPowFirstRow">
                  MK2 introduces 3D sensors for the mini-keyboard, allowing for expressive 
                  controls over each notes. Velocity, aftertouch, pitch bend, and vertical 
                  modulation are possible directly on the keyboard.
                </p>
              </div>
            </div>

            <div className="MK2mainPicPart">
              <div className="MK2imgageLeftSimp">
                <img src={D_SEQUENCER} alt="" className="MK2firstInputSimpPow" />
                <img
                  hidden
                  src={thirdCircleLineMK2}
                  alt=""
                  className="MK2lineCopy1"
                  id="lineCopy1Id"
                />
              </div>
              <img src={thirdCircleLineMK2} alt="" className="MK2cirlceLinePow1" />
              <div className="MK2txtParaRightSimp">
                <p className="MK2txtSimpPowFirstRow">
                  Continuous sequencer strip
                </p>
                <p className="MK2subTxtSimpPowFirstRow">
                  3D sensors are also used for the sequencer and page keys, 
                  which allow for dozens of new musical features, like live play-head, 
                  multi-pattern morphing, and precise control over the patterns.
                </p>
              </div>
            </div>

            <div className="MK2mainPicPart">
              <div className="MK2imgageLeftSimp">
                <img src={autmation} alt="" className="MK2firstInputSimpPow2" />
                <img
                  hidden
                  src={thirdCircleLineMK2}
                  alt=""
                  className="MK2lineCopy1"
                  id="lineCopy1Id"
                />
              </div>
              <img src={thirdCircleLineMK2} alt="" className="MK2cirlceLinePow1" />
              <div className="MK2txtParaRightSimp">
                <p className="MK2txtSimpPowFirstRow">
                  High resolution automation
                </p>
                <p className="MK2subTxtSimpPowFirstRow">
                  MK2 uses a 1024 PPQN sequencer system, which allow automations to be recorded 
                  at a very-high definition with the knobs and keys. The 3D sequencer strip allow 
                  for precise creation and edition of these automation using curve mode. 
                </p>
              </div>
            </div>

            <div className="MK2mainPicPart">
              <div className="MK2imgageLeftSimp">
                <img src={aluminum} alt="" className="MK2firstInputSimpPow4" />
                <img
                  hidden
                  src={thirdCircleLineMK2}
                  alt=""
                  className="MK2lineCopy1"
                  id="lineCopy1Id"
                />
              </div>
              <img src={thirdCircleLineMK2} alt="" className="MK2cirlceLinePow1" />
              <div className="MK2txtParaRightSimp">
                <p className="MK2txtSimpPowFirstRow">
                  Full machined alluminum body
                </p>
                <p className="MK2subTxtSimpPowFirstRow">
                  Your voice has been heard! MK2 was redesigned with a full machined aluminium body. 
                  A lot of attention was given to every details. 
                </p>
              </div>
            </div>

            <div className="MK2mainPicPart">
              <div className="MK2imgageLeftSimp">
                <img src={pic2} alt="" className="MK2firstInputSimpPow4" />
                <img
                  hidden
                  src={thirdCircleLineMK2}
                  alt=""
                  className="MK2lineCopy1"
                  id="lineCopy1Id"
                />
              </div>
              <img src={thirdCircleLineMK2} alt="" className="MK2cirlceLinePow1" />
              <div className="MK2txtParaRightSimp">
                <p className="MK2txtSimpPowFirstRow">
                  Tons of interface improvement
                </p>
                <p className="MK2subTxtSimpPowFirstRow">
                  A full row of button has been added to reflect the improvement made on the software 
                  over the years. All the LEDs are now RGB, with smart color coding for a clearer interface. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MK2Whatsnew;
