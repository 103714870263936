import React from "react";
import "./ApplyingModulation.css";
import ApplyModuleCard from "./ApplyModuleCard";
import picJaqu from "./../../images/picJaqu.png";
import jaqVideo from "./../../videos/mono_contextual_mod.mp4";

const ApplyingModulation = () => {
  const para =
    "« Mono's modulation system opens up many sound design possibilities »";
  const name = " Jaquarius";
  const country = "France";

  return (
    <>
      <ApplyModuleCard
        para={para}
        name={name}
        picJaqu={picJaqu}
        country={country}
        video={jaqVideo}
      />
      {/* <div className="mainApplyModule">
        <div className="mainContainerApplyingMod">
          <div className="txtPartApplyMod">
            <p className="paraApplyModTxt">
              <AiOutlineDoubleLeft className="iconsApplyMod" />
              Applying a different modulation on each paramete is really great
              for
              <br /> me!
              <AiOutlineDoubleRight />{" "}
            </p>
            <div className="picPartUserAppMod">
              <img src={picJaqu} alt="" className="imgJaqu" />
              <p className="nameApplyModHolder">
                {" "}
                Jaquarius
                <br />
                <span className="spCountryAppMod">France</span>
              </p>
            </div>
          </div>
          <div className="mainVideoApplyMod">
            <Player src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4">
              <BigPlayButton position="center" />
            </Player>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ApplyingModulation;
