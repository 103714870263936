import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import "./PureHarmonic.css";

import arrowLong from "./../../images/Arrow-LongIntr.svg";
import circledLineAna from "./../../images/circledLineAna.svg";
import anaLogyImg from "./../../images/anaLogyImg.svg";
import anaLogySecond from "./../../images/anaLogySecond.svg";
import anaLogyThird from "./../../images/anaLogyThird.svg";
import analogyFourth from "./../../images/analogyFourth.svg";
import analogyFifth from "./../../images/analogyFifth.svg";
import analogySixth from "./../../images/analogySixth.svg";
import vscPic from "./../../images/vscPic.svg";
import arrow from "./../../images/ArrowRightBlack.svg";

const PureHarmonic = () => {
  return (
    <>
      <div className="mainContainerPureHarmonic">
        <p className="txtHowDoes">How does it sound?</p>
        <p className="pureHarmonicTxt">
          Pure <br />
          <span className="spTxtPureHar">Harmonic</span>
          <br /> Tone
        </p>
        <p className="underTheHoodTxt">
          Under the hood, there is an all-analogue signal path tuned for rich
          harmonics and creating a very pure tone. Mono shows how far a
          minimalistic, two oscillator, one filter set-up can go.
        </p>
        <div className="mainContainerAnaLogMainPic">
          <div className="subcontainerAnalogy">
            <div className="miniContainerAnaLogy">
              <img src={anaLogyImg} alt="" className="analogyImg3" />
              <img src={circledLineAna} alt="" className="peepAnalogyPic" />
              <p className="txtAnalogyPara">All-analog signal path</p>
            </div>
            <div className="miniContainerAnaLogy">
              <div className="top2OsciPics">
                <img
                  src={anaLogySecond}
                  alt=""
                  className="analogyImg3"
                  id="analogyImgId"
                />
                <img src={vscPic} alt="" className="vcoSecondPic" />
              </div>
              <img src={circledLineAna} alt="" className="peepAnalogyPic" />
              <p className="txtAnalogyPara">Two Oscillators</p>
            </div>
          </div>
          <div className="subcontainerAnalogy">
            <div className="miniContainerAnaLogy">
              <img src={anaLogyThird} alt="" className="analogyImg3" />
              <img src={circledLineAna} alt="" className="peepAnalogyPic" />
              <p className="txtAnalogyPara">Through Zero FM</p>
            </div>
            <div className="miniContainerAnaLogy">
              <img src={analogyFourth} alt="" className="analogyImg3" />
              <img src={circledLineAna} alt="" className="peepAnalogyPic" />
              <p className="txtAnalogyPara">
                Continuously
                <br />
                variable waveforms
              </p>
            </div>
          </div>
          <div className="subcontainerAnalogy">
            <div className="miniContainerAnaLogy">
              <img src={analogyFifth} alt="" className="analogyImg3" />
              <img src={circledLineAna} alt="" className="peepAnalogyPic" />
              <p className="txtAnalogyPara">Hard sync</p>
            </div>
            <div className="miniContainerAnaLogy">
              <img src={analogySixth} alt="" className="analogyImg3" />
              <img src={circledLineAna} alt="" className="peepAnalogyPic" />
              <p className="txtAnalogyPara">
                Unique 3-pole
                <br />
                State Variable Filter
              </p>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "40px" }}>
          <Link to={"/mono/manual#synth-engine"} className="learnMorePureTxt">
            Learn more in the manual{" "}
            <img src={arrow} alt="" className="arrowPureLearn" />
          </Link>
        </div>
        {/* <div className="monoArrowDiv">
          <p className="monoTxtStudio">MONO in the studio</p>
          <img src={arrowLong} alt="" className="arrowLong" />
        </div> */}
      </div>
    </>
  );
};

export default PureHarmonic;
