import React from "react";
import "../SimplePowerful/SimplePowerful.css";
import symbolInput from "./../../images/SymbolInput.svg";
import cirlceLinePow1 from "./../../images/cirlceLinePow1.svg";
import lineCopy1 from "./../../images/lineCopy1.svg";
import unionSimp from "./../../images/UnionSimp.svg";
import step1 from "./../../images/step1.svg";
import group64Pic from "./../../images/Group64.svg";
import undoImg from "./../../images/undoImg.svg";
import redoImg from "./../../images/redoImg.svg";
import plusIconPic from "./../../images/plusIconPic.svg";
import thirdCircleLine from "./../../images/thirdCircleLine.svg";
import circledLineSecond from "./../../images/circledLineSecond.svg";
import playButtonImg from "./../../images/playButtonImg.svg";
import saveImg from "./../../images/saveImg.svg";
import outPutPic from "./../../images/outPutPic.svg";
const SimplePowerfulMK2 = () => {
  return (
    <>
      <div className="mainContainerSimplePowerful">
        <div className="txtPartSimplPow">
          <p className="doItAllTxt">The Do-It-All Composing Tool</p>
          <p className="simplePowerfulTxt">
            Simple <span className="spYetPowerTxt">yet powerful</span> sequencer
          </p>
          <p className="mainParaSiimplePower">
            Mono's sequencer does it all - starting with a straight-forward,
            <span className="mainParaSpTxtSimpPow">
              user-friendly interface{" "}
            </span>
            that can build complex ratchet phrases with parameter automations.
            With step-per-function capability, it's possible to{" "}
            <span className="mainParaSpTxtSimpPow">
              write a track's core percussion as well as a bass or lead line
              simultaneously
            </span>
            … All on a mono synth.{" "}
          </p>
        </div>

        <div className="upperDivSim">
          <div className="lowerDivSim">
            <div className="mainPicPart">
              <div className="imgageLeftSimp">
                <img src={symbolInput} alt="" className="firstInputSimpPow" />
                <img
                  hidden
                  src={lineCopy1}
                  alt=""
                  className="lineCopy1"
                  id="lineCopy1Id"
                />
              </div>
              <img src={cirlceLinePow1} alt="" className="cirlceLinePow1" />
              <div className="txtParaRightSimp">
                <p className="txtSimpPowFirstRow">
                  Easily record and edit patterns
                </p>
                <p className="subTxtSimpPowFirstRow">
                  With direct control on MONO or with an external MIDI keyboard
                </p>
              </div>
            </div>
            <div className="mainPicPart" id="mainPicPart2">
              <div className="imgageLeftSimp" style={{ paddingRight: "40px" }}>
                <img src={step1} alt="" className="step1Img" />
                <img src={step1} alt="" className="step1Img2" />
                <img src={step1} alt="" className="step1Img3" />
                <img src={group64Pic} alt="" className="group64Img" />

                <img hidden src={lineCopy1} alt="" className="lineCopySecond" />
              </div>
              <img
                src={circledLineSecond}
                alt=""
                className="cirlceLinePow1"
                id="cirlceLinePow1"
              />
              <div className="txtParaRightSimp" id="txtParaRightSimpId1">
                <p className="txtSimpPowFirstRow">
                  Up to 64 steps, each containing
                </p>
                <div className="subTxtSimpPowFirstRow">
                  <ul>
                    <li>Note + Trigger ON</li>
                    <li>Parameter states (patch preset)</li>
                    <li> Parameter automations</li>
                    <li>Ratchet</li>
                    <li>Slide</li>
                    <li>Accent</li>
                    <li>Microshift</li>
                    <li>Probability</li>
                    <li>Mute</li>
                  </ul>
                </div>
                <p className="learnMoreTxtSimp">
                  Learn more in the manual{" "}
                  <img src={unionSimp} alt="" className="unionSimpImg" />
                </p>
              </div>
            </div>

            <div className="mainPicPart" id="mainPicPart3">
              <div className="imgageLeftSimp">
                <div className="undoRedoContainer">
                  <img src={undoImg} alt="" className="undoImg" />
                  <img src={plusIconPic} alt="" className="plusSimple" />
                  <img src={redoImg} alt="" className="redoImg" />
                </div>
                <img
                  hidden
                  src={lineCopy1}
                  alt=""
                  className="lineCopy1"
                  id="lineCopy3"
                />
              </div>
              <img src={thirdCircleLine} alt="" className="cirlceLinePow1" />
              <div className="txtParaRightSimp" id="txtParaRightSimp">
                <p className="txtSimpPowFirstRow">Powerful Undo-Redo</p>
                <p className="subTxtSimpPowFirstRow">
                  Cancel (or re-instate) thousand of events, even after rebooting.
                </p>
              </div>
            </div>

            <div className="mainPicPart" id="mainPicPart5">
              <div className="imgageLeftSimp">
                <img src={playButtonImg} alt="" className="firstInputSimpPow" />
                <img
                  hidden
                  src={lineCopy1}
                  alt=""
                  className="lineCopy1"
                  id="secondLastId"
                />
              </div>
              <img src={cirlceLinePow1} alt="" className="cirlceLinePow1" />
              <div className="txtParaRightSimp">
                <p className="txtSimpPowFirstRow">Many run options</p>
                <div className="subTxtSimpPowFirstRow">
                  <ul>
                    <li>9 plays styles</li>
                    <li>3 plays modes</li>
                    <li>Swing</li>
                    <li>Live mode</li>
                    <li>Loop mode</li>
                    <li>Song mode</li>
                    <li>Preset synth mode</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="mainPicPart" id="mainPicPart6">
              <div className="imgageLeftSimp">
                <img src={saveImg} alt="" className="firstInputSimpPow" />
                <img
                  hidden
                  src={lineCopy1}
                  alt=""
                  className="lineCopy1"
                  id="endLineCopyID"
                />
              </div>
              <img src={cirlceLinePow1} alt="" className="cirlceLinePow1" />
              <div className="txtParaRightSimp">
                <p className="txtSimpPowFirstRow">Huge memory</p>
                <p className="subTxtSimpPowFirstRow">
                Mono uses an SD card for storage, allowing for virtually unlimited amount of patterns and projects.
                </p>
              </div>
            </div>
            <div className="mainPicPart" id="mainPicPart7">
              <div className="imgageLeftSimp">
                <img src={outPutPic} alt="" className="firstInputSimpPow" />
              </div>
              <img src={cirlceLinePow1} alt="" className="cirlceLinePow1" />
              <div className="txtParaRightSimp">
                <p className="txtSimpPowFirstRow">MIDI & USB</p>

                <div className="subTxtSimpPowFirstRow">
                  <ul>
                    <li> MIDI Jack input</li>
                    <li> MIDI Jack thru</li>
                    <li> MIDI Jack output</li>
                    <li> USB C Device</li>
                    <li> USB C Host</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SimplePowerfulMK2;
