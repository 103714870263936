import React from "react";
import { Link } from "react-router-dom";

import Header from "../../Components/Header/Header";
import FooterNorand1 from "../../Components/FooterNorand/FooterNorand1";

import "./RetailersPage.css";

const AboutUsPage = () => {
  return (
    <div>
      <div backdrop-trigger="true"></div>
      <Header />
      <div className="main-bg">
        <h1 className="discover-heading">
          WHERE TO
          <span className="discover-heading-bold"> BUY</span>
        </h1>
      </div>
      <div className="retailers-section-1">
        <p className="retailers-text">
          If you are a retailer interested in carrying Norand Products or if you
          currently carry Norand Products and would like your website listed
          here please <Link to={"/contact-us"}> contact us!</Link>
        </p>
      </div>
      <div className="retailers-section-2">
        <div className="retailers-col">
          <h3>Europe:</h3>
          <a href="https://www.modularsquare.com/fr/brand/norand/">Modular Square - FR</a>
          <br />
          <a href="https://www.stars-music.fr/norand/all/">Star's Music - FR</a>
          <br />
          <a href="https://schneidersladen.de/de/search?sSearch=norand">Schneidersladen - DE</a>
          <br />
          <a href="https://www.musicstore.com/fr_FR/EUR/search?SearchTerm=norand">Music Store - DE</a>
          <br />
          <a href="https://www.dj-technik.de/norand">DJ-Technik.de - DE</a>
          <br />
          <a href="https://www.justmusic.de/suche?s=norand">Just Music - DE</a>
          <br />
          <a href="https://www.thomann.de/de/norand.html">Thomann - DE</a>
          <br />
          <a href="https://www.martinpas.com/products/norand/mono">Martin Pas - IT</a>
          <br />
          <a href="https://www.dlxmusic.se/sok?q=norand">Deluxe Music - SE</a>
          <br />
          <a href="https://www.houseofsound.ch/catalogsearch/result/?q=norand">House of Sound - CH</a>
          <br />
          <a href="https://www.midiamsterdam.nl/catalogsearch/result/?q=norand">Midi Amsterdam - NL</a>
          <br />
          <a href="https://www.synthesizer.gr/index.php?route=product/search&search=NORAND">Synthesizer.gr - GR</a>
          <br />
          <a href="https://escapefromnoise.com/search/?lang=sv&q=NORAND">Escape From Noise - SE</a>
          <br />
        </div>
        <div className="retailers-col">
          <h3>Americas:</h3>
          <a href="https://www.perfectcircuit.com/norand">Perfect Circuit - USA</a>
          <br />
          <a href="https://www.detroitmodular.com/catalogsearch/result/?q=norand">Detroit Modular - USA</a>
          <br />
          <a href="https://nightlife-electronics.com/search?type=product&q=norand">
            Nightlife Electronics - CA
          </a>
          <br />
          <a href="https://moogaudio.com/search?type=product&options%5Bprefix%5D=last&options%5Bunavailable_products%5D=last&q=norand">Moog Audio - CA</a>
          <br />
        </div>
        <div className="retailers-col">
          <h3>UK:</h3>
          <a href="https://www.signalsounds.com/catalogsearch/result/?q=+norand">Signal Sound</a>
          <br />
          <a href="https://www.elevatorsound.com/shop/?_sf_s=norand">Elevator Sound</a>
          <br />
          <a href="https://rubadub.co.uk/search?type=product&options%5Bunavailable_products%5D=last&options%5Bprefix%5D=none&q=norand*">Rubadub</a>
          <br />
        </div>
      </div>
      <FooterNorand1 />
    </div>
  );
};

export default AboutUsPage;
