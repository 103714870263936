import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import headerPic1 from "./../../images/headerPicRight1.svg";
import headerPic2 from "./../../images/headerPicRight2.svg";
import headerPic3 from "./../../images/headerPicRight3.svg";
import headerPic4 from "./../../images/headerNewPic.svg";
import headerPic5 from "./../../images/headerJoinPic.svg";

import burger from "./../../images/burger.svg";

import { Navbar, Nav } from "react-bootstrap";

import trigger from "./../../ScrollTrigger";

class Header extends React.Component {
  constructor(props) {
    super(props);
    // Set initial state (ONLY ALLOWED IN CONSTRUCTOR)
    this.state = {
      displayBackdrop: false
    };
  }

  SetBackdrop(bb) {
    this.setState({
      displayBackdrop: bb
    });
  }

  componentDidMount() {
    trigger.add("[backdrop-trigger]", {
      toggle: {
        callback: {
          in: trigger => {
            // `trigger` contains the Trigger object that goes out
            // of the viewport
            return new Promise((resolve, reject) => {
              this.SetBackdrop(false);
            });
          },
          out: trigger => {
            // `trigger` contains the Trigger object that goes out
            // of the viewport
            return new Promise((resolve, reject) => {
              this.SetBackdrop(true);
            });
          }
        }
      }
    });
  }

  render() {
    return (
      <>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="none"
          variant="dark"
          fixed={"top"}
          className={` ${this.props.fixed ? "hide-header" : ""} ${
            this.state.displayBackdrop
              ? "navbar-backdrop"
              : "navbar-no-backdrop"
          }`}
        >
          <Navbar.Brand>
            <Link to="/">
              <img src={headerPic3} alt="" className="headerLogo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            style={{ border: "none" }}
          >
            <span>
              <img src={burger} alt="" />
            </span>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto" id="navbarCenterContent">
              <Link className="centerContentTxtHeader" to="/monomk2">
                Mono MK2  <img src={headerPic4} alt="" className="newPicHeader" />
              </Link>
              <Link className="centerContentTxtHeader" to="/mono">
                Mono
              </Link>
              <Link className="centerContentTxtHeader" to="/morphos">
                Morphos
              </Link>
              <Link className="centerContentTxtHeader" to="/learn">
                Learn
              </Link>
              <Link className="centerContentTxtHeader" to="/downloads">
                Downloads
              </Link>
              <Link className="centerContentTxtHeader" to="/about">
                About
              </Link>
              <a
                className="centerContentTxtHeader"
                href="https://community.norand.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Community{" "}
                <img src={headerPic5} alt="" className="newPicHeader" />
              </a>
            </Nav>
            <Nav hidden className="rightIconPartHeader">
              <Nav.Link href="#deets">
                <img src={headerPic1} alt="" className="headerPicRight" />
              </Nav.Link>
              <Nav.Link href="#deets">
                <img src={headerPic2} alt="" className="headerPicRight" />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}

export default Header;
