import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import "../Innovativetools/Innovativetools.css";
import btnArrow from "./../../images/ArrowRightBlack.svg";
import btnArrowWhite from "./../../images/ArrowRightWhite.svg";

const Innovativetools = () => {
  return (
    <div className="innovative-main">
      <Container>
        <Row>
          <Col className="mainInnovative">
            <div>
              <p className="norand-phiolosphy">Norand's Philosophy</p>
              <h1 className="innovative-heading">
                INNOVATIVE <br /> TOOLS
                <br />
                <span className="inspire-heading">
                  INSPIRE <br /> CREATIVITY
                </span>
              </h1>
            </div>
            <div className="inno-btn-div">
              <Link to={"/about"} className="inno-about-btn">
                About Us <img src={btnArrowWhite} alt="" className="imgArrow" />
              </Link>
              <Button hidden className="inno-join-btn">
                Join The Team <img src={btnArrow} alt="" className="imgArrow" />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Innovativetools;
