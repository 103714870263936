import React from "react";

class LoadingSpinner extends React.Component {
  render() {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#222726",
          boxShadow: "0px 6px 25px rgba(0, 0, 0, 0.4)",
          borderRadius: "25px",
          zIndex: "9999",
          disspaly: "flex",
          padding: "50px",
        }}
      >
        <p
          style={{
            color: "white",
            margin: "auto",
            paddingBottom: "40px",
          }}
        >
          Redirecting to shop ...
        </p>
        <div className="loading-spinner"></div>
      </div>
    );
  }
}

export default LoadingSpinner;
