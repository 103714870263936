import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import "./TZFMMorphos.css";

import dropArrow from "./../../images/Arrow-LongIntr.svg";
import VideoPlayer from "react-video-js-player";
import video from "./../../videos/morphos_tzfm.mp4";

import circledLineAna from "./../../images/circledLineAna.svg";
import anaLogySecond from "./../../images/anaLogySecond.svg";
import analogyFourth from "./../../images/analogyFourth.svg";
import analogyFifth from "./../../images/analogyFifth.svg";
import tracking from "./../../images/tracking.svg";
import vscPic from "./../../images/vscPic.svg";
import arrow from "./../../images/ArrowRightBlack.svg";

const TZFMMorphos = () => {
  const videoSrx = video;

  return (
    <>
      <div className="mainContainerTZFM">
        <div className="TZFMFirstRow">
          <p className="mostStableTZFMTxt">Versatile, extremely stable</p>
          <p className="mainHeadingTZFM">
            TZFM analog <br />{" "}
            <span className="spMainHeadingTZFM">Oscillators</span>
          </p>
          <p className="mainParaTZFM">
            MORPHOS let’s you push sonic limits with control, helping get the
            most from your modular setup. The two all-analogue, digitally
            controlled Oscillators provide very pure harmonics, 4 wave types and
            extremely stable TZFM (Through Zero Frequency Modulation).
          </p>
        </div>

        <div className="TZFMSecondRow">
          <div className="secondRowTxtPartTZFM">
            <p className="extemlyStableTxt">
              Extremely stable Through Zero Frequency Modulation
            </p>
            <p className="pageRandTZFM">
              {" "}
              Getting excellent pitch tracking with analog TZFM is quite
              difficult to achieve. Our custom oscillator design taken from
              Mono, was refined for Morphos. Thanks to its active calibration
              system, it tracks over 10 octaves, even at high TZFM ratio.
            </p>
          </div>
          <div className="TZFMVideoTop">
            <VideoPlayer src={videoSrx} width="640" height="360" />
          </div>
        </div>
        <div className="picPortionMainTZFM">
          {/* <img src={synthSpecs2} alt="" className="monoTZFM" /> */}

          <div className="mainContainerAnaLogMainPic">
            <div className="subcontainerAnalogy">
              <div className="miniContainerAnaLogy">
                <div className="top2OsciPics">
                  <img
                    src={anaLogySecond}
                    alt=""
                    className="analogyImg3"
                    id="analogyImgId"
                  />
                  <img src={vscPic} alt="" className="vcoSecondPic" />
                </div>
                <img src={circledLineAna} alt="" className="peepAnalogyPic" />
                <p className="txtAnalogyPara">
                  The two all-analogue,
                  <br /> digitally controlled <br />
                  Oscillators
                </p>
              </div>
              <div className="miniContainerAnaLogy">
                <img src={analogyFourth} alt="" className="analogyImg" />
                <img src={circledLineAna} alt="" className="peepAnalogyPic" />
                <p className="txtAnalogyPara">
                  Continuously
                  <br />
                  variable waveforms
                </p>
              </div>
            </div>
            <div className="subcontainerAnalogy">
              <div className="miniContainerAnaLogy">
                <img src={analogyFifth} alt="" className="analogyImg" />
                <img src={circledLineAna} alt="" className="peepAnalogyPic" />
                <p className="txtAnalogyPara">Soft sync</p>
              </div>
              <div className="miniContainerAnaLogy">
                <img src={tracking} alt="" className="analogyImg" />
                <img src={circledLineAna} alt="" className="peepAnalogyPic" />
                <p className="txtAnalogyPara">Soft sync</p>
              </div>
            </div>
          </div>
        </div>
        <div className="learnTxtTop">
          <Link to={"/mono/manual#synth-engine"} className="learnMorePureTxt">
            Learn more in the manual{" "}
            <img src={arrow} alt="" className="arrowPureLearn" />
          </Link>
        </div>

        <div hidden className="topMorphosArrowTZMF">
          <p className="txtMorphosStudioTZMF"> MORPHOS in the studio</p>
          <img src={dropArrow} alt="" className="dropArrowTZFM" />
        </div>
      </div>
    </>
  );
};

export default TZFMMorphos;
