import React from "react";
import "./../PrivacyPol/PrivacyPol.css";

const PrivacyPol = () => {
  return (
    <>
      <div className="mainContainerPrivacyPolicy">
        <div className="containerPrivacyForWidth">
          <p className="privacyMainHeader" id="privacyMainHeader1">
            LEGAL INFORMATION
          </p>

          <p className="subHeadingPrivacyPolicy">
            1) Information about the publisher of the site
          </p>
          <p className="paraTxtPrivacyPol">
            <span className="strong-1">Company name :</span> NORAND SAS
          </p>
          <p className="paraTxtPrivacyPol">
            <span className="strong-1">Legal form :</span> Simplified Joint
            Stock Company
          </p>
          <p className="paraTxtPrivacyPol">
            <span className="strong-1">Share capital :</span> 1,000 euros
          </p>
          <p className="paraTxtPrivacyPol">
            <span className="strong-1">Address of registered office :</span> 10
            rue du Moulin 92170 Vanves - France
          </p>
          <p className="paraTxtPrivacyPol">
            <span className="strong-1">Postal address :</span> 10 rue du Moulin
            92170 Vanves - France
          </p>
          <p className="paraTxtPrivacyPol">
            <span className="strong-1">Telephone :</span> 01 41 08 02 10
          </p>
          <p className="paraTxtPrivacyPol">
            <span className="strong-1">E-mail :</span> contact@norand.io
          </p>
          <p className="paraTxtPrivacyPol">
            <span className="strong-1">
              Registration with the RCS – Trade and Companies Registry (SIREN) :
            </span>{" "}
            849 126 149 R.C.S Nanterres
          </p>
          <p className="paraTxtPrivacyPol">
            <span className="strong-1">Name of the publication director :</span>{" "}
            Mathieu Frohlich
          </p>

          <p className="subHeadingPrivacyPolicy">
            2) information on the hosting company of the site
          </p>
          <p className="paraTxtPrivacyPol">
            <span className="strong-1">Company name : </span> Google Firebase
            Hosting
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPol;
