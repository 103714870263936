import React from "react";
import "./PrivacyPol.css";

const PrivacyPol = () => {
  return (
    <>
      <div className="mainContainerPrivacyPolicy">
        <div className="containerPrivacyForWidth">
          <p className="privacyMainHeader" id="privacyMainHeader1">PRIVACY POLICY</p>
          <p className="paraTxtPrivacyPol">
            Norand attaches great importance to the protection of your personal
            data. This privacy policy describes how we collect and process your
            personal data. The personal data contains all the information
            concerning an identified or identifiable physical individual. By
            registering and visiting our site, you agree to the provisions of
            this privacy policy.
          </p>

          <p className="subHeadingPrivacyPolicy">Article 1: COLLECTION</p>
          <p className="paraTxtPrivacyPol">
            Norand, by collecting and processing your personal data, is
            responsible for the processing of personal data as required by
            national legislation concerning the protection of personal data. The
            personal data that is collected contains the information that you
            provide us with when you fill out a form to open an account on our
            site, whenever you purchase products on our website or whenever you
            contact us. This information is in particular contact information,
            such as your name, your address, your telephone number, your email
            address. Also collected as part of this process are details about
            orders, purchases, deliveries, invoices etc. Personal data may also
            be collected automatically when you visit our website or open an
            account. This data may be obtained through the use of cookies that
            you have accepted when you enter our site and cookies which come
            from third-party sites. The connection data, such as your IP address
            or your browsing history may also be collected. The data which is
            collected is sent to the Sales Department at Norand.
          </p>
          <p className="subHeadingPrivacyPolicy">Article 2: PURPOSE / USE</p>
          <p className="subSubHeadingPrivacyPol">
            2.1) Purpose of the collection and the processing of personal data
          </p>
          <p className="paraTxtPrivacyPol">
            The reason that we collect and process your personal data is to
            allow us to improve our products, to provide you with a customer
            service, to make your visit to the site a more personal experience,
            to allow you to save your shopping cart, manage your orders, and
            with your consent, to send you promotional messages and other
            information about us.
          </p>
          <p className="subSubHeadingPrivacyPol">2.2) Sales canvassing</p>

          <p className="paraTxtPrivacyPol">
            In the absence of your agreement, we undertake not use your personal
            data for sales canvassing purposes. You can unsubscribe and opt out
            of receiving promotional messages by changing the settings on your
            account.
          </p>
          <p className="subHeadingPrivacyPolicy">Article 3: COOKIES</p>
          <p className="paraTxtPrivacyPol">
            A cookie is a file which is placed on the user’s hard drive and
            which allows the party which placed it there to identify and store
            information concerning the browsing habits of the user (information
            concerning pages viewed, the date and time when they were viewed,
            key words used, geo-localization).
          </p>
          <p className="paraTxtPrivacyPol">
            Please be advised that there is a banner announcing that we use
            cookies when you access our site. You have the option of accepting
            or refusing the cookies.
          </p>
          <p className="paraTxtPrivacyPol">
            These cookies make browsing easier by allowing the site to memorise
            your browsing preferences, such as for example your language choice,
            or by saving your shopping cart.
          </p>
          <p className="paraTxtPrivacyPol">
            Please be informed that most browsers are by default configured to
            automatically accept cookies and store them on your computer or
            terminal. You are entitled to refuse the storage of cookies by
            configuring your browser settings.
          </p>
          <p className="paraTxtPrivacyPol">
            However, if you refuse to accept cookies you may find that our site
            doesn’t work as well.
          </p>

          <p className="subHeadingPrivacyPolicy">Article 4: DURATION OF CONSERVATION OF DATA</p>
          <p className="paraTxtPrivacyPol">
            The data which is collected must be kept for a limited period only
            which must not exceed the time necessary for the purposes for which
            it is collected and processed.
          </p>
          <p className="paraTxtPrivacyPol">
            The data will be deleted after a period of 3 years following your
            registration on our site.
          </p>
          <p className="subHeadingPrivacyPolicy">Article 5: RIGHT of ACCESS and RECTIFICATION, MODIFICATION</p>
          <p className="paraTxtPrivacyPol">
            You may access, modify, rectify and delete your personal data or
            refuse the processing of your personal data by logging into your
            account or by sending a message to the following link:
            https://www.norand.io/contact
          </p>
          <p className="paraTxtPrivacyPol">
            We take every appropriate precaution to verify the identity of
            persons making such requests before giving them access to personal
            data or before implementing their corrections or their refusal so as
            to prevent the risk of misuse.
          </p>
          <p className="subHeadingPrivacyPolicy">Article 6: DISCLOSURE</p>
          <p className="paraTxtPrivacyPol">
            Your data will not be sent to third parties, and in particular to
            our commercial partners, without your express consent.
          </p>
          <p className="paraTxtPrivacyPol">
            In certain cases, we may be forced to release your data in
            accordance with mandatory legal provisions. Data may in particular
            need to be sent to public authorities, as part of a police
            investigation, or to financial organisations in particular with
            regard to payments.
          </p>
          <p className="subHeadingPrivacyPolicy">Article 7: SECURITY</p>
          <p className="paraTxtPrivacyPol">
            We guarantee that we take every appropriate precaution to preserve
            data security so as to prevent the data from being damaged or
            tampered with or unauthorized third parties from gaining access and
            misusing the data.
          </p>
          <p className="paraTxtPrivacyPol">
            As part of our security measures we use data encryption using SSL
            (Secure-Socket- Layer) software, and firewalls.
          </p>
          <p className="paraTxtPrivacyPol">
            If you think that your account may have been pirated, please contact
            us at the following link: https://www.norand.io/contact.
          </p>
          <p className="subHeadingPrivacyPolicy">Article 8 - THIRD-PARTY SERVICES</p>
          <p className="paraTxtPrivacyPol">
            We hand over information that we receive to third parties solely for
            the purposes described in the following:
          </p>
          <p className="paraTxtPrivacyPol">
            a. In certain circumstances, under force of law, Norand must
            disclose information to third parties. This occurs notably when law
            enforcement authorities oblige Norand to disclose information
            relating to a suspected crime or abuse. Cases regarding companies
            who are inquiring about credit card abuse are included.
          </p>
          <p className="paraTxtPrivacyPol">b. Google Analytics</p>
          <p className="paraTxtPrivacyPol">
            Google Analytics is a web analytics service provided by Google, Inc.
            that Norand uses to help analyze how the site is used; this service
            uses cookies that generate information about your use of the
            website, which is then transmitted to and stored by Google on
            servers in the United States.
          </p>
          <p className="paraTxtPrivacyPol">
            This information will be used by Google to evaluate your use of the
            website, compiling reports on website activity for Norand and
            providing other services relating to website activity and internet
            usage.
          </p>
          <p className="paraTxtPrivacyPol">
            To opt out of this so that your activity on this website is not
            tracked, follow this link:
          </p>
          <p className="paraTxtPrivacyPol">https://support.google.com/ads/answer/2662922?hl=en</p>
          <p className="paraTxtPrivacyPol">c. Google - Remarketing</p>
          <p className="paraTxtPrivacyPol">
            Re-marketing is Google’s program for “interest-based advertising”,
            which Norand uses within the scope of its marketing operations. A
            special browser cookies is stored on the user’s computer in order to
            complete this process. This allows Norand, Google or third-party
            services to serve users special ads when visiting other websites.
          </p>
          <p className="paraTxtPrivacyPol">d. Facebook</p>
          <p className="paraTxtPrivacyPol">
            The Norand website utilise plug-ins from the social network Facebook
            (www.facebook.com), which are marked with a Facebook company logo or
            are noted “Facebook Social Plug-in”. When accessing such a website
            via Norand website, a direct connection is set up to servers through
            the Facebook browser. Facebook receives information that you have
            visited the Norand site from the integrated plug-in. Facebook can
            connect the visit to our site with your Facebook account as long as
            you are logged in. To prevent such a collection of data through
            Facebook, you must log out on Facebook before visiting our website.
          </p>
          <p className="paraTxtPrivacyPol">e. Stripe and Paypal</p>
          <p className="paraTxtPrivacyPol">
            Financial transactions are handled by our payment service provider
            stripe and Paypal. We will only share transaction data with them for
            the purposes of processing your payments and refunds.
          </p>
      
          <p className="privacyMainHeader" id="privacyMainHeader">Politique de confidentialité</p>

          <p className="paraTxtPrivacyPol">
            Norand accorde une grande importance à la protection de vos données
            personnelles.
          </p>
          <p className="paraTxtPrivacyPol">
            La présente politique de confidentialité décrit la manière dont nous
            recueillons et traitons vos données personnelles.
          </p>
          <p className="paraTxtPrivacyPol">
            Les données personnelles regroupent toute information concernant une
            personne physique identifiée ou identifiable.
          </p>
          <p className="paraTxtPrivacyPol">
            En accédant à notre site et en vous y inscrivant, vous acceptez les
            dispositions de la présente politique de confidentialité.
          </p>
        
          <p className="subHeadingPrivacyPolicy">Article 1 : COLLECTE</p>
          <p className="paraTxtPrivacyPol">
            Norand, en collectant et traitant vos données personnelles, est
            qualifié de responsable de traitement de données à caractère
            personnel en vertu des lois nationales sur la protection des données
            personnelles.
          </p>
          <p className="paraTxtPrivacyPol">
            Les données personnelles collectées regroupent les informations que
            vous nous fournissez lorsque vous remplissez un formulaire pour
            l’ouverture d’un compte sur notre site, l’achat de produits sur
            notre site ou lorsque vous nous signalez ou nous contactez.
          </p>
          <p className="paraTxtPrivacyPol">
            Ces informations sont notamment vos coordonnées, telles que votre
            nom, votre adresse, votre numéro de téléphone, votre adresse mail.
            Sont également collectés par ce biais, les informations relatives
            aux commandes, achats, livraisons, facturations etc.
          </p>
          <p className="paraTxtPrivacyPol">
            Les données personnelles peuvent être également collectées
            automatiquement lorsque vous accédez à notre site ou ouvrez un
            compte. Ces données peuvent provenir de l’utilisation des cookies
            que vous avez acceptés en accédant à notre site et de cookies émis
            par des sites tiers. Les données de connexion, comme votre adresse
            IP ou votre historique de navigation peuvent être également
            collectées.
          </p>
          <p className="paraTxtPrivacyPol">
            Le service commercial de Norand est destinataire des données
            collectées.
          </p>
          <p className="subHeadingPrivacyPolicy">Article 2 : FINALITE / UTILISATION</p>
          <p className="subSubHeadingPrivacyPol">
            2.1) Finalité de la collecte et du traitement des données
            personnelles
          </p>
          <p className="paraTxtPrivacyPol">
            Nous collectons et traitons vos données personnelles afin que vous
            puissiez nous permettre d’améliorer nos produits, de vous fournir un
            service client, de personnaliser votre visite sur le site,
            d’enregistrer votre panier d’achat, de gérer vos commandes, et avec
            votre accord, de vous envoyer des messages promotionnels et autres
            informations nous concernant.
          </p>
          <p className="subSubHeadingPrivacyPol">2.2) Prospection commerciale</p>
          <p className="paraTxtPrivacyPol">
            A défaut d’accord de votre part, nous nous engageons à ne pas
            utiliser vos données personnelles à des fins de prospection
            commerciale.
          </p>
          <p className="paraTxtPrivacyPol">
            Vous pouvez vous désabonner et refuser de recevoir des messages
            promotionnels en modifiant vos paramètres sur votre compte.
          </p>
          <p className="subHeadingPrivacyPolicy">Article 3 : COOKIES</p>
          <p className="paraTxtPrivacyPol">
            Un cookie est un fichier déposé sur le disque dur de l’utilisateur
            permettant à celui qui les a déposés d’identifier et de stocker des
            informations relatives à la navigation de l’utilisateur
            (informations relatives aux pages consultées, aux dates et heures de
            la consultation, mots clés utilisés, géolocalisation).
          </p>
          <p className="paraTxtPrivacyPol">
            Vous êtes informés que nous déposons des cookies sur notre site par
            la bannière apparaissant lorsque vous accédez à notre site. Vous
            avez la possibilité d’accepter ou de refuser le dépôt de cookies.
          </p>
          <p className="paraTxtPrivacyPol">
            Ces cookies facilitent la navigation en permettant au site de se
            souvenir de vos préférences de navigation, comme par exemple le
            choix de la langue, ou la conservation d’un panier d’achat.
          </p>

          <p className="paraTxtPrivacyPol">
            Vous êtes informés que la plupart des navigateurs sont configurés
            par défaut pour accepter automatiquement les cookies et les stocker
            sur votre équipement terminal. Vous pouvez vous opposer au stockage
            de ces cookies en configurant les paramètres de votre navigateur.
          </p>
          <p className="paraTxtPrivacyPol">
            L’opposition au stockage de cookies risque cependant d’entraver le
            bon fonctionnement de notre site.
          </p>
          <p className="subHeadingPrivacyPolicy">Article 4 : DUREE DE CONSERVATION DES DONNEES</p>
          <p className="paraTxtPrivacyPol">
            Les données collectées doivent être conservées pour une durée
            limitée qui n'excède pas la durée nécessaire aux finalités pour
            lesquelles elles sont collectées et traitées.
          </p>
          <p className="paraTxtPrivacyPol">
            Les données seront supprimées après un délai de 3 ans suivant votre
            inscription à notre site.
          </p>
          <p className="subHeadingPrivacyPolicy">Article 5 : DROIT D’ACCES ET DE RECTIFICATION, MODIFICATION</p>
          <p className="paraTxtPrivacyPol">
            Vous pouvez, accéder, modifier, rectifier et supprimer vos données
            personnelles ou vous opposez au traitement de vos données
            personnelles en vous connectant à votre compte ou en adressant un
            courrier électronique au service commercial sur ce lien :
            https://www.norand.io/contact
          </p>
          <p className="paraTxtPrivacyPol">
            Nous prendrons toutes les précautions utiles afin de vérifier
            l’identité de la personne à l’origine de la demande, avant de lui
            donner l’accès à ses données personnelles ou de donner suite à ses
            corrections ou à son opposition et ce afin de prévenir le risque de
            demandes abusives.
          </p>
          <p className="subHeadingPrivacyPolicy">Article 6 : DIVULGATION</p>
          <p className="paraTxtPrivacyPol">
            Vos données ne pourront pas être transmises à des tiers, et
            notamment à nos partenaires commerciaux, sans votre accord exprès.
          </p>
          <p className="paraTxtPrivacyPol">
            Dans certains cas, nous serons contraint de transmettre vos données
            en application de dispositions légales impératives. Le transfert de
            données pourra notamment s’effectuer auprès d’autorités publiques,
            dans le cadre d’une enquête policière, ou auprès d’organismes
            financiers concernant notamment les opérations de paiement.
          </p>
          <p className="subHeadingPrivacyPolicy">Article 7 : SECURITE</p>
          <p className="paraTxtPrivacyPol">
            Nous garantissons prendre toutes précautions utiles pour préserver
            la sécurité des données, pour empêcher qu’elles soient endommagées
            ou déformées ou que des tiers non autorisés y aient accès et en
            fassent une utilisation abusive.
          </p>
          <p className="paraTxtPrivacyPol">
            Nos mesures de sécurité incluent notamment le cryptage de donnés
            avec un logiciel de serveur sécurisé SSL (Secure-Socket-Layer), des
            pares-feux.
          </p>
          <p className="paraTxtPrivacyPol">
            Si vous estimez que votre compte a fait l’objet d’une utilisation
            abusive, veuillez nous contacter sur ce lien
            https://www.norand.io/contact
          </p>
          <p className="subHeadingPrivacyPolicy">Article 8 : SERVICES DE TIERCES PARTIES</p>
          <p className="paraTxtPrivacyPol">
            Les informations que nous recevons sont uniquement communiquées à
            des tiers aux fins suivantes :
          </p>
          <p className="paraTxtPrivacyPol">
            a. Dans certaines circonstances, en vertu de la loi, Norand pourrait
            divulguer des informations à des tiers. Cela se produit notamment
            lorsque les autorités policières ou judiciaires obligent Norand à
            divulguer des informations dans le cadre d'une suspicion de crime ou
            d'abus. Ces cas incluent des sociétés enquêtant sur des fraudes à la
            carte bancaire.
          </p>
          <p className="paraTxtPrivacyPol">b. Google Analytics</p>
          <p className="paraTxtPrivacyPol">
            Google Analytics est un service d'analyse en ligne fourni par
            Google, Inc.utilisé dans le but de comprendre la manière dont le
            site internet est utilisé. Ce service utilise des cookies qui
            génèrent des informations relatives à votre utilisation du site
            internet, lesquelles sont ensuite transmises à Google et stockées
            sur ses serveurs aux USA.
          </p>
          <p className="paraTxtPrivacyPol">
            Ces informations sont utilisées par Google afin d'évaluer
            l'utilisation du site web, de compiler des rapports concernant
            l'activité du site internet et proposer d'autres services relatifs à
            l'activité du site et l'usage d'internet.
          </p>
          <p className="paraTxtPrivacyPol">
            Si vous ne souhaitez pas que votre activité sur ce site internet
            soit suivie, veuillez cliquer sur le lien suivant :
          </p>
          <p className="paraTxtPrivacyPol">https://support.google.com/ads/answer/2662922?hl=en</p>
          <p className="paraTxtPrivacyPol">c. Google - Remarketing</p>
          <p className="paraTxtPrivacyPol">
            Le Remarketing est un programme conçu par Google pour la "publicité
            ciblée" que Norand utilise dans le cadre de ses opérations
            publicitaires. Un cookie spécifique au navigateur est stocké à cette
            fin sur l'ordinateur de l'utilisateur. Cela permet à Norand, Google
            ou des services de tierces parties de proposer aux utilisateurs des
            publicités spécifiques lorsqu'ils visitent d'autres sites internet.
          </p>
          <p className="paraTxtPrivacyPol">d. Facebook</p>
          <p className="paraTxtPrivacyPol">
            Le site internet de Norand utilise des plug-ins provenant du réseau
            social Facebook (www.facebook.com), lesquels arborent le logo de la
            société Facebook et portent la mention "Facebook Social Plug-in".
            Lorsque vous accédez à ce site internet via le site de Norand, une
            connexion directe aux serveurs est configurée au travers du
            navigateur de Facebook. Facebook reçoit depuis le plug-in intégré
            les informations indiquant que vous avez visité le site de Norand.
            Facebook peut associer ces informations à votre compte Facebook, et
            ce tant que vous êtes connecté à votre compte utilisateur. Afin
            d'éviter la collecte de ces données par le biais de Facebook, vous
            devez vous déconnecter de Facebook avant de visiter notre site
            internet.
          </p>
          <p className="paraTxtPrivacyPol">e. Stripe</p>
          <p className="paraTxtPrivacyPol">
            Les transaction financières sont prises en charge par notre
            fournisseur de services de paiement : Stripe. Nous partagerons
            uniquement les informations nécessaires au traitement de vos
            paiements et remboursements avec ce fournisseur.
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPol;
