import React from "react";
import { Link } from "react-router-dom";
import { firestore, serverTimestamp } from "../../lib/firebase";
import { Alert } from "react-bootstrap";

import "./FooterNorand1.css";
import { AiOutlineMail } from "react-icons/ai";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import flagPic from "./../../images/uk_flag.svg";

import { Row, Col } from "react-bootstrap";
import arrow from "./../../images/ArrowRightWhite.svg";
import norandImg from "./../../images/FooterLogo.svg";

class FooterNorand1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showAlert: false };
  }

  options = [
    { value: "English", label: "English" },
    { value: "French", label: "French" }
  ];

  subscribeToNewsLetter = async event => {
    event.preventDefault();
    firestore
      .collection("newsletter")
      .add({
        email: event.target.email.value,
        timestamp: serverTimestamp(),
        subbedToNewsletter: true
      })
      .then(ref => {
        this.setState({ showAlert: true });
        setTimeout(() => {
          this.setState({ showAlert: false });
        }, 4000);
      });
  };

  render() {
    return (
      <>
        <div className="mainTopFooterForback">
          <Alert
            className="my-alert-success"
            onClose={() => this.setState({ showAlert: false })}
            dismissible
            show={this.state.showAlert}
          >
            <p>
              Thank you for registering ! You'll receive a confirmation email.
            </p>
          </Alert>
          <Row className="footerMainContainer">
            <Col lg={5} md={5} sm={12} className="footerNorandcol1">
              <div className="footerNorandCol11">
                <p className="topFooterNorand">Products</p>
                <Link to={"/monomk2"} className="bottomContFooterNorand">
                  MonoMk2
                </Link>
                <Link to={"/mono"} className="bottomContFooterNorand">
                  Mono
                </Link>
                <Link to={"/morphos"} className="bottomContFooterNorand">
                  Morphos
                </Link>
              </div>
              <div className="footerNorandCol11">
                <p className="topFooterNorand">Norand</p>
                <Link to={"/retailers"} className="bottomContFooterNorand">
                  Retailers
                </Link>
                <Link to={"/about"} className="bottomContFooterNorand">
                  About
                </Link>
                <Link to={"/contact-us"} className="bottomContFooterNorand">
                  Contact
                </Link>
              </div>
              <div className="footerNorandCol11" hidden={true}>
                <p className="topFooterNorand">Learn</p>
                <span className="bottomContFooterNorand">FAQ</span>
                <span className="bottomContFooterNorand">Downloads</span>
                <span className="bottomContFooterNorand">Manuals</span>
                <span className="bottomContFooterNorand">Tutorials</span>
              </div>
              <div className="footerNorandCol11" hidden={true}>
                <p className="topFooterNorand">Community</p>
                <span className="bottomContFooterNorand">Forum</span>
                <span className="bottomContFooterNorand">Contest</span>
                <span className="bottomContFooterNorand">File sharing</span>
                <span className="bottomContFooterNorand">Feels</span>
              </div>
            </Col>

            <Col lg={5} md={5} sm={12} className="footerNorandcol2">
              <p className="stayLoopTxt">Stay in the loop</p>
              <form onSubmit={this.subscribeToNewsLetter}>
                <AiOutlineMail className="emailTxt" />
                <input
                  name="email"
                  type="email"
                  className="inputEmailFooter"
                  placeholder="Email address"
                />
                <button className="btnSubsFooter">
                  Subscribe <img src={arrow} alt="" className="ArrowFooter" />
                </button>
              </form>
              <p className="txtDataProtFooter">
                By signing up, I agree with the data protection policy of Norand
                and understand that I can unsubscribe at any time.
              </p>
            </Col>
          </Row>

          <Row className="secondRowFooter">
            <Col lg={3} md={4} sm={12} className="footerLogo">
              <Link to={"/#"}>
                <img src={norandImg} alt="" className="footerNorandImg" />
              </Link>
            </Col>
            <Col lg={2} md={4} className="socialIconsContainerFooter">
              <a
                href="https://www.instagram.com/norand.synth/"
                className="socialIconFooterBack"
              >
                <BsInstagram className="socialIconFooter" />
              </a>
              <a
                href="https://www.facebook.com/Norand.synth/"
                className="socialIconFooterBack"
              >
                <FaFacebookF className="socialIconFooter" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCiZlZ0uFuXgT9kTBxqNh8zg"
                className="socialIconFooterBack"
              >
                <BsYoutube className="socialIconFooter" />
              </a>
            </Col>
          </Row>
          <div className="thirdRowFooter">
            <div className="thirdRowCol1">
              <img src={flagPic} alt="" className="flagImg" hidden />
              <select
                hidden
                name="language"
                id="language"
                className="selectFooter"
              >
                <option value="volvo">English</option>
                <option value="saab">Arabic</option>
                <option value="opel">Spanish</option>
                <option value="audi">Russian</option>
              </select>
            </div>
            <div className="thirdRRight">
              <Link to={"/terms-and-conditions"} className="thirdRowContent">
                Terms of Use
              </Link>
              <Link to={"/privacy-policy"} className="thirdRowContent">
                Privacy policy
              </Link>
              <Link to={"/legal-information"} className="thirdRowContent">
                @2022 Norand SAS
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FooterNorand1;
