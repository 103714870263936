import React from "react";
import "./FrontBackAng.css";
import Mono_front from "./../../images/mono_front.png";
import Mono_back from "./../../images/mono_back.png";
import Mono_angled from "./../../images/mono_angle.png";

const pageData = {
  pages: [
    {
      number: "01.",
      title: "Front",
      image: Mono_front,
    },
    {
      number: "02.",
      title: "Back",
      image: Mono_back,
    },
    {
      number: "03.",
      title: "Angled",
      image: Mono_angled,
    },
  ],
};

class FrontBackAng extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentPage: 0 };
  }

  render() {
    return (
      <>
        <div style={{ marginTop: "80px" }} className="mainTabBarPgRand">
          <div className="txtMainTabRand">
            <div
              className="tabDivLineTxt"
              onClick={() => this.setState({ currentPage: 0 })}
            >
              <p
                className={
                  this.state.currentPage === 0 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[0].number} {pageData.pages[0].title}
              </p>
              <div
                className={
                  this.state.currentPage === 0
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
            <div
              className="tabDivLineTxt"
              onClick={() => this.setState({ currentPage: 1 })}
            >
              <p
                className={
                  this.state.currentPage === 1 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[1].number} {pageData.pages[1].title}
              </p>
              <div
                className={
                  this.state.currentPage === 1
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
            <div
              className="tabDivLineTxt"
              onClick={() => this.setState({ currentPage: 2 })}
            >
              <p
                className={
                  this.state.currentPage === 2 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[2].number} {pageData.pages[2].title}
              </p>
              <div
                className={
                  this.state.currentPage === 2
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
          </div>
        </div>
        <div className="numberFrontBack">
          <p className="numberFrntTxt">
            {pageData.pages[this.state.currentPage].number}
          </p>
        </div>
        <div className="imgMainFrontBack">
          <img
            src={pageData.pages[this.state.currentPage].image}
            alt=""
            className="monoBack"
          />
        </div>
      </>
    );
  }
}

export default FrontBackAng;
