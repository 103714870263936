import React from "react";
import FooterNorand1 from "../../Components/FooterNorand/FooterNorand1";
import Header from "../../Components/Header/Header";
import { AiOutlineMail } from "react-icons/ai";
import { firestore, serverTimestamp } from "../../lib/firebase";
import { Alert } from "react-bootstrap";
import btnArrow from "./../../images/ArrowRightWhite.svg";
import headerPic1 from "./../../images/headerPicRight1black.svg";

class JoinWaitlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showAlert: false };
  }

  submitMessage = async event => {
    event.preventDefault();
    firestore
      .collection("productWaitlist")
      .add({
        email: event.target.email.value,
        name: event.target.name.value,
        timestamp: serverTimestamp(),
        subbedToNewsletter: true
      })
      .then(ref => {
        this.setState({ showAlert: true });
        setTimeout(() => {
          this.setState({ showAlert: false });
        }, 4000);
      });
  };

  render() {
    return (
      <>
        <div className="contactUsTopPage">
          <Header />
          <Alert
            className="my-alert-success"
            onClose={() => this.setState({ showAlert: false })}
            dismissible
            show={this.state.showAlert}
          >
            <p>
              Thank you, once the beta is ready, you will receive an email with
              your download link !
            </p>
          </Alert>
          <form
            onSubmit={this.submitMessage}
            style={{ height: "60vh" }}
            className="mainContainerContacts"
          >
            <h1 className="headingContactUs">Join product waitlist</h1>
            <div className="topInputFieldContact">
              <p className="inputTxt">Email address:</p>
              <div>
                <AiOutlineMail className="iconcontact" />
                <input
                  className="inputFieldContact"
                  type="email"
                  name="email"
                  placeholder="Enter email"
                />
              </div>
            </div>
            <div className="topInputFieldContact">
              <p className="inputTxt">Name:</p>
              <div>
                <img
                  src={headerPic1}
                  style={{ paddingBottom: "8px" }}
                  alt=""
                  className="iconcontact"
                />
                <input
                  className="inputFieldContact"
                  type="text"
                  name="name"
                  placeholder="Enter name"
                />
              </div>
            </div>

            <div className="buttonTopContact">
              <button className="btnContactSubmit">
                Submit
                <img src={btnArrow} alt="" className="arrowLeftBtnContact" />
              </button>
            </div>
          </form>
          <FooterNorand1 />
        </div>
      </>
    );
  }
}

export default JoinWaitlist;
