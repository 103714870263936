import React from "react";
import { AiFillFilePdf, AiOutlineCloudDownload } from "react-icons/ai";
import { FaDice, FaSlidersH } from "react-icons/fa";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./../ManualPage/ManualPage.css";

import manualPanel from "./../../images/mono_manual_panel.png";
import manualPanelMK2 from "./../../images/mono_mk2_panel.png";
import manualPanelBack from "./../../images/mono_manual_panel_back.png";
import soundArchitecture from "./../../images/mono_sound_architecture.png";
import dataArchitecture from "./../../images/mono_data_architecture.png";
import eastCoast from "./../../images/mono_manual_east_coast.svg";
import westCoast from "./../../images/mono_manual_west_coast.svg";
import oscSync from "./../../images/mono_manual_osc_sync.svg";
import schemaOsc from "./../../images/mono_manual_schematic_osc.svg";
import schemaFilter from "./../../images/mono_manual_schematic_filter.svg";
import schemaAmp from "./../../images/mono_manual_schematic_amp.svg";
import schemaModulation from "./../../images/mono_manual_schematic_modulation.svg";
import schemaPatch1 from "./../../images/mono_manual_schematic_patch_1.svg";
import schemaPatch2 from "./../../images/mono_manual_schematic_patch_2.svg";
import schemaPatch3 from "./../../images/mono_manual_schematic_patch_3.svg";
import schemaPatch4 from "./../../images/mono_manual_schematic_patch_4.svg";

const monoManualV2Data = {
  chapterLinks: [
    {
      name: "#whats-new-on-MK2",
      title: "Mono MK2 New Features",
      class: "title-1"
    },
    {
      name: "#whats-new-on-MK2",
      title: "Quick Start",
      class: "title-1"
    },
    {
      name: "#quick-start-hardware",
      title: "Hardware",
      class: "title-2"
    },
    {
      name: "#quick-start-connectors",
      title: "Connectors",
      class: "title-2"
    },
    {
      name: "#quick-start-power",
      title: "Power",
      class: "title-2"
    },
    {
      name: "#quick-start-drive",
      title: "Drive",
      class: "title-2"
    },
    {
      name: "#quick-start-expressivity",
      title: "Expressivity",
      class: "title-2"
    },
    {
      name: "#quick-start-high-resolution-automation",
      title: "High-resolution automation",
      class: "title-2"
    },
    {
      name: "#quick-start-curve-mode",
      title: "Curve mode",
      class: "title-2"
    },
    {
      name: "#quick-start-morphing",
      title: "Morphing",
      class: "title-2"
    },
    {
      name: "#quick-start-other-changes",
      title: "Other Changes from MK1",
      class: "title-2"
    },
    {
      name: "#quick-start-vocabulary",
      title: "Mono vocabulary",
      class: "title-2"
    },
    {
      name: "#quick-start-sound-arch",
      title: "Sound architecture",
      class: "title-2"
    },
    {
      name: "#quick-start-data-arch",
      title: "Data architecture",
      class: "title-2"
    },
    {
      name: "#quick-start-setting-sheet",
      title: "Settings cheat sheet",
      class: "title-2"
    },
    // {
    //   name: "#intro",
    //   title: "Intro",
    //   class: "title-1"
    // },
    {
      name: "#synth-engine",
      title: "Synthesis Engine",
      class: "title-1"
    },
    {
      name: "#synth-engine-intro",
      title: "Intro",
      class: "title-2"
    },
    {
      name: "#synth-engine-oscillator-block",
      title: "Oscillator block",
      class: "title-2"
    },
    {
      name: "#synth-engine-filter-block",
      title: "Filter block",
      class: "title-2"
    },
    {
      name: "#synth-engine-amplifier",
      title: "Amplifier and main envelope",
      class: "title-2"
    },
    {
      name: "#synth-engine-drive",
      title: "Drive",
      class: "title-2"
    },
    {
      name: "#synth-engine-master-volume",
      title: "Master Volume",
      class: "title-2"
    },
    {
      name: "#modulation",
      title: "Modulation",
      class: "title-1"
    },
    {
      name: "#modulation-intro",
      title: "Intro",
      class: "title-2"
    },
    {
      name: "#modulation-contextual",
      title: "Contextual modulators",
      class: "title-2"
    },
    {
      name: "#modulation-envelope",
      title: "X-Envelope",
      class: "title-2"
    },
    {
      name: "#modulation-modulator",
      title: "X-Modulators",
      class: "title-2"
    },
    {
      name: "#modulation-example",
      title: "A few examples",
      class: "title-2"
    },
    {
      name: "#modulation-kick",
      title: "Kick drum",
      class: "title-3"
    },
    {
      name: "#modulation-phase",
      title: "Evolving phase",
      class: "title-3"
    },
    {
      name: "#modulation-pinged",
      title: "Pinged FM",
      class: "title-3"
    },
    {
      name: "#modulation-filter",
      title: "Filter FM",
      class: "title-3"
    },
    {
      name: "#sequencer",
      title: "Sequencer",
      class: "title-1"
    },
    // {
    //   name: "#sequencer-intro",
    //   title: "Intro",
    //   class: "title-2"
    // },
    {
      name: "#sequencer-projects-and-basic",
      title: "Projects and patterns",
      class: "title-2"
    },
    {
      name: "#sequencer-projects-and-basic-projects",
      title: "Projects",
      class: "title-3"
    },
    {
      name: "#sequencer-projects-and-basic-patterns",
      title: "Patterns",
      class: "title-3"
    },
    {
      name: "#sequencer-projects-and-basic-pattern-control",
      title: "Pattern control",
      class: "title-3"
    },
    {
      name: "#sequencer-projects-and-basic-pattern-length",
      title: "Pattern length",
      class: "title-3"
    },
    {
      name: "#sequencer-projects-and-basic-tempo",
      title: "Tempo",
      class: "title-3"
    },
    {
      name: "#sequencer-projects-and-basic-play-styles",
      title: "Play styles",
      class: "title-3"
    },
    {
      name: "#sequencer-projects-and-basic-play-modes",
      title: "Play modes",
      class: "title-3"
    },
    {
      name: "#sequencer-projects-and-basic-live-mode",
      title: "Live mode",
      class: "title-3"
    },
    {
      name: "#sequencer-projects-and-basic-loop-mode",
      title: "Loop mode",
      class: "title-3"
    },
    {
      name: "#sequencer-projects-and-basic-song-mode",
      title: "Song mode",
      class: "title-3"
    },
    {
      name: "#sequencer-projects-and-basic-patchless-pattern",
      title: "Patchless pattern",
      class: "title-3"
    },
    {
      name: "#sequencer-projects-and-basic-default-patch",
      title: "Default pattern",
      class: "title-3"
    },
    {
      name: "#sequencer-pattern-morphing",
      title: "Pattern morphing",
      class: "title-3"
    },
    {
      name: "#sequencer-duonote-mode",
      title: "Duophonic mode",
      class: "title-3"
    },
    {
      name: "#sequencer-editing",
      title: "Pattern editing",
      class: "title-2"
    },
    {
      name: "#sequencer-editing-mini-keyboard",
      title: "Mini keyboard",
      class: "title-3"
    },
    {
      name: "#sequencer-editing-adding-note",
      title: "Adding notes",
      class: "title-3"
    },
    {
      name: "#sequencer-editing-recording-notes",
      title: "Recording notes",
      class: "title-3"
    },
    {
      name: "#sequencer-editing-sh-style",
      title: "SH-Style sequencing",
      class: "title-3"
    },
    {
      name: "#sequencer-editing-change-pitch",
      title: "Edit note pitch",
      class: "title-3"
    },
    {
      name: "#sequencer-editing-note-length",
      title: "Edit note length",
      class: "title-3"
    },
    {
      name: "#sequencer-step",
      title: "Step parameters",
      class: "title-3"
    },
    {
      name: "#sequencer-step-accent",
      title: "Accent",
      class: "title-3"
    },
    {
      name: "#sequencer-step-accent-param",
      title: "Accent parameters",
      class: "title-3"
    },
    {
      name: "#sequencer-step-slide",
      title: "Slide",
      class: "title-3"
    },
    {
      name: "#sequencer-step-slide-rate",
      title: "Slide Rate",
      class: "title-3"
    },
    {
      name: "#sequencer-step-param",
      title: "Microshift probability and ratchet",
      class: "title-3"
    },
    {
      name: "#sequencer-step-mutes",
      title: "Step mutes",
      class: "title-3"
    },
    {
      name: "#sequencer-param-offset",
      title: "Parameter offsets or automation",
      class: "title-2"
    },
    {
      name: "#sequencer-param-offset-visual",
      title: "Visualisation",
      class: "title-3"
    },
    {
      name: "#sequencer-param-offset-add",
      title: "Adding parameter offsets",
      class: "title-3"
    },
    {
      name: "#Curve-mode",
      title: "Curve Mode",
      class: "title-3"
    },
    {
      name: "#sequencer-param-offset-record",
      title: "Recording parameter offsets",
      class: "title-3"
    },
    {
      name: "#sequencer-expressive-param-offset",
      title: "Custom expressive parameter offsets",
      class: "title-3"
    },
    {
      name: "#sequencer-unlink-env",
      title: "Unlink X-Env Sequence",
      class: "title-2"
    },
    {
      name: "#sequencer-copy",
      title: "Copy paste and clear",
      class: "title-2"
    },
    {
      name: "#sequencer-undo",
      title: "Undo/redo",
      class: "title-2"
    },
    {
      name: "#sequencer-settings",
      title: "Pattern settings",
      class: "title-2"
    },
    {
      name: "#sequencer-settings-scale",
      title: "Scale and root note",
      class: "title-3"
    },
    {
      name: "#sequencer-settings-custom-scale",
      title: "Custom scales",
      class: "title-3"
    },
    {
      name: "#Scale-autoquantize",
      title: "Scale autoquantize",
      class: "title-3"
    },
    {
      name: "#sequencer-settings-transpose",
      title: "Transpose",
      class: "title-3"
    },
    {
      name: "#sequencer-settings-beat-div",
      title: "Beat division",
      class: "title-3"
    },
    {
      name: "#sequencer-settings-swing",
      title: "Swing",
      class: "title-3"
    },
    {
      name: "#sequencer-mod-note",
      title: "Mod note mode",
      class: "title-2"
    },
    {
      name: "#sequencer-mod-note-pitch",
      title: "Mod Note pitch",
      class: "title-3"
    },
    {
      name: "#sequencer-mod-note-autom",
      title: "Automation on Mod Note",
      class: "title-3"
    },
    {
      name: "#sequencer-mod-note-mutes",
      title: "Mod note mutes",
      class: "title-3"
    },
    {
      name: "#sequencer-mod-note-copy",
      title: "Copy/Paste operations",
      class: "title-3"
    },
    {
      name: "#randomizer",
      title: "Randomizer",
      class: "title-1"
    },
    {
      name: "#randomizer-page",
      title: "Page randomizer",
      class: "title-2"
    },
    {
      name: "#randomizer-pitch",
      title: "Pitch randomizer",
      class: "title-2"
    },
    {
      name: "#randomizer-dice",
      title: "Step dice",
      class: "title-2"
    },
    {
      name: "#randomizer-step",
      title: "Step randomizer",
      class: "title-2"
    },
    {
      name: "#randomizer-parameter",
      title: "Parameter randomizer",
      class: "title-2"
    },
    {
      name: "#randomizer-range",
      title: "Randomize a step range",
      class: "title-2"
    },
    {
      name: "#range-autom-randomizer",
      title: "Randomize automation range",
      class: "title-2"
    },
    {
      name: "#randomizer-accent",
      title: "Accent and slide randomizer",
      class: "title-2"
    },
    {
      name: "#settings",
      title: "Project settings",
      class: "title-1"
    },
    {
      name: "#settings-midi",
      title: "MIDI",
      class: "title-2"
    },
    {
      name: "#settings-pot",
      title: "Pot mode",
      class: "title-2"
    },
    {
      name: "#settings-bend",
      title: "Bend rg",
      class: "title-2"
    },
    {
      name: "#settings-cv2",
      title: "CV2 src",
      class: "title-2"
    },
    {
      name: "#settings-trigger",
      title: "Trigger in",
      class: "title-2"
    },
    {
      name: "#update",
      title: "Update and calibration",
      class: "title-1"
    },
    {
      name: "#update-firmaware",
      title: "Updating firmware",
      class: "title-2"
    },
    {
      name: "#update-oscillators",
      title: "Oscillators calibration",
      class: "title-2"
    },
    {
      name: "#midi",
      title: "Midi implementation",
      class: "title-1"
    },
    {
      name: "#midi-velocity",
      title: "Velocity",
      class: "title-2"
    },
    {
      name: "#midi-cc",
      title: "Control change",
      class: "title-2"
    },
    {
      name: "#midi-cc-table",
      title: "CC parameter table",
      class: "title-3"
    },
    {
      name: "#midi-pc",
      title: "Program change",
      class: "title-2"
    },
    // {
    //   name: "#midi-dump",
    //   title: "Program dump/receive",
    //   class: "title-2"
    // }
  ],
  content: (
    <>
      <div className="download-link-container">
        <a
          className="download-link"
          href="https://storage.googleapis.com/norand_public_files/norand_mono_mk2_quickref_s.pdf"
        >
          <AiFillFilePdf size={"40px"} style={{ "padding-right": "10px" }} />
          Quick Reference
        </a>
        <a
          className="download-link"
          href="https://storage.googleapis.com/norand_public_files/Mono%20MK2%20new%20feature.pdf"
        >
          <AiFillFilePdf size={"40px"} style={{ "padding-right": "10px" }} />
          MK2 New Features
        </a>
        <a
          className="download-link"
          href="https://storage.googleapis.com/norand_public_files/Norand_MK2_manual.pdf"
        >
          <AiFillFilePdf size={"40px"} style={{ "padding-right": "10px" }} />
          PDF
        </a>
        <a
          className="download-link"
          href="https://storage.googleapis.com/norand_public_files/mono_MK2_V1.1.3r.zip"
        >
          <AiOutlineCloudDownload
            size={"40px"}
            style={{ "padding-right": "10px" }}
          />
          Firmware V1.1.3r
        </a>
      </div>
      
      <h2 id="whats-new-on-MK2" manual-section-trigger="true">
        Mono MK2 new features
      </h2>
      <h3 id="quick-start-hardware" manual-section-trigger="true">
        Hardware
      </h3>
      <img
        className="manual-img"
        id="panel-img"
        src={manualPanelMK2}
        alt="mono panel"
      />
      <h3 id="quick-start-connectors" manual-section-trigger="true">
        Connectors
      </h3>
      <h4>
        Front
      </h4>
      <p>
        <span className="param-enum">A</span> SEQ switches : The SEQ switches are a continuyous strip of 3D sensors,
        capable of detecting the “position” of the finger taps via the pressure distribution applied on it.
        <br />
        <span className="param-enum">B</span> PAGE switches : Similar to the seq switches
        <br />
        <span className="param-enum">C</span> Mini-keyboard keys : 3D sensors chromatic keys for playing, editing, recording notes
        <br />
      </p>
      <h4>
        Back
      </h4>
      <p>
        <span className="param-enum">1</span> Audio output : Stereo output capable of driving line level, and headphones
        <br />
        <span className="param-enum">2</span> Clock input : For synchronizing with external analog gear, such as eurorack
        <br />
        <span className="param-enum">3</span> Reset input : Reset input for external analog gear
        <br />
        <span className="param-enum">4</span> Midi in : MIDI compliant (Type A) 3.5mm jack MIDI input
        <br />
        <span className="param-enum">5</span> Midi thru : MIDI compliant (Type A) 3.5mm jack MIDI thru
        <br />
        <span className="param-enum">6</span> Midi out : MIDI compliant (Type A) 3.5mm jack MIDI out
        <br />
        <span className="param-enum">7</span> MicroSD card slot : Used for storage, note that Mono needs an SD card to boot.
        <br />
        <span className="param-enum">8</span> USB-C Host port : Connect to a MIDI device, such as asequencer, external keyboard or
        other synth
        <br />
        <span className="param-enum">9</span> USB-C Device port : Connect to a MIDI host, such as a computer
        <br />
        <span className="param-enum">10</span> USB-C Power port : Dedicated power port. Use the provided power supply.
        <br />
        <span className="param-enum">11</span> Extension port : For future semi-modular extension. Note that although it uses a USB-C
        port, it IS NOT USB-C. Although it is protected and there is no risk doing it, do not connect to
        anything other than the designed extension.
        <br />
        <span className="param-enum">12</span> On-Off switch
        <br />
      </p>
      <h3 id="quick-start-power" manual-section-trigger="true">
        Power
      </h3>
      <p>
        Mono MK2 can be powered either via its dedicated power port, or via the USB device port. Note
        that it require at least 1A capable ports with broadcast capabilities. MK2 senses the power
        capabilities of the power source, there are 3 level of power.
        <ul>
          <li>
            <strong>Normal mode :</strong> With the included power supply, or a high power capable USB-C host,
            Mono boots in high power mode, the LEDs are full brightness.
          </li><li>
            <strong>Low power mode :</strong> With a 1A capable host, such as a USB 2.0 host, mono will be in
            low power mode, with the LED in low brightness.
          </li><li>
            <strong>Standby mode :</strong> Mono cannot work correctly with a 0.5A supply, if it sense such a
            power supply, it will not boot, or go to standby mode.
          </li>
        </ul>
      </p>
      <h3 id="quick-start-drive" manual-section-trigger="true">
        Drive
      </h3>
      <p>MK2 adds a soft overdrive circuit at the output, it is the only change in the signal path. The
        output DAC is software compensated so that driving the signal doesn’t make it louder, it will just
        add more and more overtones as you push it harder. Ther drive circuit as been designed to play
        extremely well with the resonant filter !</p>
      <h3 id="quick-start-expressivity" manual-section-trigger="true">
        Expressivity
      </h3>
      <p>MK2 now has dedicated Vel, X, Y and Z switches for expressivity controls. X is the horizontal
        axis, otherwise known as pitchbend, Y is the vertical axis, it is triggered by moving up or down
        on the minikey, Z is the pressure, or aftertouch, and Vel is the velocity.. Here is an exhaustive
        list of the feature.</p>
      <h4>
        Enable/Disable
      </h4>
      <p>To enable/disable an expressivity channel, simply press one of the 4 switches. A lit switch
        indicate that the expressivity channel is enabled. When enabled, you can play the minikeyboard
        expressively.
        <p></p><strong>When releasing your finger from the [NOTE], X and Z expressivity will go back to its default value.
          If you want to keep the last value, simply turn off the expressivity switch before releasing the note. Only Y
          expressivity will keep its value when releasing the note.
        </strong>
      </p>
      <h4>
        Using the expressive keyboard without triggering note
      </h4>
      <p>Sometimes, you want to use the expressivity control on the keyboard without triggering keys,
        to add liveliness to a pattern. To do so, hold FUNC and press any of the X,Y, and Z switch.
        The expressivity switch(s) will blink. You can now use the expressive keyboard without triggering note on a
        pattern being played.</p>
      <h4>
        Programming the expressive modulation
      </h4>
      <p>The way expressive channels modulate Mono’s sound is completely customizable.
        It can modulate ay of the parameter of the synthesizer, even the modulation parameter such as XMod rate.
        To program it, hold the desired expressive channel (X,Y,Z or VEL), and turn a knob’s parameter either
        clockwise to apply positive modulation, or counterclockwise to apply negative modulation. When the knob is at noon,
        no modulation is applied to that parameter. Release the switch when you’re done with the assignment.</p>

        <h4>
         Tuned parameters X behaviour
      </h4>
       <p>
         By default the 2 oscillators and the filter have an offset of 1 semitone by MIDI note.
         The filter tracking can still be enabled/disabled with the follow switch. 
         On the two oscillators and the filter the X offset is not linear but quantized, 
         this allow for microtonal/macrotonal playing. At center position the oscs/filter don’t track, 
         there are then 6 positions on the positive and negative side : ⅛ semitone - ¼ semitone - ½ semitone 
         - 1 semitone - 1 tone - 2 tone.
       </p>

      <h3 id="quick-start-high-resolution-automation" manual-section-trigger="true">
        High-resolution automation
      </h3>
      <p>One of the most important, if not the most important feature of MK2.
        All the automation are now recorded at a very high resolution (1024PPQN).
        Allowing for very smooth parameter and expressivity curves.
        Recording an automation works just like MK1. Enable/disable record with the [RECORD] key,
        and turn any knobs to record an automation.</p>
      <h4>
        Expressivity
      </h4>
      <p>Of course, you can also record expressivity when they are enabled. Simply play the minikeyboard expressively !</p>
      <h4>
        Step-quantized automation
      </h4>
      <p>Sometimes, a good old step-quantized automation is actually more suited. When you hold the [UP] key in record mode,
        and record any kind of automation, those willb e step-quantized just like in MK1, release [UP] and it will immediately
        go back to high-resolution, making it possible to blend quantized and unquantized steps in the same automation.</p>
      <h4>
        Overdub
      </h4>
      <p>The standard behaviour is that the automation recoding will shortly stop after you stop moving a knob.
        If you want to overdub the automation lane you are currently recording, hold [RECORD]. It will overwrite the
        previous automation as the pattern moveforward, as long as [RECORD] is held.</p>
      <h3 id="quick-start-curve-mode" manual-section-trigger="true">
        Curve mode
      </h3>
      <p>What’s good of an automation if you cant edit it ? Curve mode allow you to precisely edit a recorded automation.
        Go to curve mode by holding [FUNC] and pressing [UP]. The [SEQ] LED will show the recorded automation with a color map.</p>
      <h4>
        Edit a curve
      </h4>
      <p>In curve mode, the recorded automation has anchor points placed at each maximum and minimum points of the curve.
        Similar to how this works in a DAW. When you move an anchor point, it will move the full curve between the 2 anchor
        points around the one being moved.</p>
      <h4>
        Moving an anchor point
      </h4>
      <p>To show every anchor points of the current curve, simply press and hold the [UP] key, the anchor points will blink.
        To move an anchor point in any direction, press the blinking step you want to move, and drag it up or down, left or right.
        The curve will update after you release the [SEQ] strip.</p>
      <h3 id="quick-start-morphing" manual-section-trigger="true">
        Morphing
      </h3>
      <p>The continuous strips allow for continuouis morphing between all the patterns of a page.
        To be able to morph between the patterns, enable morphing with the MORPH switch, go to pattern mode by pressing pattern,
        and use your finger to morph between the patterns on the [SEQ] strip. The “old” morphing feature via [FUNC] + [CUTOFF]
        is now replaced with [FUNC] + [MORPH].</p>
      <h3>
        Tape Playhead
      </h3>
      <p>In player mode, if you enable morphing with the MORPH key, you can use your finger as a playhead.
        This works in forward or backward direction. When you release the SEQ strip, the playhead will resume
        playing where it is supposed to, not ruining your time signature.</p>
      <h3 id="quick-start-other-changes" manual-section-trigger="true">
        Other changes from MK1
      </h3>
      <h4>
        Pattern Rotate
      </h4>
      <p>The shortcut for pattern rotate has been moved, now you need to hold [FUNC], and press [PAGE A] to rotate left,
        and [PAGE D] to rotate right.</p>
      <h4>
        Storage
      </h4>
      <p>Note that on the first time you open a project that has never been used, mono will create it’s directory structure,
        which takes a small amount of time (about 20 seconds).
        The projects are now stored on an SD card in a serialized format. At any time, you can copy these folders to your computer to save it,
        and change the projects name to reorder it.</p>
      <h4>
        Sequencer strips common use
      </h4>When in player mode, You can use the [SEQ] strip to :
      <p></p>
      <ul>
        <li>  Set note length     (from left to right)</li>
        <li>  Enable/disable note (from right to left)</li>
      </ul>

      <p>
        There are tons of cosmetic UI changes such as the playhead, parameter offsets visualization etc.
        Setting offset parameters is not relative to the currrent potentiometer position anymore,
        but to the center position. Although it was handy to start record automation
        or place step locks without jumping to a position, it was pretty much impossible
        to know exactly what was the starting position, so you needed to only trust your
        ears anyway. Starting from the middle allows to use the maximum range of offset at any time.

      <p>
        <span style={{ fontWeight: 'bold' }}>You can visualise when the [SEQ] or [PAGE] is being pressed with a blue colored feedback.</span>
      </p>
      </p>

      <h3 id="quick-start-vocabulary" manual-section-trigger="true">
        Mono vocabulary
      </h3>
      <p>
        <span className="strong-1">Project :</span> Top structure of mono data
        architecture, This holds 64 patterns, global settings 40 Mod Notes and 4
        custom scales.
      </p>
      <p>
        <span className="strong-1">Pattern :</span> A sequence of steps to be
        played in a loop
      </p>
      <p>
        <span className="strong-1">Step :</span> An element holding information
        to be sent to the synthesis engine to “play” sound.
      </p>
      <p>
        <span className="strong-1">Synth parameter :</span> A parameter directly
        controlling the internal CVs of the underlying analog synthesis engine,
        such as Wave, Freq, Cutoff … Even though the envelope is digital,
        envelope parameters are considered synth parameters as well.
      </p>
      <p>
        <span className="strong-1">Modulation parameter :</span> A parameter
        that modulates a given synth parameter. Each synth parameters have
        dedicated modulation parameters.
      </p>
      <p>
        <span className="strong-1">Patch :</span> All synth and modulation
        parameters that form a particular sound. Each pattern holds its own
        patch.
      </p>
      <p>
        <span className="strong-1">Note trig :</span> Information held in a
        step, triggering the synth engine with a given note.
      </p>
      <p>
        <span className="strong-1">Parameter offset :</span> Information held in
        the sequencer, offsetting the value of a given parameter with high definition.
      </p>
      <h3 id="quick-start-sound-arch" manual-section-trigger="true">
        Mono sound architecture
      </h3>
      <img
        className="manual-img"
        src={soundArchitecture}
        alt="Mono sound architecture"
      />
      <h3 id="quick-start-data-arch" manual-section-trigger="true">
        Mono data architecture
      </h3>
      <img
        className="manual-img"
        src={dataArchitecture}
        alt=" Mono data architecture"
      />
      <Row className="row-bubble">
        <Col lg={6} md={12} className="card-col">
          <Card>
            <Card.Title>
              <FaDice size={"40px"} style={{ "padding-right": "10px" }} />
              Randomizer
            </Card.Title>
            <Card.Text>
              Hold [FUNC] + [TEMPO] and :
              <ul>
                <li>
                  Press a [PAGE] key : add between 1 and 4 random notes to the
                  page, the added note are always in scale.
                </li>
                <li>
                  Press a [SEQ] key : Randomize every parameter of said step
                  except its pitch
                </li>
                <li>
                  Press [GLIDE] or [ACCENT] key to add random glides and accents
                </li>
                <li>Move a parameter's knob to create a random automation</li>
                <li>
                  Remember you can always undo if you don't like what you hear
                </li>
              </ul>
            </Card.Text>
          </Card>
        </Col>
        <Col lg={6} md={12} className="card-col">
          <Card>
            <Card.Title>
              <FaSlidersH size={"40px"} style={{ "padding-right": "10px" }} />
              Mod note mode
            </Card.Title>
            <Card.Text>
              In Mod Note mode, the keyboard no longer act as chromatic, each of
              the 8 bottom mini keyboard [NOTE] keys has its own set of
              parameters, its own timbre in a way.
            </Card.Text>
            <Card.Text>
              Each project has 5 pages of Mod notes, scroll with [UP] or [DOWN]
              key. When the mode is activated, both [UP] and [DOWN] key LED are
              on.
            </Card.Text>
            <Card.Text>
              To edit one of the moded note, hold a mini-keyboard key and set
              its parameter. You can then add steps of the selected moded note,
              or play live and record it.
            </Card.Text>
          </Card>
        </Col>
      </Row>
      <h3 id="quick-start-setting-sheet" manual-section-trigger="true">
        Settings cheat sheet
      </h3>

      <p>
        <span className="strong-1">Length :</span> Set pattern length with the [SEQ] and [PAGE] keys.
      </p>
      <p>
        <span className="strong-1">Play Mode :</span>  Set Play Style and Play Mode 
      </p>
      <p></p>
        On the [SEQ] keys :
        <p></p>
        <p>
        <Row className="row-settings">
          <div className="settings-col">
            <span className="param-enum">1</span> Forward (Default)
            <br />
            <span className="param-enum">2</span> Reverse
            <br />
            <span className="param-enum">3</span> Synced Pendulum
            <br />
            <span className="param-enum">4</span> Pendulum
            <br />
            <span className="param-enum">5</span> Drunk (2 Forward, 1 backward)
            <br />
            <span className="param-enum">6</span> Reverse drunk
            <br />
            <span className="param-enum">7</span> Random Drunk (75% chance
            forward, 25% backward)
            <br />
            <span className="param-enum">8</span> Reverse Random Drunk
            <br />
            <span className="param-enum">9</span> Random
            <br />
          </div>
        </Row>
      </p>
      <p>
        On the [PAGE] keys :
        <p></p>
        <Row className="row-settings">
          <div className="settings-col">
            <span className="param-enum">A</span> Sequential (default)
            <br />
            <span className="param-enum">B</span> Direct Jump
            <br />
            <span className="param-enum">C</span> Direct Start
            <br />
          </div>
        </Row>
      </p>
      <p>
        <span className="strong-1">Swing :</span> Set swing value. Default is 9
        (no swing). Greater value add swing and lower value add shuffle.
        The [PAGE] key is for enable/disable duophonic mode.
        <p></p>
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          A
        </span>{" "}
        Disabled
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          B
        </span>{" "}
        Enabled
      </p>
      <p>
        <span className="strong-1">Scale :</span> Set root note with [NOTE]
        keys, and scale with the [SEQ] keys.
      </p>
      <p>
        <Row className="row-settings">
          <Col lg={6} md={12} className="settings-col">
            <span className="param-enum">1</span> Chromatic (default)
            <br />
            <span className="param-enum">2</span> Major
            <br />
            <span className="param-enum">3</span> Melodic minor
            <br />
            <span className="param-enum">4</span> Harmonic minor
            <br />
            <span className="param-enum">5</span> Arabic
            <br />
            <span className="param-enum">6</span> Dorian
            <br />
            <span className="param-enum">7</span> Aeolian
            <br />
            <span className="param-enum">8</span> Phrygian
            <br />
          </Col>
          <Col lg={6} md={12} className="settings-col">
            <span className="param-enum">9</span> Gypsy
            <br />
            <span className="param-enum">10</span> Mixolidian
            <br />
            <span className="param-enum">11</span> Romanian minor
            <br />
            <span className="param-enum">12</span> Gypsy minor
            <br />
            <span className="param-enum">13</span> japanese
            <br />
            <span className="param-enum">14</span> Spanish
            <br />
            <span className="param-enum">15</span> blues
            <br />
          </Col>
        </Row>
      </p>
      <p>
        <span className="strong-1">Transpose :</span> Set transpose with [NOTE]
        keys for semi-tone transposition and [SEQ] key for octave transposition,
        [SEQ] key 8 is the default value.
      </p>
      <p>
        <span className="strong-1">Beat div :</span> Set the number of step per
        beat :
      </p>
      <p>
        <Row className="row-settings">
          <Col lg={6} md={12} className="settings-col">
            <span className="param-enum">1</span> 1:16
            <br />
            <span className="param-enum">2</span> 1:12
            <br />
            <span className="param-enum">3</span> 1:8
            <br />
            <span className="param-enum">4</span> 1:6
            <br />
            <span className="param-enum">5</span> 1:4 (Default)
            <br />
            <span className="param-enum">6</span> 1:3
            <br />
          </Col>
          <Col lg={6} md={12} className="settings-col">
            <span className="param-enum">7</span> 1:2
            <br />
            <span className="param-enum">8</span> 2:3
            <br />
            <span className="param-enum">9</span> 1:1
            <br />
            <span className="param-enum">10</span> 3:2
            <br />
            <span className="param-enum">11</span> 2:1
            <br />
          </Col>
        </Row>
        <p></p>The [PAGE] is for enable/disable step automation quantization, a quantized automation
        means there is only one value of that automation per step : <p></p>
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          A
        </span>{" "}
        Disable automation step quantization
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          B
        </span>{" "}
        Enable automation step quantization
      </p>
      
      <p>
        <span className="strong-1">MIDI :</span> General MIDI settings
        </p><p>
        On the [SEQ] keys :
        <span className="param-enum" style={{ marginLeft: "14px" }}>
        1->16
        </span>
        Set MIDI channel from 1 to 16
        </p><p>
        On the [PAGE] keys :
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          A
        </span>{" "}
        Disable ModNoteMidiChannel
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          B
        </span>{" "}
        Enable ModNoteMidiChannel
        </p><p>
          On the [NOTE] keys :
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          C
        </span>{" "}
        Enable MidiRecording
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          C#
        </span>{" "}
        Disable MidiRecording
      </p>
      <p>
        <span className="strong-1">Clock :</span> Set clock source with [SEQ] key.
      </p>
      {/* internal / midi / usb / cv */}
      <p>
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          1
        </span>{" "}
        Internal
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          2
        </span>{" "}
        USB
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          3
        </span>{" "}
        MIDI
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          4
        </span>{" "}
        CV
      </p>
      <p></p>
      <p></p>The [PAGE] is for enable/disable ModNote automation : <p></p>
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          A
        </span>{" "}
        Disable ModNote automation
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          B
        </span>{" "}
        Enable ModNote automation
      <p></p>
      <p>
        <span className="strong-1">Bend Rg :</span> Sets pitch bend range from 1
        to 16 semitones with [SEQ] key. The [PAGE] keys enable/disable the time quantization.
        <p></p>
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          A
        </span>{" "}
        Disable time quantization
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          B
        </span>{" "}
        Enable time quantization
      </p>
      <p>
        <span className="strong-1">Pot Mode :</span> Sets potentiometer mode and FSR sensibility.
      </p>
      <p>
      </p><p>
        On the [SEQ] keys :
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          1
        </span>{" "}
        Jump
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          2
        </span>{" "}
        Passthru
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          3
        </span>{" "}
        Relative
        </p><p>
        On the [PAGE] keys :
        <span className="param-enum" style={{ marginLeft: "14px" }}>
        1->4 </span> Set FSR sensibility from 1 to 4 (1 being the most sensitive)
      </p>
      <p>
        <span className="strong-1">Param Snd :</span> Enable/Disable CC
        parameter send.
      </p>
      <p>
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          1
        </span>{" "}
        Disabled
        <span className="param-enum" style={{ marginLeft: "20px" }}>
          2
        </span>{" "}
        Enabled
      </p>
      <p>
        <span className="strong-1">Pgm Rcv :</span> Wait to receive a project
        file as sysex.
      </p>
      <p>
        <span className="strong-1">Pgm Dump :</span> Dump the current project as
        a sysex file
      </p>

      <p>
        <span className="strong-1">Version:</span> Hold [FUNC] + [FOLLOW] to show the current firmware
        version. The [PAGE] keys show the major version, the [SEQ] keys show the minor version, and the [NOTE]
        keys show the revision.
      </p>
      {/* <h2 id="intro" manual-section-trigger="true">
        Intro
      </h2>

      <p>
        TO REDO
      </p> */}
      <h2 id="synth-engine" manual-section-trigger="true">
        Synthesis Engine
      </h2>
      <h3 id="synth-engine-intro" manual-section-trigger="true">
        Intro
      </h3>
      <p>
        By the looks of it, Mono has everything of an East Coast synthesizer.
        There is a clear signal flow from left to right, starting from the
        oscillators, through the filter, and finally to the amplifier. But the
        contextual modulations (X-Modulator and X-Envelope) let any user alter
        each synth parameter so deeply that one can effectively use west coast
        synthesis techniques to craft the timbre. Indeed, one can easily make a
        good sounding patch using a single oscillator with Mono ! Let's dive
        into each part.
      </p>
      <Row className="row-bubble">
        <Col lg={6} md={12} className="col-lg-6 card-col">
          <Card>
            <Card.Title>East coast synthesis</Card.Title>
            <Card.Img src={eastCoast} alt="east coast" />
            <Card.Text>
              East coast synthesis usually describe subtractive synthesis
              techniques, where the sound designers start with an oscillator
              rich in harmonics, and then shape the sound by removing
              (subtracting) harmonics with filters.
            </Card.Text>
          </Card>
        </Col>
        <Col lg={6} md={12} className="col-lg-6 card-col">
          <Card>
            <Card.Title>West coast synthesis</Card.Title>
            <Card.Img src={westCoast} alt="west coast" />
            <Card.Text>
              West Coast synthesis describes a different approach. It tipically
              feature oscillators with simple shapes, that are then heavily
              modulated using FM and waveshaping techniques to create complex
              timbre.
            </Card.Text>
          </Card>
        </Col>
      </Row>
      <h3 id="synth-engine-oscillator-block" manual-section-trigger="true">
        Oscillator block
      </h3>
      <img
        style={{ maxHeight: "200px", display: "block", margin: "20px auto" }}
        className="manual-img"
        src={schemaOsc}
        alt="oscillator block"
      />
      <p>
        Mono offers 2 analog oscillators with continuously variable waveforms,
        thru-zero frequency modulation, and hard sync. For the electronic geeks
        among us, the oscillators are based around a unique discrete asymmetric
        integrator. In “layman” terms, it means that the triangle core waveform
        is not perfect, it is a bit “skewed”, which gives it and the sinus wave
        a slightly richer harmonic content.
      </p>
      <p>
        Each oscillator has 3 parameters. The big [FREQ] knob adjusts the pitch
        in semitones, from C0 to C8. The [DETUNE] knob has a range of two
        semitones, with a pitch perfect 440Hz tuning in the middle.
      </p>
      <p>
        The [WAVE] parameters smoothly fade from sinus, to triangle, to square
        and finally to sawtooth wave. You basically go from the dullest of
        sounds to the richest in terms of harmonics.
      </p>
      <p>
        The two mod parameters give you additional sound design tools : the
        [SYNC] key synchronizes the wave cycle of oscillator 2 with oscillator
        1; the [TZFM] parameter modulates the frequency of oscillator 2
        <span className="emphasis">· the carrier ·</span> with oscillator 1
        <span className="emphasis">· the modulator ·</span>. It is analog
        thru-zero frequency modulation, which has a very rich distinctive sound.
      </p>
      <p>
        Finally you can adjust the amount of both oscillators fed to the filter
        with [OSC1] and [OSC2] parameters. At high gain, the oscillators
        overdrive the filter, so you can also use this parameter creatively.
      </p>
      <p>
        Although limited compared to other big monosynths (only 2 oscillators ?!
        No noise ?! What is this ?), this oscillator block allows for a huge
        landscape of timbre. This can range from subtle bell-like sounds to very
        harsh saturated noise. All this without even touching contextual
        modulation. Adding the latter makes for a very complex oscillator
        system.
      </p>
      <p>
        Just a simple example : use the X-Modulator at audio rate on the Freq
        parameter of both oscillators - you get a 4-operator hybrid FM synth
        (for normal people, this translates to: “You can do some pretty cool
        stuff with this synth”).
      </p>
      <Row className="row-bubble">
        <Col xl={6} lg={12} className="card-col">
          <Card>
            <Card.Title>Oscillator Sync</Card.Title>
            <img
              className="manual-img"
              style={{ "margin-top": "20px", maxHeight: "200px" }}
              src={oscSync}
              alt="osc sync"
            />
            <Card.Text>
              I'm lazy so here is the wikipedia definition : As one oscillator
              <span className="emphasis">· the master ·</span> finishes a cycle,
              it resets the period of another oscillator
              <span className="emphasis">· the slave ·</span>, forcing the
              latter to have the same base frequency. This can produce a
              harmonically rich sound, the timbre of which can be altered by
              varying the synced oscillator's frequency. And here is a nice
              schematic with sawtooth wave example.
            </Card.Text>
          </Card>
        </Col>
        <Col xl={6} lg={12} className="card-col">
          <Card>
            <Card.Title>Thru-Zero Frequency modulation</Card.Title>
            <Card.Text>
              FM (Frequency Modulation) is a very deep subject with whole books
              written about it, I'll do my best to explain it shortly. Let's
              start with what FM is : the output of one oscillator
              <span className="emphasis">· the modulator ·</span> is used to
              control the frequency of another oscillator
              <span className="emphasis">· the carrier ·</span>. This will in
              turn enrich the harmonic content of the carrier, by effectively
              “spliting” each of it's base harmonic. There are two main type of
              FM : exponential and linear. Unlike exponential FM, linear FM can
              reach zero hertz, which basically stops the oscillators, which is
              not very pleasing in term of musicality. Thru-zero FM alleviate
              this problem by introducing “negative” frequency. When the
              oscilator reaches zero hertz, it will reverse it's cycle and start
              oscillating backward, and when it crosses zero hertz, it will
              reverse again back to “positive” frequencies. Note that I put
              negative and positive in quote, in the real world there is no such
              thing as negative frequency.
            </Card.Text>
          </Card>
        </Col>
      </Row>
      <h3
        className="anchor"
        id="synth-engine-filter-block"
        manual-section-trigger="true"
      >
        Filter block
      </h3>
      <img
        className="manual-img"
        style={{ maxHeight: "200px", display: "block", margin: "20px auto" }}
        src={schemaFilter}
        alt="filter block"
      />
      <p>
        Mono's filter is a resonant filter with low-pass, high-pass and
        band-pass output. Like the oscillator, it is a unique design : discrete
        integrator cells arranged in a three cell 18dB slope state-variable
        filter. It features a stable self oscillation at maximum resonance and
        it is precisely tuned like the oscillators. When driven hard, it
        saturates the input signal asymmetrically
        <span className="emphasis">
          · only the negative voltage is clipped ·
        </span>
        .
      </p>
      <p>
        The [CUTOFF] parameter sets the frequency, from C-1 to C10. Although you
        won't necessarily hear it because it is smoothed, the filter knob is
        quantized to semitones (just the knob, not the modulations). This is a
        controversial feature, but this is the only way to make the filter
        resonance perfectly tuned with the oscillator.
      </p>
      <p>
        The [RESONANCE] parameter goes from a very attenuated cutoff point to
        self-resonant, with lots of nice things in the middle. The resonance
        peak is quite shallow, which allows the user to “scan” the harmonic
        content of the input when swept.
      </p>
      <p>
        The [COLOR] parameters continuously fade from band-pass, to low-pass, to
        high-pass output. On these types of continous outputs, you'll usually
        find band-pass in the middle, but I find that quite boring, so there's
        that. There are some very interesting timbres in between each output.
        For example, put the knob halfway between low-pass and high-pass, and
        you get a resonant all-pass filter !
      </p>
      <p>
        The [FOLLOW] key toggles keyboard follow : when enabled, cutoff
        frequency is linked to the current note pitch.
      </p>
      <p>
        Finally the [ENV AMOUNT] parameter adjusts how much of the main envelope
        contour is applied to the cutoff frequency parameter. It's bipolar : no
        effect in the center position, positive envelope in the clockwise
        direction, and negative envelope in the counter-clockwise direction.
      </p>
      <h3
        className="anchor"
        id="synth-engine-amplifier"
        manual-section-trigger="true"
      >
        Amplifier and main envelope
      </h3>
      <img
        className="manual-img"
        style={{ maxHeight: "200px", display: "block", margin: "20px auto" }}
        src={schemaAmp}
        alt="amplifier"
      />
      <p>
        There is not much to say here, the [VOLUME] parameters surprisingly
        adjust the output volume. The envelope is a (somewhat) classic
        exponential ADSR envelope with no retriggering.
      </p>
      <p>
        There is a case where it doesn't behave like a classic analog envelope
        though : Usually when given a short trigger, an ADSR will perform its
        attack cycle only if very short, and then the release cycle. If the
        trigger is too short or the attack is long, it won't even reach the
        maximum level. If this was the case here, the attack parameter would be
        useless when using the sequencer.
      </p>
      <p>
        Here is how it works instead : when the gate is shorter than the attack
        time, the envelope acts as an AD envelope : it will perform a full
        attack cycle, and then a decay cycle down to 0.
      </p>
      <h3
        className="anchor"
        id="synth-engine-drive"
        manual-section-trigger="true"
      >
        Drive
      </h3>
      <p>
      MK2 adds a soft overdrive circuit at the output, it is the only change in the 
      signal path. The output DAC is software compensated so that driving the signal 
      doesn’t make it louder, it will just add more and more overtones as you push it
      harder. Ther drive circuit as been designed to play extremely well with the resonant filter !
      </p>
      <h3
        className="anchor"
        id="synth-engine-master-volume"
        manual-section-trigger="true"
      >
        Master volume
      </h3>
      <p>
        The volumes are recorded into each patch and mod notes separately which
        make it impossible to adjust the global volume output. Of course we
        needed to address that : there's a "hidden" master volume control, which
        is not recorded into project. To adjust it, hold [FUNC] and turn the
        [VOLUME] knob.
      </p>
      <h2 id="modulation" manual-section-trigger="true">
        Modulation
      </h2>
      <img
        className="manual-img"
        style={{ maxHeight: "200px", display: "block", margin: "20px auto" }}
        src={schemaModulation}
        alt="modulation"
      />
      <h3 id="modulation-intro" manual-section-trigger="true">
        Intro
      </h3>
      <p>
        This is where all the fun begins … Remember your old synth where you get
        to play with one LFO that you can assign to a 2 or 3 parameters ? Those
        were great ! Then the digital era introduced the “Modulation Matrix”
        which are admittedly much better in terms of synthesis possibilities,
        but are kind of a pain in the arse to work with. One of my favourite
        features with software synths is the fact that one can have an unlimited
        amount of modulators for each and every possible synthesis parameter -
        Mono implements this without menu-diving or 200 potentiometers.
      </p>
      <h3 id="modulation-contextual" manual-section-trigger="true">
        Contextual modulators
      </h3>
      <p>
        When you select something in a software instrument, you usually get a
        contextual menu appearing somewhere. Mono's X-modulator and X-envelope
        works in the same way. When you slightly move a parameter's
        potentiometer, it will be selected (a selected parameter is indicated by
        a bright orange LED). You can then edit that parameter's AD envelope and
        modulator.
      </p>
      <p>
        Every single parameter has its own envelope and modulator with their
        dedicated settings, there are no limitations. That's 18 Modulators and
        14 AD envelopes. the main envelope parameters don't have an envelope,
        that wouldn't make sense.
      </p>
      <p></p>
      <p>
        <span style={{ fontWeight: 'bold' }}>If you’re having trouble keeping up with all those amazing modulations, 
        you can now differentiate the parameters being modulated with their purple color !</span>
      </p>
      <h3 id="modulation-envelope" manual-section-trigger="true">
        X-Envelope
      </h3>
      <p>
        The X-Envelopes are AD envelopes triggered by the sequencer's note trig,
        incoming MIDI, or their very own trig sequence. Like the main envelope,
        they always complete their attack cycle before going to the decay cycle.
        The [ATTACK] and [DECAY] potentiometers control attack and decay times,
        and the [AMOUNT] potentiometer controls the envelope amount. Like the
        filter, it is bipolar, you can have negative or positive envelopes. By
        default, the X-Envelope is applied to the parameter, but it can be
        routed to the X-Modulator amount :  simply press [TO_XMOD] on the selected
        parameter, the LED should turn on, indicating that the X-Envelope is
        routed to X-Mod amount.
      </p>
      <h3 id="modulation-modulator" manual-section-trigger="true">
        X-Modulator
      </h3>
      <p>
        The X-Modulators are simple oscillators, I didn't call them LFO because
        one of their best use cases is in audio rate mode.
      </p>
      <p>
        The [WAVE] key cycles between sinusoidal (orange), triangle (light blue), square (yellow), sawtooth (dark blue), and
        S&H (pur) waveforms.
      </p>
      <p>
        The [TYPE] key lets you select one of the 3 types : LFO (LED off),
        synced LFO (LED blinking), and Audio Rate (LED on). LFO mode gives you
        rates between 40 seconds and 100ms, synced LFO has 12 different sync
        values, and resets at the beginning of the pattern. At audio-rate you
        get a tuned modulator ranging from C0 to C8. In audio rate, the
        X-modulators follow oscillator 1 frequency.
      </p>
      <h3 id="modulation-example" manual-section-trigger="true">
        A few examples
      </h3>
      <h4 id="modulation-kick" manual-section-trigger="true">
        Kick drum
      </h4>
      <img
        className="manual-img"
        style={{ width: "300px", display: "block", float: "right" }}
        src={schemaPatch1}
        alt="kick"
      />
      <p>A kick drum starts with a simple tone with a pitch envelope.</p>
      <ul>
        <li>Open the filter</li>
        <li>turn up oscillator one</li>
        <li>Set oscillator 1 wave to sinus</li>
        <li>Set a low frequency to oscillator 1</li>
        <li>Select Oscillator 1</li>
        <li>Set X-Env Attack to minimum</li>
        <li>Set X-Env Decay center</li>
        <li>Slowly turn X-Env amount clockwise while triggering Mono</li>
        <li>Et voilà, the beginning of your own amazing kick</li>
      </ul>
      <h4 id="modulation-phase" manual-section-trigger="true">
        Evolving phase effect
      </h4>
      <img
        className="manual-img"
        style={{ width: "300px", display: "block", float: "right" }}
        src={schemaPatch2}
        alt="phase"
      />
      <p>
        When 2 oscillator's frequencies are very close, it leads to interesting
        phase effects. If you modulate that frequency with an LFO, the effect
        will evolve in time, leading to even nicer subtle changes.
      </p>
      <ul>
        <li>Open the filter</li>
        <li>Turn up both oscillator</li>
        <li>Set both oscillator frequency to the same pitch</li>
        <li>Select oscillator 1 detune</li>
        <li>Set X-Mod to LFO or Synced LFO</li>
        <li>Set X-Mod Rate as you see fit</li>
        <li>Slowly turn X-Mod amount, either way</li>
        <li>Let the magic happen</li>
      </ul>

      <h4 id="modulation-pinged" manual-section-trigger="true">
        Pinged FM sound
      </h4>
      <img
        className="manual-img"
        style={{ width: "300px", display: "block", float: "right" }}
        src={schemaPatch3}
        alt="pinged"
      />
      <p>
        Most string instruments have a rich harmonic content at the beginning,
        and then slowly fade to a more muffled sound. In East-Coast synthesis
        this is usually imitated with an enveloped low-pass filter. West-Coast
        approach usually involve FM instead, here is one way to do it :
      </p>
      <ul>
        <li>Open the filter</li>
        <li>Turn up oscillator 2</li>
        <li>Set oscillator 2 to sinus wave</li>
        <li>Set oscillator 1 and 2 frequency 1 octave apart (or not)</li>
        <li>Select FM parameter</li>
        <li>Set X-Env Attack to minimum</li>
        <li>Set X-Env Decay center</li>
        <li>Slowly turn X-Env amount clockwise while triggering Mono</li>
        <li>The more you open it, the bigger the effect</li>
      </ul>

      <h4 id="modulation-filter" manual-section-trigger="true">
        Filter FM
      </h4>
      <img
        className="manual-img"
        style={{ width: "300px", display: "block", float: "right" }}
        src={schemaPatch4}
        alt="filter"
      />
      <p>
        Mono's filter responds really well to frequency modulation, it's a cool
        option to add harmonic content to any patch.
      </p>
      <ul>
        <li>Start with any patch you want</li>
        <li>Select filter cutoff parameter</li>
        <li>Set X-Mod to audio-rate</li>
        <li>Set X-Mod rate dead center (frequency will be the same as osc1)</li>
        <li>Turn X-Mod amount left or right to add FM</li>
        <li>Changing X-Mod wave gives a different FM “flavour”</li>
      </ul>
      <h2 id="sequencer" manual-section-trigger="true">
        Sequencer
      </h2>
      {/* <h3 id="sequencer-intro" manual-section-trigger="true">
        Intro
      </h3>
      <p>
          TO REDO
      </p> */}
      <h3 id="sequencer-projects-and-basic" manual-section-trigger="true">
        Projects and basic pattern operation
      </h3>
      <h4
        id="sequencer-projects-and-basic-projects"
        manual-section-trigger="true"
      >
        Projects
      </h4>
      <p>
        Mono holds up to 64 projects in the SD card. Projects need to be saved before turning off the synth, 
        save by holding [FUNC] and hitting [SAVE]. To load a project, hold [FUNC] and hit [LOAD], you will
        see a blinking LED on the [LOAD]. Projects are organized into pages of 16, use the [PAGE] key to select
        a page (projects 1 to 16 are on page A, projects 17 to 32 on page B, and so on until page D), then hit a [SEQ] key to load the
        desired project. Don't forget to save the current project before loading
        another one ! Saving and loading is very fast (less than a second) and
        doesn't break tempo, you can safely load and save in the middle of a
        live show.
      </p>
      <ul>
        <li>Projects can be changed while the sequencer is running</li>
        <li>
          While in project load mode, hold [FUNC] + [PASTE], then press once a [SEQ]
          key to select the desired slot to paste the current project. The selected [SEQ] LED
          is blinking, then press again the [SEQ] key to confirm the project paste.
          This procedure is to ensure that you don't paste a project by mistake, it will
          overwrite the project in the selected slot.
        </li>
      </ul>
      <h4
        id="sequencer-projects-and-basic-patterns"
        manual-section-trigger="true"
      >
        Patterns
      </h4>
      <p>
        The 64 patterns of a project are organized into 4 pages. Hit the
        [PATTERN] key to open pattern mode. Patch load on pattern shift is deffered
        to the first trig of newly loaded pattern. In pattern mode, the bright key
        indicates a slot with a pattern, the dimmed key indicates a slot with a
        patch but no pattern, the current pattern [KEY] blinks, and the next
        pattern's [KEY] pulses. To switch pattern, select a [PAGE] and hit a
        [SEQ] key to set the current pattern. For example, press C, then 5 to
        select the C05 pattern. To play a pattern temporarily, hold [FUNC] and
        press the pattern [SEQ] key, when releasing [FUNC] the previous pattern
        will resume playing. Press [PATTERN] again to quit pattern selection
        mode.
      </p>
      <ul>
        <li>Patterns can be changed while the sequencer is running.</li>
        <li>Patterns can be changed with program changes messages.</li>
        <li>
          While in pattern selection mode, hold [FUNC] + [COPY] and hit a [SEQ]
          key to copy the desired pattern, then hold [FUNC] + [PASTE] and hit
          the destination [SEQ] key.
        </li>
        <li>
          Hold [DELETE], and hit a [SEQ] key to clear the desired pattern. 
          Note that [DELETE] + [PATTERN] won't clear the patch.
        </li>
      </ul>

      <p>
        <span style={{ fontWeight: 'bold' }}>You can now change the color of the pattern to help you differentiate between them.
        Simply press on the pattern with [SEQ], then, after release, hold the same [SEQ] key and turn [XModAmount] to select the color you want.</span>
      </p>

      <h4
        id="sequencer-projects-and-basic-pattern-control"
        manual-section-trigger="true"
      >
        Pattern control
      </h4>
      <p>
        Press [RUN] to start the playback of a pattern, press [RUN] again to
        stop and reset. The [SEQ] keys LEDs indicate the playback position. If a
        pattern contains more than 16 steps, the [PAGE] keys LED indicate the
        position.
      </p>
      <h4
        id="sequencer-projects-and-basic-pattern-length"
        manual-section-trigger="true"
      >
        Pattern Length
      </h4>
      <p>
        Hold [FUNC] and hit [LENGTH] to open the pattern length setting. You are
        now familiar with the next step : select “page” length with a [PAGE]
        key, then the actual length with a [SEQ] key. For example, for a 50 step
        pattern, press [PAGE] D and [SEQ] 2 (3x16+2), should be doable even
        after a few beers. Hit [FUNC] to leave setting mode.
      </p>
      <h4 id="sequencer-projects-and-basic-tempo" manual-section-trigger="true">
        Tempo
      </h4>

      <p>
        When clocked internally, there are several ways to change the current
        tempo.
      </p>
      <ul>
        <li>
          Hold [TEMPO] and press [UP] or [DOWN] to increment or decrement the
          tempo by 1.
        </li>
        <li>
          Hold [TEMPO] and enter a number with the 0 to 9 [SEQ] key. For
          example, for 135 BPM, hold [TEMPO] press [SEQ] 1, then 3, then 5, and
          release [TEMPO].
        </li>
        <li>
          Tap the [TEMPO] key in a steady rhythm at least 4 times to set a tempo
          manually. After four consecutive taps, the average tempo of the taps
          will be calculated. By continuing tapping, the average tempo will keep
          on updating.
        </li>
        <li>
          Hold [TEMPO] and rotate the X-MOD [AMOUNT] potentiometer to speed
          up/slow down tempo by +/-16%, the pot center being 0. Similar to a
          turntable pitch fader.
        </li>
      </ul>
      <h4
        id="sequencer-projects-and-basic-play-styles"
        manual-section-trigger="true"
      >
        Play styles
      </h4>
      <p>
        Play styles allow you to alter the order in which the sequencer plays the
        steps of a pattern. The default of course is forward. When you switch to
        a non default play style, going back to the forward mode will position
        the play head to where it should be e.g it won't ruin your sync.
      </p>
      <p>
        <Row className="row-settings">
          <Col className="settings-col">
            <span className="param-enum">1</span> Forward (Default)
            <br />
            <span className="param-enum">2</span> Reverse
            <br />
            <span className="param-enum">3</span> Synced Pendulum
            <br />
            <span className="param-enum">4</span> Pendulum
            <br />
            <span className="param-enum">5</span> Drunk (2 Forward, 1 backward)
            <br />
            <span className="param-enum">6</span> Reverse drunk
            <br />
            <span className="param-enum">7</span> Random Drunk (75% chance
            forward, 25% backward)
            <br />
            <span className="param-enum">8</span> Reverse Random Drunk
            <br />
            <span className="param-enum">9</span> Random
            <br />
          </Col>
        </Row>
      </p>
      <p>
        You can change play style by going to the Play mode menu, [FUNC] + [RUN],
        and select one of the 9 play modes with the [SEQ] keys 1 to 9.
      </p>
      <h4
        id="sequencer-projects-and-basic-play-modes"
        manual-section-trigger="true"
      >
        Play modes
      </h4>

      <p>
        You can change the behaviour of the sequencer when a pattern switch
        occurs with the play mode setting. There are 3 play modes, if you are
        familiar with Elektron gear, they are equivalent (no need to reinvent
        what's already great right ?).
      </p>
      <ul>
        <li>
          A. Sequential (default) : The pattern switch occurs at the end of the
          currently playing pattern
        </li>
        <li>
          B. Direct Jump : The pattern switch occurs immediately, the new
          pattern will start from the position where the previous pattern left
          off.
        </li>
        <li>
          C. Direct Start : The pattern switch occurs immediately, the new
          pattern will start playing from the beginning.
        </li>
      </ul>
      <p>
        You can change play mode by going to Play mode menu, [FUNC] + [RUN], and
        select one of the 3 play modes with the [PAGE] A, B or C keys.
      </p>
      <h4
        id="sequencer-projects-and-basic-live-mode"
        manual-section-trigger="true"
      >
        Live mode
      </h4>
      <p>
        Live mode allows musician to heavily edit the running pattern without
        affecting it in memory. When live mode is enabled, a snapshot of the
        active pattern is taken when it starts. You can edit everything (patch,
        pattern, automations ...), but when you switch to another pattern or
        recall the same pattern, it will revert back to its saved state. Live
        mode also affect Mod Notes : when a Mod Note is selected on the mini
        keyboard, you can edit it, but when you leave the Live Mode, it'll revert 
        back to the saved state. Toggle live mod by holding [PATTERN] + [RUN] and
        hit the [RECORD] key. [RECORD] blinks when live mode is enabled.
      </p>
      <p>
        If you do want to save the changes you've made to the pattern while live
        mode is enabled, you need to save the project. Note that disabling live
        mode will also revert the pattern back to the saved state, so don't
        forget to save if you want to retain your changes.
      </p>
      <h4
        id="sequencer-projects-and-basic-loop-mode"
        manual-section-trigger="true"
      >
        Loop mode
      </h4>
      <p>
        Loop mode is a live oriented feature that allows you to play a small
        part of a pattern temporarily. It is especially useful in live
        situations to create movement on-the-fly. To enable it, hold [FUNC] and
        select your start point byt hitting a [SEQ] key, then the end point with
        another [SEQ] key. You can also set a reversed loop selecting the ending
        step before the starting step. The loop will start at the next step after
        releasing the [FUNC] key. To leave the loop, hit [FUNC] again. The full pattern
        will resume playback as if it was running in the background. Loop mode
        only works on a single page, selecting a start point located after the
        end point plays the loop backward.
      </p>
      <h4
        id="sequencer-projects-and-basic-song-mode"
        manual-section-trigger="true"
      >
        Song mode
      </h4>
      <p>
        The number of chained pattern to create a song is unlimited. When a song
        is playing, it will play the selected pattern sequentially, and loop
        back to the first one at the end. To create a song, you need to be in
        pattern mode, press [PATTERN], the LED should be on. Once in pattern
        mode, hold [PATTERN] and hit the [SEQ] key of the pattern you want to
        add to your song in the correct order (you can add the same pattern
        several times). Once you release [PATTERN], your song will start
        playing. To leave song mode, press a [SEQ] key.
      </p>
      <h4
        id="sequencer-projects-and-basic-patchless-pattern"
        manual-section-trigger="true"
      >
        Patchless pattern
      </h4>
      <p>
        When playing a song with normal patterns, the patch of the patterns will
        be called at each jump, so if you have the same patch on several
        patterns, it will be recalled as it was on each jump. If you are
        modulating a parameter live, this is not the behaviour you expect from
        the synth.
      </p>
      <p>
        To remedy this issue, you can set a pattern in “patchless mode”. A
        patchless pattern won't call it's associated patch when it starts, it
        will keep the previous pattern patch. This way you can create a song
        with the same patch by setting each pattern in patchless mode (not the
        first one obviously).
      </p>
      <p>
        To set a pattern in patchless mode, hold [PATTERN] and hit [RECORD], the
        blinking [PATTERN] LED indicates that the current pattern is patchless.
        Hold [PATTERN] and press [RECORD] to disable patchless mode.
      </p>

      <h4
        id="sequencer-projects-and-basic-default-patch"
        manual-section-trigger="true"
      >
        Default patch
      </h4>
      <p>
          When a pattern is loaded for the first time, a basic patch is loaded with it.
          You can now change the default patch : make the patch you want to be the default,
          then hold [TYPE] + [WAVE] and press [RECORD]. This saves the current patch as 
          the default patch. To delete the default patch, simply hold [TYPE] + [WAVE] and press [DELETE].
      </p>

      <h4 id="sequencer-pattern-morphing" manual-section-trigger="true">
        Pattern morphing
      </h4>
      <p>
        When activated, it allows you to morph between two patterns and explore the
        “in between” patterns. What does it do ? Every synth parameter of a current
        pattern will shift to the selected pattern parameters. When in pattern mode,
        hold [FUNC] + [PATTERN] and press a desired pattern you want to morph with.
        The selected pattern should blink. Then hold [FUNC] and turn the [MORPH] pot clockwise
        to morph between the main and the morphing pattern.
        You can also use the [SEQ] strip to morph between patterns, just turn on
        morph mode by pressing the [MORPH] key, be sure to be in pattern mode, 
        and glide your finger on the [SEQ] strip.
      </p>

      <h4 id="sequencer-duonote-mode" manual-section-trigger="true">
        Duophonic note mode
      </h4>
      <p>
        In this mode you can set a different pitch to each oscillator.
        Press [FUNC] + [SWING] and select [B] to enable the mode.
        Hold or press two [NOTE] on the mini keyboard to set the duophonic
        pitch and press a [SEQ] to put the duonote trig on the sequence.
        You can also edit the second pitch of a duonote by holding [FUNC] +
        [SEQ] position, and selecting a pitch on the mini keyboard
        (allows for spreads wider than an octave). The first [NOTE] pressed will
        trigger the first oscillator and the second [NOTE] pressed will trigger the second one.
      </p>

      <h3 id="sequencer-editing" manual-section-trigger="true">
        Editing a pattern
      </h3>

      <p>
        There are three ways of adding notes and automations with Mono : by inputting it
        manually, by recording it live while the sequencer is running in record mode,
        or randomly. Each step contains information concerning the note, and all the
        automation are now recorded at a very high resolution (1024PPQN).
      </p>
      <h4 id="sequencer-editing-mini-keyboard" manual-section-trigger="true">
        Mini keyboard
      </h4>
      <p>
        The [NOTE] keys act like a one octave keyboard. You can play a note to preview your current patch, use it in
        record mode, and select the currently active note. When booting up, the
        active note is C4, the selected note is indicated by a bright [NOTE] key
        LED. Press a [NOTE] key to change the currently selected note. Use the
        [UP] and [DOWN] key to change the current octave. There are 9 octaves
        available, from C0 to C10.
      </p>
      <p>
        When you hold a [NOTE] key, the [SEQ] key containing that note will
        pulse.
      </p>
      <h4 id="sequencer-editing-adding-note" manual-section-trigger="true">
        Adding notes manually
      </h4>
      <p>
        Adding note trigs manually on Mono is quite simple : just hit the
        desired [SEQ] key, it adds a note trig on that step with the currently
        selected [NOTE]. An active step is indicated by a bright LED. Select the
        page where you want to add a note with the [PAGE] key. To remove a note
        trig, hit a step with an active note trig, the led will turn off.
      </p>
      <h4 id="sequencer-editing-recording-notes" manual-section-trigger="true">
        Recording notes
      </h4>
      <p>
        You can record incoming notes live using the [NOTE] keys or an external
        keyboard. Enable recording by pressing the [RECORD] key. The [RECORD]
        key LED indicates wether the record mode is enabled or not. Recording a
        note on top of an already active note trig overwrites it. The recorded
        notes are quantized to the closest step by default but can be disabled.
        Press [FUNC] + [Bend Rg], select [PAGE A] to disable quantization and [PAGE B] to enable it.

      </p>
      <h4 id="sequencer-editing-sh-style" manual-section-trigger="true">
        SH-style sequencing
      </h4>
      <p>
        When the pattern is not playing and [RECORD] is enabled, a step [SEQ]
        key LED pulses, indicating the active step. Playing a note with the
        [NOTE] key adds a note to the active step and advances by one step. Keep
        playing notes to fill the pattern. You can place the "record head"
        anywhere by pressing the associated [SEQ] key.
      </p>
      <h4 id="sequencer-editing-change-pitch" manual-section-trigger="true">
        Change the pitch of a note
      </h4>
      <p>
        Hold the [SEQ] key of an active step and hit the desired [NOTE] key. You
        can also change the pitch by semitone by holding the [SEQ] key and hitting [UP] or
        [DOWN].
      </p>
      <h4 id="sequencer-editing-note-length" manual-section-trigger="true">
        Note length
      </h4>
      <p>
        Single step note trigs, and very short recorded notes have a particular
        behaviour on Mono. Classic ADSR envelope will not do much with a short
        pulse - if the attack is longer than a few ms, you won't hear anything …
        Fortunately Mono's envelopes are digital ! Basically, when the sequencer
        hits a single step note trig, the envelope will act as an AD envelope,
        ignoring sustain and release values. It was actually implemented by
        mistake, but I found it amazing so I kept it.
      </p>
      <p>
        Anyway, to edit a note length, hold the [SEQ] key of the desired step,
        and hit the [SEQ] key where you want the note to end. The length will be
        visible with dimmed [SEQ] LEDs. You can also hold the [SEQ] key, and drag your finger
        to the desired step on the [SEQ] strip (to the right).
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>Note that you can disable any midi recorning with the MidiRecording setting (Setting->Midi->C(on)/C#(off)).
        The Mono will still receive and play midi, but won't record it !</span>
      </p>
      <h3 id="sequencer-step" manual-section-trigger="true">
        Step parameters
      </h3>
      <h4 id="sequencer-step-accent" manual-section-trigger="true">
        Accent
      </h4>
      <p>
        Accent can be added on a per step basis. Hit [ACCENT] to open accent
        mode, and hit the desired [SEQ] key to add or remove accents from steps.
        In accent mode, the active note trigs are shown by a dim [SEQ] key LED,
        active accents are shown by a green [SEQ] key LED. Hit [ACCENT] again
        to leave accent mode. The same can be done when just holding the
        [ACCENT] key.
      </p>
      <h4 id="sequencer-step-accent-param" manual-section-trigger="true">
        Accent parameters
      </h4>
      <p>
        By default an accent slightly increases the amp level and the filter cut-off frequency,
        but this is entirely customisable. To set your own accent parameters on the active pattern,
        start by setting default offsets to 0 by holdind [ACCENT] and set Cutoff and Volume to the middle.
        Keep holding accent and set pots to the desired value. The value stored in memory is an offset
        from the initial value to the last position of the potentiometer. For example, if the cutoff
        value is set dead center and you move it 20% to the right while holding [ACCENT], every accented
        step will have a higher cut-off frequency by this amount, whatever the current value is,
        or if there is automation on that step. You can add as many values as you want,
        by it synth parameters or modulation parameters.
      </p>
      <h4 id="sequencer-step-accent-ratio" manual-section-trigger="true">
        Global Accent ratio
      </h4>
      <p>
        The global accent ratio is a useful live feature. When holding [UP] +
        [ACCENT], the X-Mod amount potentiometer allows to set the ratio of the
        accent applied to the accented note. At full right, 100% of the accent is
        applied (default behaviour), at noon, 0% is applied, and at full left
        -100% of the accent is applied, creating a "negative accent". The global
        accent ratio is reset to zero when switching patterns.
      </p>
      <h4 id="sequencer-step-slide" manual-section-trigger="true">
        Slide
      </h4>
      <p>
        Slide can be added on a per step basis. Hit [SLIDE] to open slide mode,
        and hit the desired [SEQ] key to add or remove slide to a step. When a
        slide is active on a step, all the step parameters will slide linearly
        to the next step parameters (note as well as parameter offsets). In
        slide mode, the active note trigs are shown by a dim [SEQ] key LED.
        Active slide trigs are shown by a bright [SEQ] key LED. Hit [SLIDE]
        again to leave slide mode. The same can be done when just holding the
        [SLIDE] key. To clear all slides, hold [SLIDE] and hit [DELETE].
      </p>
      <h4 id="sequencer-step-slide-rate" manual-section-trigger="true">
        Slide Rate
      </h4>
      <p>
        The rate of the slide, or in other word, the time it gets to the slide to 
        get to the target note, can be set on a per pattern basis. Hold [slide] and
        turn the [XModRate] potentiometer to set the slide rate. The slide default 
        position (at noon on the pot), is a length of half a step.

      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>You can now change the Slide time by holding [SLIDE] and turning
        [XModRate] !</span>
      </p>
      <h4 id="sequencer-step-param" manual-section-trigger="true">
        Microshift, probability, ratchet
      </h4>
      <p>
        Each step of a pattern contains a microshift, probability, and ratchet
        value. These 3 parameters together allow musicians to create very
        organic sequences - it's an excellent way to break the 4/4 monotony that
        usually haunts an x0x-style sequencer. To edit one of these parameters,
        hold [FUNC] + the desired [SEQ] key, and set the value with
        [MICROSHIFT], [PROBABILITY] or [RATCHET] potentiometer, the [SEQ] LED strip
        is going to show the value saved in the step (blue is the lowest, red is the highest).
      </p>
      <p>
        You can record microshift, probability, and Ratchet automation when
        [RECORD] is enabled, by holding [FUNC] and moving the associated knob.
      </p>
      <p>
        Hold [DELETE] + [FUNC] (in that order), and move the associated knob to
        delete all microshift/probability/ratchet of the current pattern.
      </p>
      <ul>
        <li>
          Microshift : The microshift parameter shifts the starting point of a
          step forward or backward by up to ½ step.
        </li>
        <li>
          Ratchet : The ratchet parameter multiplies the number of note trigs in
          a single step. From 1 (default behaviour) when the pot is fully
          counterclockwise to 16 fully clockwise. The trigs are evenly
          distributed on the time between the start of the selected step and the
          start of the next step, even when both have different microshift
          values.
        </li>
        <li>
          Probability : The probability as its name suggests gives note trigs a
          certain probability to be sent to the synth engine or not. From 100%
          probability hard left, to 0% hard right. When combined with the
          ratchet value, each ratcheted note trigs are subjected to the
          probability parameter.
        </li>
      </ul>
      <h4 id="sequencer-step-mutes" manual-section-trigger="true">
        Step Mutes
      </h4>
      <p>
        To create interesting variations on the fly, it is possible to Mute
        individual steps of a pattern. To do so, hold [DOWN] and hit the step's
        [SEQ] key you want to mute/unmute. All the selected steps will be
        muted/unmuted upon releasing the [DOWN] key. The muted steps are
        indicated by a blinking [SEQ] key. Of course you can mute steps that
        only contain offset informations.
      </p>
      <p>
        To unmute all steps (and mod notes : see mod note mutes) all at once,
        hold [DOWN] and press [DELETE].
      </p>
      <h3 id="sequencer-param-offset" manual-section-trigger="true">
        Parameter offsets or automations
      </h3>
      <p>
        On the Mono Mk2, every automations is now recorded at a very high resolution (1024PPQN, or 256 per step).
        This allows for very smooth transitions between steps, and a lot of possibilities
        when it comes to sound design. The sequencer can record offsets to every parameter, 
        either synth parameters (filter cut-off, oscillator waves, enveloppe decay…) or
        modulation parameters (filter resonance X-MOD Rate, oscillator frequency X-ENV amount …).
      </p>
      <p>
        Say a parameter value ranges between 0 and 100, and the potentiometer of
        this parameter is set dead center. At this setting, the current value,
        or potentiometer value is 50. If you add an offset value to a step by
        holding a [SEQ] key and turning said potentiometer a bit to the right,
        say to 60. Then you are adding a parameter offset of 10 to that step.
        Now each time the sequencer plays that step, it will add an offset of 10
        to said parameter, and go back to the current value, or to the actual
        potentiometer value at the next step.
      </p>
      <p>
        This feature really turns the Mono sequencer into a powerhouse,
        everything can be sequenced, and sequencing parameters doesn't “fix” the
        parameters value, but it adds a sequence “on top” of the current
        potentiometer position. This approach gives the user the freedom to
        adjust the knobs while performing in a live situation.
      </p>

      <h4 id="sequencer-param-offset-visual" manual-section-trigger="true">
        Parameter offset visualization
      </h4>
      <p>
        You can visualize parameter offsets immediately on potentiometer LEDs.
        LEDs intensity will show you how the offset strength is.
        The Curve Mode is also a great way to visualize unique parameter offsets.
      </p>

      <h4 id="sequencer-param-offset-add" manual-section-trigger="true">
        Adding parameter offsets
      </h4>
      <p>
        To add a parameter offset on a step, hold a [SEQ] key, and turn the
        desired knob either way to add an offset - the more you turn the knob,
        the bigger the offset. The same can be done to modulation parameters -
        don't forget to select the synth parameter on which you want to add the
        modulation parameter offset first (that's a lot of words - sorry about
        that).
      </p>

      <h4 id="Curve-mode" manual-section-trigger="true">
        Curve Mode
      </h4>
      <p>
        One of the big update on Mono Mk2 is the addition of Curve mode. Curve mode
        allows you to see the parameter offset curve on [SEQ] LEDs. To enable Curve mode,
        hold [FUNC] and press the [UP] key. The [SEQ] LEDs will show the offset curve
        of the selected parameter. To show the curve of an expressivity, simply press
        the corresponding switch ([X], [Y] or [Z]), it will turn orange, showing
        the selected expressivity. You can then edit the curve by holding [UP] and pressing any [SEQ] key where the 
        LED is blinking. Now drag your finger left or right and up or down to edit the curve. 
        The curve will update when you release your finger from the [SEQ] strip.
      </p>

      <h4 id="sequencer-param-offset-record" manual-section-trigger="true">
        Recording parameter offsets
      </h4>
      <p>
        You can also record parameter offsets while in record mode. To enable
        the mode, press the [RECORD] key, and turn a parameter knob while the
        sequencer is playing. This will record a high definition automation offset
        where a parameter knob position change is detected, so you need to
        continually move the knob to add offsets. This is a bit
        strange at first but it was mandatory, otherwise everything would be
        recorded at 0 offset as soon as [RECORD] is enabled, that's obviously
        not the best behaviour …
      </p>
      <h4 id="sequencer-expressive-param-offset" manual-section-trigger="true">
        Custom expressive parameter offsets
      </h4>
      <p>
        Mk2 allows you to set velocity, pitchbend, vertical and aftertouch. Each of these MIDI parameters
        can affect the patch according to custom offsets on any and all parameters and their modulation values.
        To check nor set these offset, the corresponding switch needs to be held, when the switch
        is held the LED brightness of the parameter shows the offset “strength”. Offset can be negative,
        a potentiometer at center position means no offset, full CCW means maximum negative offset,
        full CW means maximum positive offset.
      </p>
      <p>Here are the 4 switches :</p>
      <p>
        <ul>
          <li>
            [Vel] - Velocity
          </li>
          <li>
            [X] - Pitchbend
          </li>
          <li>
            [Y] - Vertical (CC74)
          </li>
          <li>
            [Z] - Aftertouch
          </li>
        </ul>
      </p>
      <p>
        These MIDI parameters can be recorded in high definition, like automation when played from an external keyboard
        or directly from the Mono keyboard. To record these parameters from Mono, press the desired expressive parameter
        switch, it turns on, and then press [RECORD] then [RUN], and record your expressivity.
        <p>
        <span style={{ fontWeight: 'bold' }}>Note that you can disable any midi recorning with the MidiRecording setting (Setting->Midi->C(on)/C#(off)).
        The Mono will still receive and play midi, but won't record it !</span>
      </p>
        The Mono Mk2 keyboard is 3D sensitive, meaning that it can detect the pressure in any axis (X, Y, Z).
        When moving your finger left to right, it affects the X axis (Pitchbend), when moving your finger up and down,
        it affects the Y axis (Vertical), and when pressing the keyboard, it affects the Z axis (aftertouch).
      </p><p>
        Note that the X parameter affects both how the pitch (MIDI note) and pitchbend affect your sound. The idea is that the pitch is represented as a continuous value
        by both pitch and pitchbend. The tuned parameter (OSC frequency and cutoff frequency) have special quantized values. 
        This allow for microtonal/macrotonal playing. At center position the oscs/filter don’t track, 
         there are then 6 positions on the positive and negative side : ⅛ semitone - ¼ semitone - ½ semitone 
         - 1 semitone - 1 tone - 2 tone.
      </p>

      
      <h3 id="sequencer-unlink-env" manual-section-trigger="true">
        Unlink X-Env Sequence
      </h3>
      <p>
        To create interesting effects in a pattern, it is possible to unlink the
        X-Envelope triggers from the note trigs. When doing so, the X-Envelope
        of the selected parameter will be replaced by the one entered on the
        [SEQ] keys.
      </p>
      <p>
        To create an unlinked X-Env sequence, start by selecting the desired
        parameter - [CUTOFF] for example. Then press [UNLINK], and hit
        [SEQ] keys to create your unlinked sequence. As you see the green [KEY]
        show the unlinked sequence. To disable an unlinked sequence and trig the
        X-Env with the note trigs again, you need to disable all the unlinked
        trigs.
      </p>
      <p>Unlinked X-Env trigs will also reset the LFO phase !</p>
      <h3 id="sequencer-copy" manual-section-trigger="true">
        Copy paste and clear
      </h3>
      <p>
        To copy a step, hold [FUNC] + [COPY] then hit the [SEQ] key of the step
        to be copied. To paste, hold [FUNC] + [PASTE], and hit the destination
        [SEQ] key. You can also copy a range of steps, hold [FUNC] + [COPY], hold
        the start [SEQ] of the range and press the end [SEQ]. Then hold [FUNC] +
        [PASTE], the starting [SEQ] step where you want to paste, and then the end [SEQ].
        Clear a step by holding [DELETE] and hitting the desired [SEQ] key. These step
        operations act on every parameter - note, accent, slide, parameter offset and so on.
      </p>
      <p>
        The same can be done with full pages, use the desired [PAGE] key
        instead. You can now copy a page and paste it on an other page
        without having to set the pattern length. Hold [FUNC] + [COPY]
        and press the [PAGE] to copy, then hold [FUNC] + [PASTE] and press
        the [PAGE] where you want to paste.
      </p>
      <p>
        To copy a whole parameter automation (all parameter offsets on the
        pattern), hold [FUNC] + [COPY] and slightly move the potentiometer of
        the desired parameter. To paste, hold [FUNC] + [PASTE] and slightly move
        the target parameter. To clear a whole parameter hold [DELETE] and
        slightly move the desired potentiometer.
      </p>
      <p>
        You can also copy, paste and clear a patch. To do so, use the [RECORD]
        button : To copy, hold [FUNC] + [COPY] and press [RECORD]. Navigate to
        the pattern where you want to paste the patch, hold [FUNC] + [PASTE] and
        hit [RECORD]. To delete a patch, hold [DELETE] and press [RECORD]. The
        patch will be cleared to the default patch.
      </p>
      <h3 id="sequencer-undo" manual-section-trigger="true">
        Undo/Redo operations
      </h3>
      <p>
        Every single action of the sequencer is undoable. If you are not quite
        happy with a recorded automation, you can undo it. Hate that change of
        pitch on the last 4 notes ? Undo it. Not so bad in the end ? Redo it.
        Simple hold [FUNC] and hit [UNDO] to undo and … you guessed it, hold
        [FUNC] and hit [REDO] to redo.
      </p>
      <h3 id="sequencer-settings" manual-section-trigger="true">
        Pattern settings
      </h3>
      <h4 id="sequencer-settings-scale" manual-section-trigger="true">
        Scale and root note
      </h4>
      <p>
        To set up a scale and a key, hold [FUNC] and hit [SCALE]. Set up the
        root note with the [NOTE] key and set the scale with the [SEQ] key. Here
        is the list of the scale and their related [SEQ] key. Scale and root are
        mostly useful with the randomizer, more on the next section. You can
        visualize the selected scale on the mini keyboard. Only the [NOTE] key
        LED in the selected scale are on, with the root note a bit brighter. If
        you are allergic to music theory, this is a pretty handy tool.
      </p>
      <h4 id="sequencer-settings-custom-scale" manual-section-trigger="true">
        Custom scales
      </h4>
      <p>
        Each project hold 4 custom scales on top of the 16 fixed scales. You can
        select it with the [PAGE] keys while the scale setting is active. To
        add/remove note from a custom scale, hold the desired [PAGE] key and hit
        [NOTE] keys on the MIDI keyboard.
      </p>
      <h4 id="Scale-autoquantize" manual-section-trigger="true">
        Scale autoquantize
      </h4>
      <p>
        When scale autoquantize is enabled, changing the scale
        will move the notes of the pattern according to the new scale, it uses a smart
        algorithm that works pretty well even on custom scale. The algorithm is however
        destructive : if you go from a 7 note scale to a custom scale of 4 notes and switch
        back to a 7 note scale, you wont get back the same pattern.
      </p>
      <h4 id="sequencer-settings-transpose" manual-section-trigger="true">
        Transpose
      </h4>
      <p>
        To transpose a whole pattern hold [FUNC] and hit [TRANSPOSE], you can
        select the transposition level with the [NOTE] keys. Use the [SEQ] key
        to select the octave. Key 8 is the default value.
      </p>
      <h4 id="sequencer-settings-beat-div" manual-section-trigger="true">
        Beat division
      </h4>
      <p>
        To set up the pattern beat division, hold [FUNC] and hit [BEAT DIV]. The
        selected [SEQ] key defines how many steps are played each beat, default
        is 4.
      </p>
      <h4 id="sequencer-settings-swing" manual-section-trigger="true">
        Swing
      </h4>
      <p>
        To set up the swing value of the current pattern, hold [FUNC] and hit
        [SWING]. The default value is 9 (no swing). A smaller value will add
        shuffle to the pattern (advance odd step), and a greater value will add
        swing (delay odd step).
      </p>
      <h3 id="sequencer-mod-note" manual-section-trigger="true">
        Mod Note mode
      </h3>

      <p>
        The mod note mode is a little bonus mode that allows users to create up
        to 40 custom temporary patch or timbre settings per project to be played
        and recorded. Hit [MOD NOTE], the key LED should be on indicating
        that you enabled Mod Note mode. The 8 bottom [NOTE] keys each represent
        a timbre, you can't use them to play pitch anymore.
      </p>
      <p>
        The mod notes are scoped to the project, each project can hold 40 mod
        notes, separated into 5 pages. You can scroll the pages with the [UP]
        and [DOWN] key.
      </p>
      <p>
        To set up the timbre, select one of those Mod Note keys, say the D
        [NOTE] key. When you select an empty Mod Note slot, the current patch
        will be copied to that mod note slot. Editing the timbre by rotating
        knobs will now edit that Mod Note's timbre. Every time you play that
        key, the timbre that you recorded will be played. It will
        virtually turn the potentiometer to the recorded positions. You can add
        note trigs just as you would do in normal mode to create a sequence of
        Mod Notes.
      </p>
      <p>
        The next "normal" note trig played by the sequencer after a mod note
        will revert the synth parameters back to the pattern's patch values. So
        you can use these Mod Notes to create “happy accidents” in an otherwise
        simple pattern by playing it live for example, or to create weird
        patterns using different timbres. Go back to normal mode by pressing
        [MOD NOTE] again.
      </p>
      <p>
        Note that you can't edit the pattern's patch once in Mod Note, for that
        you will need to go back to "normal" player mode. You can check weither
        or not a note is a Mod Note by pressing [MODNOTE] key, if a note is purple, then it's a Mod Note.
        If you want to check if a particular Mod Note is present in your current Pattern, just
        hold that Mod Notes [KEY]. The [SEQ] key containing it will pulse.
      </p>
      <h4 id="sequencer-mod-note-pitch" manual-section-trigger="true">
        Mod Note pitch
      </h4>
      <p>
        You can edit a Mod Note note pitch : hold the mod note [KEY] and press
        [UP] or [DOWN] to set the mod note octave. Hold [UP] + [DOWN] and press a [KEY]
        to set the current modnote semitone. Hold a step [SEQ] that contains a modnote and
        press [UP] or [DOWN] to change its pitch.
      </p>
      <h4 id="sequencer-mod-note-autom" manual-section-trigger="true">
        Automation on Mod Note
      </h4>
      <p>
        Mod Notes can be affected by automation, just like the patch notes.
      <p>
        <span style={{ fontWeight: 'bold' }}>Note that you can disable the automations on all ModNotes with this setting : (Setting->Clock->Page1(on)/Page2(off))</span>
      </p>
      </p>
      <h4 id="sequencer-mod-note-mutes" manual-section-trigger="true">
        Mod Note Mutes
      </h4>
      <p>
        To create interesting variations on the fly, it is posible to Mute
        individual Mod Notes of a pattern. To do so, hold [DOWN] and hit the
        mod note's [NOTE] key you want to mute/unmute. All the selected mod
        notes will be muted/unmuted upon releasing the [DOWN] key. The muted
        mod notes are indicated by a blinking [NOTE] key.
      </p>
      <p>
        To unmute all mod notes (and steps : see step mutes) at once, hold
        [DOWN] and press [DELETE].
      </p>
      <h4 id="sequencer-mod-note-copy" manual-section-trigger="true">
        Copy and paste operations
      </h4>
      <p>
        One can copy/paste a Mod Note - hold [FUNC] + [COPY], hit the mod note
        [NOTE] key you want to copy, then hold [FUNC] + [PASTE] and hit the
        destination [NOTE] key.
      </p>
      <p>
        You can also “import/export” mod note to the current patch. To copy the
        current patch to a mod note, hold [RECORD] and press the desired [NOTE]
        key. Inversely, hold a [NOTE] key and press [RECORD] to copy a mod note
        to the current patch.
      </p>
      <h2 id="randomizer" manual-section-trigger="true">
        Randomizer
      </h2>
      <p>
        Mono features several randomizing features to assist the musician's
        creative process. They allow users to explore the depth of Mono's
        synthesis engine. There are different randomize options : randomize
        page, step, parameter, accent, slide, and Mod Note.
      </p>
      <h3 id="randomizer-page" manual-section-trigger="true">
        Page randomizer
      </h3>
      <p>
        The page randomizer is useful to get a pattern going quickly, or to
        create alternative patterns starting from a base pattern. It adds
        between 1 and 4 note trigs with random pitch on said page. The pitch is
        always quantized to the scale of the pattern - this way, you can safely
        use it in live situations. Of course, you can undo/redo this operation.
        The page randomizer was designed to be used as an “additive tool” - you
        can start from scratch on an empty pattern or create alterations on a
        rhythmic pattern, and then undo to go back to the original pattern. The
        added step won't contain parameter offsets, probability, ratchet or
        microshift.
      </p>
      <p>
        To randomize a page, hold [FUNC] + [TEMPO], and hit the desired [PAGE]
        key.
      </p>
      <h3 id="randomizer-pitch" manual-section-trigger="true">
        Pitch/Mod Note randomizer
      </h3>
      <p>
        The pitch randomizer allows the user to randomize the pitch of all the
        active notes in the current pattern. It won't add or remove notes. As
        you might expect, the pitch of the randomized pitch will be quantized to
        the current scale.
      </p>
      <p>
        The pitch randomizer is contextual, when you are in player mode it won't
        affect the mod notes. When in mod note mode, it won't affect the patch
        notes, and will randomize the mod notes of the pattern, picking only mod
        notes from the current page.
      </p>
      <p>
        To randomize pitch, hold [FUNC] + [TEMPO], and hit the [PATTERN] key.
      </p>
      <h3 id="randomizer-dice" manual-section-trigger="true">
        Step Dice
      </h3>
      <p>
        Step dice will take all the trigs of a selected range and randomly
        reassign them to a new position. Just like the pitch randomizer it is
        contextual. It will dice only the patch notes in player mode and the mod
        notes in mod note mode. Hold your breath, this is the only 4-keys
        combination on Mono. To step dice, hold [FUNC] + [TEMPO], hold the [SEQ]
        key ending your range, and press the [SEQ] key starting your range in this order.
      </p>
      <h3 id="randomizer-step" manual-section-trigger="true">
        Step randomizer
      </h3>
      <p>
        The step randomizer as its name suggests randomizes a single step. It
        adds a random note trig to the desired step, the pitch is also quantized
        to the pattern. It will also randomize some synth parameter offsets. The
        parameter offsets being randomized are selected at random, and the value
        as well. To avoid going too wild, only the synth parameters are
        randomized, not the modulation parameters. The Freq and detune parameter
        are never randomized to keep the randomized step in tune.
      </p>
      <p>
        To randomize a step, hold [FUNC] + [TEMPO], and hit the desired [SEQ]
        key.
      </p>

      <h3 id="randomizer-parameter" manual-section-trigger="true">
        Parameter randomizer
      </h3>
      <p>
        The parameter randomizer basically creates a random automation on a
        modulation or synth parameter. There is a 50% chance that a parameter
        offset is added on each step. The random value is also restricted to a
        range set by the musician, the offsets will never exceed these values.
      </p>
      <p>
        To randomize a parameter, hold [FUNC] + [TEMPO], turn the desired
        parameter to the desired lower value, then turn it the other way, to the
        desired upper value. On releasing [FUNC], the parameter will be
        randomized.
      </p>
      <h4 id="randomizer-range" manual-section-trigger="true">
        Randomize a step range
      </h4>
      <p>
        To randomize a range of step, hold [TEMPO] + [FUNC],
        hold the [SEQ] starting the range, and then press the
        [SEQ] ending the range, release, and voila !
        It will randomize all parameters of mixer, filter and envelope section.
      </p>
      <h4 id="range-autom-randomizer" manual-section-trigger="true">
        Randomize automation range
      </h4>
      <p>
        Randomize automation range : hold [TEMPO] + [FUNC], set the desired range by
        holding the first [SEQ] and pressing the end [SEQ]. Release the [SEQ] switches
        (not [TEMPO] and [FUNC]) then turn the desired knobs parameter left and right to set the
        randomisation range, just like the randomize automation feature.
      </p>
      <h3 id="randomizer-accent" manual-section-trigger="true">
        Accent and slide randomizer
      </h3>
      <p>
        To randomize accent or slide on a pattern, hold [FUNC] + [TEMPO], and
        hit [ACCENT] or [SLIDE]. This will clear the current accent/slide and
        place accent/slide on note trigs at random.
      </p>
      <h3 id="randomizer-mod" manual-section-trigger="true">
        Mod note randomizer
      </h3>
      <p>
        The Mod note randomizer is the wildest of all. It allows musicians to
        randomize a Mod Note in the Mod Note mode. The result will usually 
        be extremely weird bleeping noises, but we all love that don't we ?
      </p>
      <p>
        To randomize a Mod note you need to enable the Mod Note mode with [MODNOTE].
         Hold [FUNC] + [TEMPO], then hit one of the 8 bottom [NOTE] keys
        to randomize the desired mod note.
      </p>
      <h2 id="settings" manual-section-trigger="true">
        Project settings
      </h2>
      <p>
        First things first, let's be honest - editing settings on Mono is not
        the best user experience. There is no way to make the process easy
        without a display, so you either need a good memory, or you need to keep
        the manual close. The process is always the same though, hold [FUNC],
        hit the desired settings, use the [SEQ] and [PAGE] key to select the
        desired value. Exit by hitting [FUNC] again.
      </p>
      <h3 id="settings-midi" manual-section-trigger="true">
        MIDI
      </h3>
      <p>
        This setting allows user to set the Mono MIDI channel.
        Use the [SEQ] button to select the midi channel from 1 to 16.
        <p></p>
        The [PAGE] key allows you to turn on/off the ModNoteMidiChannel.
        When enabled, it allows you to play Mod Notes from an external keyboard,
        by using the next MIDI Channel from the one selected on the Mk2 (so by default, 
        when activated, Mod Notes are controlled by Channel 2).
      </p>
      <h3 id="settings-pot" manual-section-trigger="true">
        Pot mode
      </h3>
      <p>
        The [POT MODE] setting changes the behaviour of the potentiometers when
        the physical potentiometers doesn't reflect the internal stored value.
        This happens on synth parameters when switching patterns, and on
        modulation parameters when selecting another synth parameters or editing
        ratchet, probability or microshift. There are three modes :
      </p>
      <ul>
        <li>
          Jump [SEQ 1] (default) : Uses absolute value based upon the position
          of the pot when edited. Turn a pot and the parameter value jumps
          immediately from the stored value to the edited value.
        </li>
        <li>
          Passthru [SEQ 2] :Turning the pot has no effect until after the edited
          value equals the stored value (that is, until the edited value “pass
          through” the stored value).
        </li>
        <li>
          Relative [SEQ 3] : Changes are relative to the stored setting. In
          relative mode, the full value range is not available until either the
          minimum or the maximum value and the respective lower or upper limit
          of the pot's travel is reached. For example, let's say the cut-off
          parameter has an internal value range of 0 to 100 (chill out geeks, it
          is 16 bit internally). The physical position of the pot is at 80 and
          you load a program with a stored value of 20. Turning the pot all the
          way up will only set the parameter to 40. To get the maximum value you
          first have to turn down until the value is at the other bound of the
          range, in this case 0, or fully counterclockwise.
        </li>
      </ul>
      <h3 id="settings-bend" manual-section-trigger="true">
        Bend Rg
      </h3>
      <p>
        The [BEND RG] sets the range of the pitch wheel bend in semitones. Use
        the [SEQ] keys to select the range from 1 to 16, 3 being the default
        value.
      </p>
      <h3 id="settings-trigger" manual-section-trigger="true">
        Clock
      </h3>
      This setting allows the user to set the sequencer clock source. Use the [SEQ]
       keys to select the 4 clock sources available:
      <ul>
        <li>
          Internal [SEQ 1] (default) : Mono uses its internal clock, and [RUN]
          key to manage playback.
        </li>
        <li>
          USB [SEQ 2] : Mono uses the clock received on the MIDI port,
          playback operation is handled with MIDI start stop messages. The [RUN]
          key can still be used to handle transport.
        </li>
        <li>
          MIDI [SEQ 3] : Mono uses the clock received on the USB port, playback
          operation is handled with MIDI start stop messages. The [RUN] key can
          still be used to handle transport.
        </li>
        <li>
          CV [SEQ 4] : Mono uses its CV clock input as clock. In this mode,
          each step is triggered by an incoming pulse signal at the CV clock
          input, and the pattern restarts everytime a pulse is received at the
          reset input. Note that when using CV clock, some features of Mono
          can't work : microshift, ratchet, and swing. This mode really shines
          when using a weird semi-random clock source like a Make Noise
          Wogglebug.
        </li>
      </ul>
      <h2 id="update" manual-section-trigger="true">
        Update and calibration
      </h2>
      <h3 id="update-firmware" manual-section-trigger="true">
        Updating firmware
      </h3>
      <p>
        To update the software, simply put the "mono_mk2_update.bin" file
        in the root folder of the SD card, then press [PATTERN] + [RUN] when
        turning on the synth. The update will start, the [SEQ] LED strip 
        will start to blink. When the update is done, the synth will restart.
      </p>
      <h3 id="update-oscillators" manual-section-trigger="true">
        Oscillators calibration
      </h3>
      <p>
        Like every analog synthesizer, the oscillators needs to be calibrated
        from time to time. Mono's oscillator and filter are no exception. To
        calibrate the oscillator and filter, turn off the unit, hold [UNLINK] + [TO XMOD]
        on the mini-keyboard, and turn the unit back on. The calibration
        procedure will start. The [SEQ] LEDs indicate the progress of
        calibration of each oscillator and filter, so it will restart at the
        beginning twice. The calibration procedure takes about 3 minutes. It is
        recommanded to calibrate Mono when it is hot, after being powered for at
        least 15 minutes.
        <p></p><strong>Restart the synth once calibration is done.</strong>
      </p>
      <h2 id="midi" manual-section-trigger="true">
        Midi implementation
      </h2>
      <h3 id="midi-velocity" manual-section-trigger="true">
        Velocity
      </h3>
      <p>
        The velocity value from the synth or from of an incoming MIDI note affects the amplification
        level of that note, internal default value is 100, any value
        below 100 will result in a quieter note. The values above 100 will apply
        an accent with a ratio between 0 and 100%.
      </p>
      <h3 id="midi-cc" manual-section-trigger="true">
        Parameter editing via CC
      </h3>
      <p>
        Mono synth and modulation parameters can be edited via control change
        message, it is also possible to select the active parameter via CC.
        Potentially, the synth engine can be entirely controlled via MIDI. It is
        also possible to send the parameter automation with the [PARAM SND]
        setting, on the same CC. This is disabled by default.
      </p>
      <h4 id="midi-cc-table" manual-section-trigger="true">
        CC parameter table
      </h4>
      <p>
        <Row className="row-settings">
          <Col className="settings-col">
            <span className="param-enum">7</span> Output Volume
            <br />
            <span className="param-enum">14</span> Reset Modulator Phase
            <br />
            <span className="param-enum">15</span> Go To Step
            <br />
            <span className="param-enum">43</span> Attack
            <br />
            <span className="param-enum">44</span> Decay
            <br />
            <span className="param-enum">45</span> Sustain
            <br />
            <span className="param-enum">46</span> Release
            <br />
            <span className="param-enum">47</span> Filter Envelope Amount
            <br />
            <span className="param-enum">48</span> Oscillator 1 Freq
            <br />
            <span className="param-enum">49</span> Oscillator 1 Detune
            <br />
            <span className="param-enum">50</span> Oscillator 1 Wave
            <br />
            <span className="param-enum">51</span> Oscillator 1 Volume
            <br />
            <span className="param-enum">52</span> FM Amount
            <br />
            <span className="param-enum">53</span> Sync On/Off
            <br />
            <span className="param-enum">54</span> Oscillator 2 Freq
            <br />
            <span className="param-enum">55</span> Oscillator 2 Detune
            <br />
          </Col>
          <Col className="settings-col">
            <span className="param-enum">56</span> Oscillator 2 Wave
            <br />
            <span className="param-enum">57</span> Oscillator 2 Volume
            <br />
            <span className="param-enum">74</span> Vertical Level (Filter Freq + Out Vol)
            <br />
            <span className="param-enum">75</span> Filter Resonance
            <br />
            <span className="param-enum">76</span> Filter Color
            <br />
            <span className="param-enum">77</span> Filter Follow On/Off
            <br />
            <span className="param-enum">78</span> Active X-Mod Rate
            <br />
            <span className="param-enum">79</span> Active X-Mod Amount
            <br />
            <span className="param-enum">80</span> Active X-Env Attack
            <br />
            <span className="param-enum">81</span> Active X-Env Decay
            <br />
            <span className="param-enum">82</span> Active X-Env Amount
            <br />
            <span className="param-enum">83</span> Active X-Mod Type (Cycle)
            <br />
            <span className="param-enum">84</span> Active X-Mod Wave (Cycle)
            <br />
            <span className="param-enum">85</span> Accent
            <br />
            <span className="param-enum">86</span> Slide
            <br />
          </Col>
        </Row>
      </p>
      <h3 id="midi-pc" manual-section-trigger="true">
        Program change
      </h3>
      <p>
        Mono supports program change to switch pattern, send a program change
        between 0 and 63 to set the current pattern. Note that play mode will
        affect the behavior just as it would if you switch pattern manually.
      </p>

      {/* <h3 id="midi-dump" manual-section-trigger="true">
        Program dump/receive
      </h3>
      <p>
        Program dump allows users to dump a project as a MIDI sysex file to a
        computer. It is useful if you need room for new projects, or simply as a
        backup. To dump a program, launch your Sysex software, set it in
        reception mode with Mono as source, hold [FUNC] and press [PGM DUMP]
      </p>
      <p>
        To load a project back to Mono, open the projects in which you want to
        receive the sysex file, hold [FUNC] and press [PGM RCV]. On your sysex
        software, send the project to Mono. As soon as you save the received
        project, it will overwrite the old one permanently. Don't forget to save
        the Project before leaving and load another project.

      </p> */}
    </>
  )
};

export default monoManualV2Data;
