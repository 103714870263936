import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import "./TabRandomizer.css";
import arrow from "./../../images/ArrowRightBlack.svg";
import arrowLong from "./../../images/Arrow-LongIntr.svg";
import picHowWorks from "./../../images/howWorks.svg";
import VideoPlayer from "react-video-js-player";
import Lottie from "lottie-react";
import video from "./../../videos/mono_rand.mp4";

import randPage from "./../../Lotties/mono_rand_page_rand.json";
import randPitch from "./../../Lotties/mono_rand_pitch_rand.json";
import randStepDice from "./../../Lotties/mono_rand_step_dice.json";
import randSingleStep from "./../../Lotties/mono_rand_single_step.json";
import randSynthParam from "./../../Lotties/mono_rand_synth_param.json";
import randAccentSlide from "./../../Lotties/mono_rand_acent_slide.json";
import { Row, Col } from "react-bootstrap";

const pageData = {
  pages: [
    {
      number: "01.",
      title: "Page Randomizer",
      text:
        "The page randomizer is useful to get a pattern going quickly, or to create alternative patterns starting from a base pattern. It adds between 1 and 4 note trigs with random pitch on said page.",
      anim: randPage,
      manualLink: "/mono/manual#randomizer",
    },
    {
      number: "02.",
      title: "Pitch/Mod Note",
      text:
        "The pitch randomizer allows the user to randomize the pitch of all the active notes in the current pattern. It won't add or remove notes. As you might expect, the pitch of the randomized pitch will be quantized to the current scale.",
      anim: randPitch,
      manualLink: "/mono/manual#randomizer-pitch",
    },
    {
      number: "03.",
      title: "Step Dice",
      text:
        "Step dice will take all the trigs of a selected range and randomly reassign them to a new position. Just like the pitch randomizer it is contextual. It will dice only the patch notes in player mode and the mod notes in mod note mode.",
      anim: randStepDice,
      manualLink: "/mono/manual#randomizer-dice",
    },
    {
      number: "04.",
      title: "Single Step",
      text:
        "The step randomizer adds a random note trig to the desired step, the pitch is quantized to the pattern. It will also randomize some synth parameter offsets. The parameter offsets being randomized are selected at random, and the value as well.",
      anim: randSingleStep,
      manualLink: "/mono/manual#randomizer-step",
    },
    {
      number: "05.",
      title: "Automation",
      text:
        "The parameter randomizer basically creates a random automation on a modulation or synth parameter. The random value is also restricted to a range set by the musician, the offsets will never exceed these values.",
      anim: randSynthParam,
      manualLink: "/mono/manual#randomizer-parameter",
    },
    {
      number: "06.",
      title: "Accent & Slide",
      text:
        "Quickly get some subtle variation of a pattern by randomizing the accents & slides !",
      anim: randAccentSlide,
      manualLink: "/mono/manual#randomizer-accent",
    },
  ],
};

class TabRandomizer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentPage: 0 };
  }

  SlideInOut(page) {
    this.setState({
      slideOut: true,
      slideIn: false,
    });
    setTimeout(() => {
      this.setState({ currentPage: page });
      this.setState({
        slideOut: false,
        slideIn: true,
      });
    }, 500);
  }

  render() {
    return (
      <>
        <div className="mainTabBarPgRand">
          <div className="txtMainTabRand">
            <div className="tabDivLineTxt" onClick={() => this.SlideInOut(0)}>
              <p
                className={
                  this.state.currentPage === 0 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[0].number}{" "}
                <span className="tabDivLineFullTxt">
                  {pageData.pages[0].title}
                </span>
              </p>
              <div
                className={
                  this.state.currentPage === 0
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
            <div className="tabDivLineTxt" onClick={() => this.SlideInOut(1)}>
              <p
                className={
                  this.state.currentPage === 1 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[1].number}{" "}
                <span className="tabDivLineFullTxt">
                  {pageData.pages[1].title}
                </span>
              </p>
              <div
                className={
                  this.state.currentPage === 1
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
            <div className="tabDivLineTxt" onClick={() => this.SlideInOut(2)}>
              <p
                className={
                  this.state.currentPage === 2 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[2].number}{" "}
                <span className="tabDivLineFullTxt">
                  {pageData.pages[2].title}
                </span>
              </p>
              <div
                className={
                  this.state.currentPage === 2
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
            <div className="tabDivLineTxt" onClick={() => this.SlideInOut(3)}>
              <p
                className={
                  this.state.currentPage === 3 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[3].number}{" "}
                <span className="tabDivLineFullTxt">
                  {pageData.pages[3].title}
                </span>
              </p>
              <div
                className={
                  this.state.currentPage === 3
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
            <div className="tabDivLineTxt" onClick={() => this.SlideInOut(4)}>
              <p
                className={
                  this.state.currentPage === 4 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[4].number}{" "}
                <span className="tabDivLineFullTxt">
                  {pageData.pages[4].title}
                </span>
              </p>
              <div
                className={
                  this.state.currentPage === 4
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
            <div className="tabDivLineTxt" onClick={() => this.SlideInOut(5)}>
              <p
                className={
                  this.state.currentPage === 5 ? "tabTxtRand1" : "tabTxtRand"
                }
              >
                {pageData.pages[5].number}{" "}
                <span className="tabDivLineFullTxt">
                  {pageData.pages[5].title}
                </span>
              </p>
              <div
                className={
                  this.state.currentPage === 5
                    ? "bottomTxtRand1"
                    : "bottomTxtRand"
                }
              ></div>
            </div>
          </div>
        </div>
        <Row className="pageRandDetail">
          <Col
            xl={6}
            lg={12}
            className={`randLeftElements ${
              this.state.slideIn ? "fadeIn" : ""
            } ${this.state.slideOut ? "fadeOut" : ""}`}
          >
            <p className="oneTxt">
              {pageData.pages[this.state.currentPage].number}
            </p>
            <div className="topDetailOnClick">
              <p className="pageRandTxtHeading">
                {pageData.pages[this.state.currentPage].title}
              </p>
              <p className="mainParaPagRand">
                {pageData.pages[this.state.currentPage].text}
              </p>
              <Link
                to={pageData.pages[this.state.currentPage].manualLink}
                className="txtLearnPageRand"
              >
                Learn more in the manual{" "}
                <img src={arrow} alt="" className="arrowImgSmall" />
              </Link>
            </div>
          </Col>
          <Col
            xl={6}
            lg={12}
            className={`randRightElements ${
              this.state.slideIn ? "slideIn" : ""
            } ${this.state.slideOut ? "slideOut" : ""}`}
          >
            <div className="randAnimContainer">
              <div className="randAnimEle">
                <Lottie
                  animationData={pageData.pages[this.state.currentPage].anim}
                  loop={true}
                />
              </div>
            </div>
          </Col>
        </Row>

        <div className="howToWorkMainTabRand">
          {/* <img src={Video1} alt="" className="video1" /> */}

          <p className="howSWorkTxt">Here's how it works</p>
          <img src={picHowWorks} alt="" className="dropDownPointer" />

          <VideoPlayer className="videorandomizer" src={video} />
        </div>

        <div className="letsMakePart">
          <img src={arrowLong} alt="" className="downArrow" />
          <p className="letTxtRandTab">Let's make a full track</p>
        </div>
      </>
    );
  }
}

export default TabRandomizer;
