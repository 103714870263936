import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import img from "../../images/CutMonoCroped.png";
import arrow from "./../../images/ArrowRightBlack.svg";
import arrowWhite from "./../../images/ArrowRightWhite.svg";
import img1 from "../../images/Facecut.png";
const Learnsecond = () => {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={6} className="learn-first-col">
            <div>
              <p className="desktop-inst">Desktop Instrument - Norand MONO</p>
              <h5 className="complex-heading">
                Complex synthesis
                <br /> made easy
              </h5>
            </div>
            <div className="learn-btn-div">
              <Link to={"/mono"} className="learn-discover-btn">
                Discover MONO
                <img src={arrow} alt="" className="ArrowFooter" />
              </Link>
              <Link to={"/mono/manual"} className="learn-manual-btn">
                Read the Manual
                <img src={arrow} alt="" className="ArrowFooter" />
              </Link>
            </div>
            <img className="cutmono-img" src={img} alt="cut" />
          </Col>
          <Col lg={6} className="learn-second-col">
            <div>
              <p className="eurorack">Eurorack Module - Norand MORPHOS</p>
              <h5 className="morphing-heading">
                3D morphing at <br /> your fingertips
              </h5>
            </div>
            <div className="morphos-btn-div">
              <Link to={"/morphos"} className="morphos-btn">
                Discover MORPHOS{" "}
                <img src={arrow} alt="" className="ArrowFooter" />
              </Link>
              <Link
                to={"/morphos/manual"}
                style={{ color: "white" }}
                className="learn-manual-btn"
              >
                Read the Manual
                <img src={arrowWhite} alt="" className="ArrowFooter" />
              </Link>
            </div>
            <img className="face-cutimg" src={img1} alt="face" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Learnsecond;
