import React from "react";
import TabMonoMK2MorphCard from "./TabMonoMK2MorphCard";
import "../TabMonoProduct/TabMonoProduct.css";

const tabData = {
  tabs: [
    {
      name: "Mono MK2",
      link: "#monohead",
    },
    {
      name: "Contextual Modulation",
      link: "#monocontextual",
    },
    {
      name: "Sequencer",
      link: "#monosequencer",
    },
    {
      name: "Randomization",
      link: "#monorandomize",
    },
    {
      name: "Sound Engine",
      link: "#monosoundengine",
    },
    {
      name: "Manual",
      link: "#",
    },
  ],
};
const price = "840";

class TabMonoMK2Product extends React.Component {
  render() {
    return (
      <>
        <TabMonoMK2MorphCard
          fixed={this.props.fixed}
          currentSection={this.props.currentSection}
          price={price}
          tabData={tabData}
          product="mono"
          shopifyLink="gid://shopify/ProductVariant/46926809563462"
        />
      </>
    );
  }
}

export default TabMonoMK2Product;
