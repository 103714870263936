import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import "./Norandmono.css";
import btnArrow from "./../../images/ArrowRightBlack.svg";
import btnArrowWhite from "./../../images/ArrowRightWhite.svg";

const Nornandmono = () => {
  return (
    <div className="norand-mono-main">
      <Container fluid className="main-container">
        <Row>
          <Col lg={6} className="first-col">
            <div>
              <p className="news-ptag">News - 04.18.2023</p>
            </div>
            <div>
              <p className="new-norand">
                New Norand{" "}
                <span>
                  MONO Firmware
                </span>
              </p>
              <p className="mono-heading">
                {" "}
                MONO <br /> OS 2.0
              </p>
            </div>
            <div className="btn-div">
                  <a target="_blank"  href="https://storage.googleapis.com/norand_public_files/mono_V2.0.0r.zip" className="update-btn">
                  {" "}
                  Download{" "}
                  <img src={btnArrow} alt="" className="imgArrow" />
                </a>
            </div>
          </Col>

          <Col lg={6} className="second-col">
            <div>
              <p className="norand-mono">Norand MONO</p>
              <p className="norand-para">
                MONO makes complex sound synthesis simple, easily enabling a
                level of creativity not yet seen in other hardware synthesizers.
              </p>
            </div>
            <div className="btn-div">
              <Link to={"/mono"} className="discover-btn">
                Discover MONO{" "}
                <img src={btnArrowWhite} alt="" className="imgArrow" />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Nornandmono;
