import React from "react";
import FooterNorand1 from "../../Components/FooterNorand/FooterNorand1";
import Header from "../../Components/Header/Header";
import TermsCondi from "../../Components/TermsCondi/TermsCondi";

const TermsConditions = () => {
  return (
    <>
      <div className="termsCondTop">
        <Header />
        <TermsCondi />
        <FooterNorand1 />
      </div>
    </>
  );
};

export default TermsConditions;
