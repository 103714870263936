import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import btnArrow from "./../../images/ArrowRightWhite.svg";
import { Link } from "react-router-dom";
import { UserContext } from "../../lib/context";
import Client from "shopify-buy";
import LoadingSpinner from "../LoadingSpinner";

class TabMonoMorphCard extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.items = [];
    for (var i = 0; i < this.props.tabData.tabs.length; i++) {
      if (i !== this.props.tabData.tabs.length - 1) {
        this.items.push(
          <Nav.Link
            key={i}
            href={this.props.tabData.tabs[i].link}
            className={`tabTxtMono ${
              this.props.currentSection === i ? "color" : ""
            } `}
          >
            {this.props.tabData.tabs[i].name}
          </Nav.Link>
        );
      } else {
        this.items.push(
          <Link
            key={i}
            className={`tabTxtMono ${
              this.props.currentSection === i ? "color" : ""
            } `}
            to={`manual`}
          >
            {this.props.tabData.tabs[i].name}
          </Link>
        );
      }
    }
  }

  orderNow = async event => {
    event.preventDefault();
    this.setState({ loading: true });
    console.log(this.country);
    this.redirectToCheckout = true;
    const client = Client.buildClient({
      storefrontAccessToken: "4afd7898ce2dc3f58b7ff3407f8d1b7b",
      domain: "norandstore.myshopify.com"
    });

    client.checkout.create().then(checkout => {
      const checkoutId = checkout.id;
      const lineItemsToAdd = [
        {
          variantId: this.props.shopifyLink,
          quantity: 1
        }
      ];

      const shippingAddress = {
        address1: "",
        address2: "",
        city: "",
        company: null,
        country: this.context.country,
        firstName: "",
        lastName: "",
        phone: "",
        province: "",
        zip: ""
      };

      client.checkout
        .updateShippingAddress(checkoutId, shippingAddress)
        .then(checkout => {});

      client.checkout
        .addLineItems(checkoutId, lineItemsToAdd)
        .then(checkout => {
          window.location.href = checkout.webUrl;
        });
    });
  };

  render() {
    return (
      <>
        {this.state.loading && <LoadingSpinner />}
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          bg="none"
          className={`tabMonoContainer ${
            this.props.fixed ? "tabMonoFixed" : ""
          }`}
          hidden={!this.props.fixed}
        >
          <Navbar.Toggle hidden aria-controls="responsivemono-nav" />
          <Navbar.Collapse id="responsivemono-nav">
            <Nav className="me-auto">{this.items}</Nav>
          </Navbar.Collapse>
          <Link to={"/join-waitlist"}>
            <Nav className="rightIconPartMono">
              {/* <button onClick={this.orderNow} className="btnOrderNowTab"> */}
              <button onClick={this.orderNow} className="btnOrderNowTab">
                Out of stock. Join waitlist
                {/* <UserContext.Consumer>
                  {priceInEur => (priceInEur ? "€" : "$")}
                </UserContext.Consumer> */}
                <img src={btnArrow} alt="" className="arrowButtonMonoProduct" />
              </button>
            </Nav>
          </Link>
        </Navbar>
      </>
    );
  }
}

export default TabMonoMorphCard;
