import React from 'react'

import '../DigitalBrainMono/DigitalBrainMono.css'
import DSC04771 from './../../images/mk2_pic-1_(1).jpg'
import DigiBrainCard from './DigiBrainCardMK2'

const DigitalBrainMonoMK2 = () => {

      const firstParaDigi="The digital brain of"
      const firstSpDigi="MONO"
      const secondParaDigi="Allows you"
      const secondSpDigi=" to build entire songs "
      const thirdParaDigi="all in the box."
  return (
    <>
           <DigiBrainCard thirdParaDigi={thirdParaDigi} secondSpDigi={secondSpDigi} secondParaDigi={secondParaDigi} DSC04771={DSC04771} firstSpDigi={firstSpDigi}firstParaDigi={firstParaDigi}/>

              
           



    </>
  )
}

export default DigitalBrainMonoMK2