import React from 'react'
import FooterNorand1 from '../../Components/FooterNorand/FooterNorand1'
import Header from '../../Components/Header/Header'
import ContactUs1 from './../../Components/ContactUs/ContactUs'
import './ContactUs.css'

const ContactUs = () => {
  return (
    <>
    <div className='contactUsTopPage'>
    <Header/>
    <ContactUs1/>
    <FooterNorand1/>
    </div>
    </>
  )
}

export default ContactUs