import React from "react";
import "./ClaudeInspiration.css";
import Waveform from "./Waveform";
import RectangleIns from "./../../images/INHALT.jpg";
import inhaltSong from "./../../audio/inhalt.mp3";

export default function ClaudeInspiration() {
  return (
    <>
      <div className="main">
        <div className="mainContainerClaudeInsTop">
          <div className="mainContainerClaudeIns">
            <div className="musicAudioTop">
              <Waveform url={inhaltSong} id="inhalt-track" />
            </div>

            <div className="txtPartClaude">
              <p className="paraTxtClauseIntro">
                INHALT
                <span className="birdRecTxt"> © 2022</span>
              </p>
            </div>
          </div>

          <div className="txtUsaClaude">
            <p className="paraUsaClaude">
              «  The Mono is a kind of 21st century bridge between the best of
              the past and the best of the now. »
            </p>
            <div className="picPartInsClaud">
              <img src={RectangleIns} alt="" className="imgClaude" />
              <p className="nameInsClaude">
                {" "}
                Matia Simovich aka INHALT
                <br />
                <span className="countryIns">USA</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
