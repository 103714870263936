import React from "react";
import VideoPlayer from "react-video-js-player";

import "../ContextualModulation/ContextualModulation.css";
import video from "./../../videos/mono_contextual_mod.mp4";

const ContextualModulation = () => {
  return (
    <div className="contextual-home-main">
      <div className="norand-context-text">
        <p className="norand-context-1">
          Discover Norand’s revolutionary interaction and modulation concept
          present in all our instruments.
        </p>

        <p className="txtContextual">CONTEXTUAL</p>
        <p className="txtModulation">MODULATION</p>
      </div>
      <VideoPlayer className="context-video-player" src={video} />
    </div>
  );
};

export default ContextualModulation;
