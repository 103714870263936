import React from "react";
import DigiBrainCard from "../DigitalBrainMono/DigiBrainCard";
import DSC04772 from './../../images/DSC04772.png'

const SaveSpaceMorhpos = () => {

  
  const firstParaDigi = "Save space in your rack with our ";
  const firstSpDigi = "Contextual Modulation system";
  const secondParaDigi = " and MORPHOS’ ";
  const secondSpDigi = "internal modulation";
  const thirdParaDigi = " sources.";
  return (
    <>
      <DigiBrainCard
        thirdParaDigi={thirdParaDigi}
        secondSpDigi={secondSpDigi}
        secondParaDigi={secondParaDigi}
        DSC04771={DSC04772}
        firstSpDigi={firstSpDigi}
        firstParaDigi={firstParaDigi}
      />
    </>
  );
};

export default SaveSpaceMorhpos;
