import React from "react";
import btnArrow from "./../../images/ArrowRightWhite.svg";
import picCut from "./../../images/MK2_front.png";
import "../NorandMonoProduct/NorandMonoProduct.css";

import NorandMonoMK2ProductCard from "./NorandMonoMK2ProductCard";
const NorandMonoMK2Product = () => {
  const mainText = "Hundreds of features, limitless creativity";
  const NORANDTxt = "";
  const MONO = "MONO";
  const MK2 = "MK2";
  const btnText = "Order now, Ship within a week - 840";
  return (
    <>
      <NorandMonoMK2ProductCard
        picCut={picCut}
        buttArrowPic={btnArrow}
        mainText={mainText}
        NORANDTxt={NORANDTxt}
        MONO={MONO}
        MK2={MK2}
        btnText={btnText}
      />
    </>
  );
};

export default NorandMonoMK2Product;
