import React from "react";
import IconArrowVco from "./../../images/ArrowBottomBlack.svg";
import firstPicM from "./../../images/Face_cut_2.png";
import "./MorphosVCO.css";
import arrowUp from "./../../images/ArrowBottomBlackSmall.svg";
import touchHereCircle from "./../../images/orangePointer.svg";
import circledLine1 from "./../../images/circledLine1.svg";
import circledLineLong from "./../../images/circledLineLong.svg";
import lineBottomCircled from "./../../images/circledLineBottom.svg";
const MorphosVCO = () => {
  return (
    <>
      <div className="mainContainerVco">
        {/* <img src={SummaryVco} alt="" className="morhposVCOPic" /> */}

        <div className="mainPicVCO">
          <div className="firstRowVcoTxtUp">
            <div className="firstRowLeftTxt">
              <p className="d3TxtVcoUp">
                3D <span className="d3TxtVcoUpSp">Morphing</span>
              </p>
              <a href="#morphostdmorphing" className="disTxtVco">
                Discover <img src={arrowUp} alt="" className="arrowUp" />
              </a>
            </div>
            <div className="tzfmAnalogOsciTop">
              <p className="d3TxtVcoUp">
                TZFM Analog <span className="d3TxtVcoUpSp">Oscillators</span>
              </p>
              <a href="#morphososcillators" className="disTxtVco">
                Discover <img src={arrowUp} alt="" className="arrowUp" />
              </a>
            </div>
          </div>
          <div className="imgTopMorPRoMain">
            <img src={firstPicM} alt="" className="firstPicM" />

            <div className="topPeepCircleIconVco">
              <img src={touchHereCircle} alt="" className="touchHereCircle" />
              <img src={circledLine1} alt="" className="circledLine1" />
            </div>
            <div className="topPeepCircleIconVco1">
              <img src={touchHereCircle} alt="" className="touchHereCircle1" />
              <img src={circledLineLong} alt="" className="circledLine11" />
            </div>
            <div className="topPeepCircleIconVco2">
              <img src={touchHereCircle} alt="" className="touchHereCircle2" />
              <img src={lineBottomCircled} alt="" className="circledLine2" />
            </div>
          </div>

          <div className="contextModTop">
            <p className="d3TxtVcoUp">
              Contextual <span className="d3TxtVcoUpSp"> Modulation</span>
            </p>
            <a href="#morphoscontextual" className="disTxtVco">
              Discover <img src={arrowUp} alt="" className="arrowUp" />
            </a>
          </div>
        </div>

        <p className="mainParaVco">
          <span className="spVcoMainPara">MORPHOS</span> is the first complex
          VCO with <span className="spVcoMainPara">3D morphing</span> touch
          strips,
          <span className="spVcoMainPara"> three modulation types </span>and the
          most stable
          <span className="spVcoMainPara"> Through Zero FM</span> yet developed.
        </p>

        <img src={IconArrowVco} alt="" className="dropArrowVco" />
      </div>
    </>
  );
};

export default MorphosVCO;
