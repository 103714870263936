import React from 'react'
import PrivacyPol from '../../Components/PrivacyPol/PrivacyPol'
import Header from '../../Components/Header/Header'
import FooterNorand1 from '../../Components/FooterNorand/FooterNorand1'

const PrivacyPolicy = () => {
  return (
    <>
    <div className='topPrivacyPol'>
    <Header/>
    <PrivacyPol/>
    <FooterNorand1/>
    </div>
    
    </>
  )
}

export default PrivacyPolicy