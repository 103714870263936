import React from "react";
import FooterNorand1 from "../../Components/FooterNorand/FooterNorand1";
import Header from "../../Components/Header/Header";
import "../../Components/Norandlearn/Learn.css";
import Learnmain from "../../Components/Norandlearn/Learnmain";
import Learnsecond from "../../Components/Norandlearn/Learnsecond";
import LearnYoutube from "../../Components/LearnYoutube/LearnYoutube";

const Norandlearn = () => {
  return (
    <div>
      <div backdrop-trigger="true"></div>
      <Header />
      <Learnmain />
      <LearnYoutube />
      <Learnsecond />
      <FooterNorand1 />
    </div>
  );
};

export default Norandlearn;
