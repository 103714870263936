import React from 'react'

import './DigitalBrainMono.css'
import DSC04771 from './../../images/DSC04771.png'
import DigiBrainCard from './DigiBrainCard'

const DigitalBrainMono = () => {

      const firstParaDigi="The digital brain of"
      const firstSpDigi="MONO"
      const secondParaDigi="Allows you"
      const secondSpDigi=" to build entire songs "
      const thirdParaDigi="all in the box."
  return (
    <>
           <DigiBrainCard thirdParaDigi={thirdParaDigi} secondSpDigi={secondSpDigi} secondParaDigi={secondParaDigi} DSC04771={DSC04771} firstSpDigi={firstSpDigi}firstParaDigi={firstParaDigi}/>

              
           



    </>
  )
}

export default DigitalBrainMono