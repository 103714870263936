import React from 'react'
import '../CaptueringTheTactic/CaptueringTheTactic.css'

import DSC04888 from './../../images/mk2_pic-3.jpg'
import CapturingTheTacticCardMK2 from './CapturingTheTacticCardMK2'

const CaptueringTheTacticMK2 = () => {
    const firstPara="Capturing the"
    const firstSp="tactile and audio feeling "
    const secondPara=" of vintage synths, providing the"
    const secondSp="technology of the future."


  return (
    <>
  <CapturingTheTacticCardMK2 DSC04888={DSC04888} firstPara={firstPara} firstSp={firstSp} 
  secondSp={secondSp}
  secondPara={secondPara}/>
    
    </>
  )
}

export default CaptueringTheTacticMK2