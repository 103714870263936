import React from "react";
import { UserContext } from "../../lib/context";
import Client from "shopify-buy";
import LoadingSpinner from "../LoadingSpinner";
import { Link } from "react-router-dom";

import picFace from "./../../images/Face_cut_2.png";
import UnionM from "./../../images/ArrowRightWhite.svg";
import "./MorphosProduct3DMorphing.css";
import picNew from "./../../images/NEW.svg";

class MorphosProduct3DMorphing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  orderNow = async event => {
    event.preventDefault();
    this.setState({ loading: true });
    console.log(this.country);
    this.redirectToCheckout = true;
    const client = Client.buildClient({
      storefrontAccessToken: "4afd7898ce2dc3f58b7ff3407f8d1b7b",
      domain: "norandstore.myshopify.com"
    });

    client.checkout.create().then(checkout => {
      const checkoutId = checkout.id;
      const lineItemsToAdd = [
        {
          variantId:
            "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MjIwODI4OTcxODQyNw==",
          quantity: 1
        }
      ];

      const shippingAddress = {
        address1: "",
        address2: "",
        city: "",
        company: null,
        country: this.context.country,
        firstName: "",
        lastName: "",
        phone: "",
        province: "",
        zip: ""
      };

      client.checkout
        .updateShippingAddress(checkoutId, shippingAddress)
        .then(checkout => {});

      client.checkout
        .addLineItems(checkoutId, lineItemsToAdd)
        .then(checkout => {
          window.location.href = checkout.webUrl;
        });
    });
  };

  render() {
    return (
      <>
        {this.state.loading && <LoadingSpinner />}
        <div className="topMoPro3dMorphin">
          <div className="backdrop-blur">
            <div className="anim-bg">
              <div className="anim-form-1"></div>
              <div className="anim-form-2"></div>
              <div className="anim-form-3"></div>
              <div className="anim-form-4"></div>
              <div className="anim-form-5"></div>
              <div className="anim-form-6"></div>
              <div className="anim-form-7"></div>
            </div>
          </div>
          <div className="creativityMonoContainer">
            <div className="mainCreativity">
              <p className="p1Creativity">3D morphing at your fingertips</p>
              <img src={picNew} alt="" className="newPicMorphos" />
              <p className="headerCreativityNorand">
                NORAND
                <span className="headerCreativityMorphos">Morphos </span>
              </p>

              <img src={picFace} alt="" className="cutMorphosImg" />

              {/* <button onClick={this.orderNow} className="btnOrderNowMorphos"> */}
              <Link to={"/join-waitlist"}>
                <button className="btnOrderNowMorphos">
                  Out of stock. Join Waitlist
                  {/* <UserContext.Consumer>
                    {priceInEur => (priceInEur ? "€" : "$")}
                  </UserContext.Consumer> */}
                  <img src={UnionM} alt="" className="arrowButtonMonoProduct" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MorphosProduct3DMorphing;
