import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Learnmain = () => {
  return (
    <div className="main-bg">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <h1 className="discover-heading">
              {" "}
              DISCOVER OUR <br />
              <span className="discover-heading-bold">INSTRUMENTS</span>
            </h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Learnmain;
