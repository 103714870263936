import React from "react";
import LegalInformation from "../../Components/LegalInformation/LegalInformation";
import Header from "../../Components/Header/Header";
import FooterNorand1 from "../../Components/FooterNorand/FooterNorand1";

const LegalInformationPage = () => {
  return (
    <>
      <div className="topPrivacyPol">
        <Header />
        <LegalInformation />
        <FooterNorand1 />
      </div>
    </>
  );
};

export default LegalInformationPage;
