import React from 'react'
import './CaptueringTheTactic.css'

import DSC04888 from './../../images/DSC04888.png'
import CapturingTheTacticCard from './CapturingTheTacticCard'

const CaptueringTheTactic = () => {
    const firstPara="Capturing the"
    const firstSp="tactile and audio feeling "
    const secondPara=" of vintage synths, providing the"
    const secondSp="technology of the future."


  return (
    <>
  <CapturingTheTacticCard DSC04888={DSC04888} firstPara={firstPara} firstSp={firstSp} 
  secondSp={secondSp}
  secondPara={secondPara}/>
    
    </>
  )
}

export default CaptueringTheTactic