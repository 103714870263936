import React from "react";
import "./TermsCondi.css";

const TermsCondi = () => {
  return (
    <>
      <div className="mainContainerPrivacyPolicy">
        <div className="containerPrivacyForWidth">
          <p className="privacyMainHeader">Terms and conditions of sale</p>
          <p className="paraTxtPrivacyPol">
            These terms and conditions of sale apply to the sale of our products
            on our website, subject to the rights granted by national
            legislation on consumer protection.
          </p>
          <p className="paraTxtPrivacyPol">
            By ticking the box "I accept these general terms and conditions of
            sale" when you order a product on our website, you confirm that you
            have read and understood these general terms and conditions of sale
            and that you agree to be bound by them.
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 1. Order</p>
          <p className="paraTxtPrivacyPol">
            You can order online all the products that are for sale on our
            website, subject to availability.
          </p>
          <p className="paraTxtPrivacyPol">
            If you place an order you must accept these terms and conditions of
            sale, indicate your delivery address and choose how you wish the
            products to be delivered and your payment method.
          </p>

          <p className="paraTxtPrivacyPol">
            Once the order has been accepted, you will receive an email
            confirmation for your order from Norand.
          </p>
          <p className="paraTxtPrivacyPol">
            The sale will only be final once the order has been confirmed by
            email by Norand.
          </p>
          <p className="paraTxtPrivacyPol"> 
            Only the email confirmation from Norand will be accepted as proof of
            the content of the order. Norand reserves the right to suspend,
            refuse or cancel your order in certain cases, and in particular in
            the case of an incorrect address, incorrect payment details or
            failure to pay.
          </p>

          <p className="paraTxtPrivacyPol">
            If you wish to track your order, you may contact Norand at the
            following link https://www.norand.io/contact for information.
          </p>

          <p className="subHeadingPrivacyPolicy">ARTICLE 2: Price</p>

          <p className="paraTxtPrivacyPol">
            The applicable prices are those indicated at the time of the
            confirmation of the order. Norand reserves the right to change its
            prices at any time.
          </p>
          <p className="paraTxtPrivacyPol">
            Prices are indicated in euros or dollars depending on the country
            where the consumer resides and do not include delivery costs. The
            delivery costs are only added to the price when the order is
            confirmed.
          </p>
          <p className="paraTxtPrivacyPol">Prices are payable in full when the order is confirmed.</p>
          <p className="paraTxtPrivacyPol">
            Norand offers a range of payment means to choose from including:
            Credit card, Visa, Mastercard, debit or credit.
          </p>
          <p className="paraTxtPrivacyPol">
            The seller reserves the right to refuse an order if your bank
            refuses payment.
          </p>
          <p className="paraTxtPrivacyPol">
            A 3D secure verification procedure is used by Norand to ensure that
            payments are secure.
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 3: Right of withdrawal</p>
          <p className="paraTxtPrivacyPol">
            If you are a consumer, you are entitled, for contracts which are
            entered into on line, to a right of withdrawal which you may
            exercise within a period of fourteen (14) days as of the date when
            you yourself or a third party other than the carrier which was
            appointed by you, takes physical possession of the goods.
          </p>
          <p className="paraTxtPrivacyPol">
            As such, you may, within this period return any unsuitable article,
            without giving a reason, and request a refund without any penalty,
            save for the costs of returning the article which remain your
            responsibility.
          </p>
          <p className="paraTxtPrivacyPol">
            Only products which are in their original state and packaging, along
            with all the accessories and documents which were initially included
            when they were packed as well as the purchase invoice for them, may
            be returned as part of the right of withdrawal.
          </p>
          <p className="paraTxtPrivacyPol">
            The right of withdrawal may not be exercised for the supply of audio
            or video recordings or computer software once you have unsealed them
            after delivery.
          </p>
          <p className="paraTxtPrivacyPol">
            The right of withdrawal does not apply to sales of digital content
            which are not provided in a physical format which was activated
            after your express prior agreement and express waiver of your right
            of withdrawal.
          </p>
          <p className="paraTxtPrivacyPol">
            To exercise your right of withdrawal, you must inform Norand at 10
            rue du Moulin 92170 Vanves (email: contact@norand.io ; Tel: 01 41 08
            02 10) of your decision to withdraw from the contract by means of an
            unambiguous notification (for example, letter sent by post, fax or
            e-mail). You may use the withdrawal form below but this is not
            compulsory. The form must be sent to the following address: 10 rue
            du Moulin 92170 Vanves or by email at the following link
            https://www.norand.io/contact
          </p>
          <p className="paraTxtPrivacyPol">
            In the event of withdrawal, Norand will refund you the price of the
            product(s) purchased as well as the delivery costs for the original
            purchase, with the exception of the additional costs resulting from
            your choice, as the case may be, of a means of delivery other than
            the least expensive standard delivery means provided by us. We will
            refund you using the same payment means as the one you used for the
            initial transaction, unless you expressly agree to a different
            means; in any case the refund will not generate any costs for you.
          </p>
          <p className="paraTxtPrivacyPol">
            You must send back or return the item to ourselves without any undue
            delay and, in any event, no later than fourteen (14) days after you
            informed us of your decision to withdraw from this contract. This
            deadline is deemed to have been complied with if you return the
            goods before the expiry of the deadline of fourteen (14) days.
          </p>
          <p className="paraTxtPrivacyPol">
            You will be responsible for the direct costs of returning the goods.
          </p>
          <p className="paraTxtPrivacyPol">
            You may only be held liable with respect to the depreciation of the
            goods if they are handled in a way which is different from what is
            required for establishing the nature, characteristics and the proper
            working order of the goods.
          </p>
          <p className="subHeadingPrivacyPolicy">WITHDRAWAL FORM</p>
          <p className="paraTxtPrivacyPol">
            (Please complete and return this form only if you wish to withdraw
            from the contract.) For the attention of Norand, 10 rue du Moulin
            92170 Vanves (https://www.norand.io/contact):
          </p>
          <p className="paraTxtPrivacyPol">
            I/we (*) hereby inform you (*) of my / our (*) withdrawal from the
            contract concerning the sale of the item (*) /for the provision of
            services (*) below:
          </p>
          <p className="paraTxtPrivacyPol">Ordered on the (*) / Received on the (*):</p>
          <p className="paraTxtPrivacyPol">Name of the consumer(s):</p>
          <p className="paraTxtPrivacyPol">Address of the consumer(s):</p>
          <p className="paraTxtPrivacyPol">
            Signature of (the) consumer(s) (only in the case of notification
            using this paper form):
          </p>
          <p className="paraTxtPrivacyPol">Date:</p>
          <p className="paraTxtPrivacyPol">(*) Delete as applicable.</p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 4: Delivery</p>
          <p>
            Norand will deliver the products on the date indicated at time of
            the order.
          </p>
          <p className="paraTxtPrivacyPol">
            The products will be delivered by a carrier provided by Norand
            depending on the country.
          </p>
          <p className="paraTxtPrivacyPol">
            The delivery will be made to the delivery address that you indicated
            when the order was registered. Products which have been returned to
            Norand due to inaccurate or wrong delivery address will be reshipped
            at your expense.
          </p>
          <p className="paraTxtPrivacyPol">
            In the event of late delivery or it being impossible to deliver the
            products ordered, you have the option of terminating the contract,
            if after having directed Norand to make the delivery of the
            product(s) within a further reasonable timeframe, we were not able
            to do so within that timeframe.
          </p>
          <p className="paraTxtPrivacyPol">
            The transfer of ownership will occur when the product is handed over
            to the buyer. Norand will be responsible for the carriage of the
            products.
          </p>
          <p className="paraTxtPrivacyPol">
            In the event of an exchange, if you decide to return the product by
            means of a different carrier to that provided by Norand, you will be
            responsible for the carriage of the products.
          </p>
          <p className="paraTxtPrivacyPol">
            If at the time of delivery, the original packaging is damaged or
            opened, you must check the condition of the products. If the
            products have been damaged, the buyer must refuse the package and
            indicate a reserve on the delivery note. You must indicate on the
            delivery slip and in the form of reserves accompanied by your
            signature any abnormality concerning the delivery (missing or
            damaged product).
          </p>
          <p className="paraTxtPrivacyPol">
            With regard to the delivery of software items, these are considered
            as having been delivered when they are downloaded from our website.
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 5: Guarantee</p>
          <p className="paraTxtPrivacyPol">
            Products sold by Norand, which is a company with its registered
            office located at 10 rue du Moulin 92170 Vanves, are subject to a
            sales guarantee of two (2) years as of the delivery of the product
            which is valid worldwide. This guarantee covers non-conformity
            defects in respect of the contractual specifications as well as
            visible and hidden defects which may encumber the product and render
            it unfit for use.
          </p>
          <p className="paraTxtPrivacyPol">
            In the event of a non-conformity, or of visible and hidden defects,
            you have two (2) years as of the delivery of the product to make a
            claim by completing the online form which is available at this link
            https://www.norand.io/contact
          </p>
          <p className="paraTxtPrivacyPol">
            Norand’s after-sales service will repair or exchange products and it
            can be contacted by completing the online form available via this
            link https://www.norand.io/contact once the alleged defect has been
            recorded and provided that:
          </p>
          <p className="paraTxtPrivacyPol">
            -reasonable care has been taken when using the products, and that
            they were only subject to normal wear and tear;
          </p>
          <p className="paraTxtPrivacyPol">
            -the failure was not caused or worsened by damage inflicted
            deliberately or through negligence, or as a result of an accident
            while the products were under your control, nor by third-party
            software, which was not provided by Norand and which was connected
            to the product.
          </p>
          <p className="paraTxtPrivacyPol">
            For exchanges or repairs, the costs for returns will be paid for by
            Norand.
          </p>
          <p className="paraTxtPrivacyPol">
            If Norand is unable to repair or exchange the products, you have the
            option of requesting the termination of the contract or of requiring
            a reduction in the sales price of the products.
          </p>
          <p className="paraTxtPrivacyPol">
            This guarantee is subject to the rights granted by national
            legislation on consumer protection. Norand remains legally bound by
            the legal guarantee on compliance provided by your national
            legislation.
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 6: Force Majeure</p>
          <p>
            In the event of a case of force majeure, caused by a third party to
            the contract which is unpredictable and insurmountable or due to the
            wrongful conduct of the buyer, Norand reserves the right to suspend
            the implementation of this contract, or to terminate it, without any
            damages or interest being claimable.
          </p>
          <p className="paraTxtPrivacyPol">
            The following constitute force majeure - any circumstances or acts
            which are unpreventable, unforeseeable and external to the parties
            and which prevent the fulfilment of the contract under normal
            conditions. Fires, blocking of transportation or supplies, storms,
            stoppages of telecommunications networks are in particular
            considered as cases of force majeure.
          </p>
          <p className="paraTxtPrivacyPol">
            In this case, you must contact Norand to find out if we will be able
            to deliver the product within a reasonable timeframe or if the
            contract will be terminated. In the absence of a reply from us
            within one month, you may terminate the contract.
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 7: Communication and conservation of the contract</p>
          <p className="paraTxtPrivacyPol">
            Norand will provide you, if you so request, with a copy of the
            contract which records the sale of the product. Norand will conserve
            this contract for a period of ten (10) years as of the delivery of
            the goods and guarantees the buyer access to this contract at any
            time.
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 8: Intellectual property</p>
          <p className="paraTxtPrivacyPol">
            The content of the site is the property of Norand, which is the sole
            owner of the intellectual property rights over its content.You
            undertake not to reproduce or use any part of this content.
          </p>
          <p className="subHeadingPrivacyPolicy"> ARTICLE 9: Personal data</p>
          <p className="paraTxtPrivacyPol">
            The conditions governing the processing and collection of your
            personal data are shown in our confidentiality policy which is
            available at the following link.
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 10: Divisibility</p>
          <p className="paraTxtPrivacyPol">
            Should any stipulation of these general terms and conditions of sale
            be cancelled in whole or in part, the validity of the other
            provisions shall not be affected.
          </p>
          <p className="subHeadingPrivacyPolicy"> ARTICLE 11: Mediation</p>
          <p className="paraTxtPrivacyPol">
            In the event of a dispute, you may apply to a consumer mediator for
            the purposes of reaching an out of court settlement of the dispute
            in accordance with the conditions stipulated under part I of Book VI
            of the Consumer Code or you may seek any alternative method of
            dispute resolution.
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 12: Applicable law</p>
          <p className="paraTxtPrivacyPol">
            These general terms and conditions of sale are governed by French
            law, except for the provisions of the United Nations Convention on
            contracts for the international sale of goods.
          </p>
          <p className="privacyMainHeader">
            Norand Mono product reservation <br />
            terms & conditions
          </p>
          <p className="paraTxtPrivacyPol">
            1. By making a reservation, you now have a place on the Mono waiting
            list. This means that you will be eligible for the purchase of a
            Mono from Norand SAS, in accordance with your place on the waiting
            list.
          </p>
          <p className="paraTxtPrivacyPol">
            2. Your place on the waiting list is subject to the completion of
            your reservation at https://www.norand.io/pre-order and payment of
            the applicable reservation fee in your country or region
            (“Reservation Fee”). You can find details of the applicable
            registration fee at https://www.norand.io/pre-order.
          </p>
          <p className="paraTxtPrivacyPol">
            3. Neither your reservation, placement on the waiting list, nor the
            payment of the Reservation Fee constitutes any legally binding offer
            or agreement between you and Norand. In particular, no (offer for
            the) sale agreement with regard to any Norand product shall commence
            before the agreed date. Until a definitive sale is concluded between
            you and Norand, you may:
          </p>
          <p className="paraTxtPrivacyPol">
            a) Cancel your reservation at any time by contacting Norand via
            https://www.norand.io/contact. In this case, Norand will refund your
            Reservation Fee within ten (10) business days.
          </p>
          <p className="paraTxtPrivacyPol">
            b) Norand is in no way obliged to sell you a product, nor shall
            Norand have any other obligation towards you that is not
            specifically mentioned in this reservation agreement.
          </p>
          <p className="paraTxtPrivacyPol">
            4. Assuming you do not cancel your reservation (pursuant to clause
            3) by the time the Mono you have reserved becomes available, Norand
            will offer to enter into a sale agreement with you. Upon your
            acceptance of the agreement, your Reservation Fee will be offset
            against the purchase price of your Mono.
          </p>
          <p className="paraTxtPrivacyPol">
            5. Norand is in no way obliged to pay interest on the Reservation
            Fee.
          </p>
          <p className="paraTxtPrivacyPol">
            6. Your reservation and (your entitlement to) return of your
            Reservation Fee are personal and can not be encumbered or
            transferred to any third party without the prior written consent of
            Norand.
          </p>
          <p className="paraTxtPrivacyPol">
            7. Mono product has an estimated delivery date on the reservation
            page. This date is determined by Norand SAS as an estimate as of
            when backers will receive the product.
          </p>
          <p className="paraTxtPrivacyPol">Estimated delivery dates may change, without prior notice.</p>
          <p className="paraTxtPrivacyPol">
            Customers can make enquiries regarding estimated delivery notices by
            contacting Norand SAS https://www.norand.io/contact.
          </p>
          <p className="paraTxtPrivacyPol">
            8. The terms and conditions of this reservation agreement shall be
            governed by French law.
          </p>
          <p className="privacyMainHeader">Conditions générales de vente</p>
          <p>
            Les présentes conditions générales de vente s’appliquent à la vente
            de nos produits sur notre site Internet, sous réserve des droits
            conférés par les législations nationales en matière de protection
            des consommateurs. En cochant la case « j’accepte les présentes
            conditions générales de vente » lorsque vous commandez un produit
            sur notre site, vous confirmez que vous avez lu et compris les
            présentes conditions générales de vente et que vous acceptez être
            lié par elles.
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 1. Commande</p>
          <p className="paraTxtPrivacyPol">
            Vous pouvez commander en ligne tous les produits en vente sur notre
            site, dans la limite des stocks disponibles.
          </p>
          <p className="paraTxtPrivacyPol">
            Lorsque vous passez une commande vous devez accepter les présentes
            conditions générales de vente, indiquer votre adresse de livraison
            et choisir votre mode de livraison et votre mode de paiement.
          </p>
          <p className="paraTxtPrivacyPol">
            Après acceptation de la commande, vous recevrez un mail de
            confirmation de la commande par Norand.
          </p>
          <p className="paraTxtPrivacyPol">
            La vente ne sera définitive qu’à compter de la confirmation de la
            commande par mail par Norand.
          </p>
          <p className="paraTxtPrivacyPol">
            Seul le mail de confirmation de Norand fait foi pour le contenu de
            la commande.
          </p>
          <p className="paraTxtPrivacyPol">
            Norand se réserve le droit de suspendre refuser ou annuler votre
            commande dans certains cas, et notamment en cas d’adresse erronée,
            de données de paiement erronées ou de défaut de paiement.
          </p>
          <p className="paraTxtPrivacyPol">
            Concernant le suivi de la commande, vous pouvez contacter Norand sur
            le lien https://www.norand.io/contact pour obtenir des informations.
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 2. Prix</p>
          <p className="paraTxtPrivacyPol">
            Les prix applicables sont ceux indiqués au moment de la validation
            de la commande. Norand se réserve le droit de modifier ses prix à
            tout moment.
          </p>
          <p className="paraTxtPrivacyPol">
            Les prix sont indiqués en euros ou en dollars en fonction du pays où
            le consommateur réside et n’incluent pas les frais de livraison. Ces
            derniers ne sont pris en compte dans le prix de la commande qu’au
            moment de la validation de cette dernière.
          </p>
          <p className="paraTxtPrivacyPol">
            Les prix sont payables au comptant en totalité à la validation de la
            commande.
          </p>
          <p className="paraTxtPrivacyPol">
            Vous avez la possibilité de choisir votre mode de paiement, parmi
            ceux proposés par Norand qui sont : Carte bleue, Visa, Mastercard,
            Paypal débit ou crédit. Le vendeur se réserve le droit de refuser
            une commande en cas de refus de paiement émis par votre organisme
            bancaire.
          </p>
          <p className="paraTxtPrivacyPol">
            Une procédure de vérification 3D secure a été mise en place par
            Norand pour assurer la sécurité des paiements.
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 3. Droit de rétractation</p>
          <p className="paraTxtPrivacyPol">
            Si vous êtes consommateur, vous bénéficiez, pour les contrats
            conclus à distance, d’un droit de rétractation que vous pouvez
            exercer dans un délai de quatorze (14) jours à compter de la date où
            vous-même, ou un tiers autre que le transporteur et désigné par
            vous, prend physiquement possession du bien.
          </p>
          <p className="paraTxtPrivacyPol">
            Vous disposez à ce titre de ce délai pour retourner tout article ne
            vous convenant pas, sans donner de motif, et demander le
            remboursement sans pénalité, à l’exception des frais de retour qui
            restent à votre charge.
          </p>
          <p className="paraTxtPrivacyPol">
            Seuls les produits dans leur état et emballage d’origine,
            accompagnés de tous les accessoires et documents qui figuraient
            initialement dans leur emballage ainsi que de leur facture d’achat,
            pourront être retournés au titre de l’exercice du droit de
            rétractation.
          </p>
          <p className="paraTxtPrivacyPol">
            Le droit de rétractation ne pourra pas être exercé pour la
            fourniture d'enregistrements audio ou vidéo ou de logiciels
            informatiques lorsque vous les avez descellés après la livraison.
          </p>
          <p className="paraTxtPrivacyPol">
            Le droit de rétractation ne pourra pas être exercé pour la
            fourniture d’un contenu numérique non fourni sur un support matériel
            dont l'exécution a commencé après votre accord préalable exprès et
            renoncement exprès à votre droit de rétractation.
          </p>
          <p className="paraTxtPrivacyPol">
            Pour exercer le droit de rétractation, vous devez notifier à Norand,
            10 rue du Moulin 92170 Vanves (lien email contact :
            https://www.norand.io/contact) votre décision de rétractation du
            contrat au moyen d'une déclaration dénuée d'ambiguïté (par exemple,
            lettre envoyée par la poste, télécopie ou courrier électronique).
            Vous pouvez utiliser le modèle de formulaire de rétractation
            ci-dessous mais ce n'est pas obligatoire. Le formulaire doit être
            envoyé à l’adresse suivante : 10 rue du Moulin 92170 Vanves ou par
            mail sur le lien https://www.norand.io/contact
          </p>
          <p className="paraTxtPrivacyPol">
            En cas de rétractation, Norand vous remboursera le prix du ou des
            produit(s) acheté(s) ainsi que les frais de livraison lors de
            l’achat initial, à l'exception des frais supplémentaires découlant
            du fait que vous avez choisi, le cas échéant, un mode de livraison
            autre que le mode moins coûteux de livraison standard proposé par
            nous. Nous procéderons au remboursement en utilisant le même moyen
            de paiement que celui que vous aurez utilisé pour la transaction
            initiale, sauf si vous convenez expressément d'un moyen différent ;
            en tout état de cause, ce remboursement n'occasionnera pas de frais
            pour vous.
          </p>
          <p className="paraTxtPrivacyPol">
            Vous devrez renvoyer ou rendre le bien, à nous-mêmes sans retard
            excessif et, en tout état de cause, au plus tard quatorze (14) jours
            après que vous nous aurez communiqué votre décision de rétractation
            du présent contrat. Ce délai est réputé respecté si vous renvoyez le
            bien avant l'expiration du délai de quatorze (14) jours.
          </p>
          <p className="paraTxtPrivacyPol">
            Vous devrez prendre en charge les frais directs de renvoi du bien.
          </p>
          <p className="paraTxtPrivacyPol">
            Votre responsabilité n'est engagée qu'à l'égard de la dépréciation
            du bien résultant de manipulations autres que celles nécessaires
            pour établir la nature, les caractéristiques et le bon
            fonctionnement de ce bien.
          </p>
          <p className="subHeadingPrivacyPolicy">
            MODÈLE DE FORMULAIRE DE RÉTRACTATION (Veuillez compléter et renvoyer
            le présent formulaire uniquement si vous souhaitez vous rétracter du
            contrat.)
          </p>
          <p className="paraTxtPrivacyPol">
            A l'attention de Norand, 10 rue du Moulin 92170 Vanves (email :
            contact@norand.io) :
          </p>
          <p className="paraTxtPrivacyPol">
            Je/nous (*) vous notifie/notifions (*) par la présente ma/notre (*)
            rétractation du contrat portant sur la vente du bien (*)/pour la
            prestation de services (*) ci-dessous :
          </p>
          <p className="paraTxtPrivacyPol">Commandé le (*)/reçu le (*) :</p>
          <p className="paraTxtPrivacyPol">Commandé le (*)/reçu le (*) :</p>
          <p className="paraTxtPrivacyPol">Adresse du (des) consommateur(s) :</p>
          <p className="paraTxtPrivacyPol">
            Signature du (des) consommateur(s) (uniquement en cas de
            notification du présent formulaire sur papier) :
          </p>
          <p className="paraTxtPrivacyPol">Date :</p>
          <p className="paraTxtPrivacyPol">(*) Rayez la mention inutile.</p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 4. Livraison</p>
          <p className="paraTxtPrivacyPol">
            Norand livre les produits à la date indiquée au moment de la
            commande.
          </p>
          <p className="paraTxtPrivacyPol">
            Les produits seront livrés par un transporteur proposé par Norand en
            fonction des pays.
          </p>
          <p className="paraTxtPrivacyPol">
            La livraison est effectuée à l’adresse de livraison que vous avez
            indiquée lors de l’enregistrement de la commande. Les produits
            renvoyés à Norand pour cause d’adresse de livraison inexacte ou
            erronée seront réexpédiés à vos frais.
          </p>
          <p className="paraTxtPrivacyPol">
            En cas de retard de livraison ou d’impossibilité de livrer les
            produits commandés, vous avez la possibilité de résoudre le contrat,
            si après avoir enjoint Norand d’effectuer la livraison du ou des
            produit(s) dans un délai supplémentaire raisonnable nous ne nous
            sommes pas exécutés dans ce délai.
          </p>
          <p className="paraTxtPrivacyPol">
            Le transfert de propriété interviendra à la remise du produit à
            l’acheteur. Les produits voyagent aux risques d’Norand.
          </p>
          <p className="paraTxtPrivacyPol">
            En cas d’échange, si vous décidez de retourner le produit à un autre
            transporteur que celui proposé par Norand, les produits voyageront à
            vos risques. Si au moment de la livraison, l’emballage d’origine est
            abîmé ou ouvert, vous devez vérifier l‘état des produits. Si les
            produits ont été endommagés, l’acheteur doit impérativement refuser
            le colis et noter une réserve sur le bordereau de livraison. Vous
            devez indiquer sur le bon de livraison et sous forme de réserves
            accompagnées de votre signature toute anomalie concernant la
            livraison (produit manquant, endommagé).
          </p>
          <p className="paraTxtPrivacyPol">
            S’agissant de la livraison des logiciels, ceux-ci seront livrés lors
            de leur téléchargement sur notre site Internet.
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 5. Garantie</p>
          <p className="paraTxtPrivacyPol">
            Les produits vendus par Norand, société dont le siège social se
            situe au 10 rue du Moulin 92170 VANVES, sont soumis à une garantie
            commerciale d’un (1) an à compter de la livraison du produit et
            valable dans le monde entier. Cette garantie couvre les défauts de
            conformité aux spécifications contractuelles ainsi que les vices
            apparents et vices cachés grevant le produit et le rendant impropre
            à son usage.
          </p>
          <p className="paraTxtPrivacyPol">
            En cas de défaut de conformité, de vice apparent et de vice caché,
            vous pourrez adresser toute réclamation dans un délai d’un (1) an à
            compter de la livraison du produit nous contactant sur le lien
            https://www.norand.io/contact
          </p>
          <p className="paraTxtPrivacyPol">
            Norand assurera la réparation ou l’échange des produits par le biais
            de notre service après-vente qui peut être contacté en complétant le
            formulaire en ligne disponible sur ce lien
            https://www.norand.io/contact après avoir constaté le défaut allégué
            et à condition que :
          </p>
          <p className="paraTxtPrivacyPol">
            - un soin raisonnable ait été apporté aux produits, et qu’ils
            n’aient été soumis qu’à une usure normale ;
          </p>
          <p className="paraTxtPrivacyPol">
            - la défaillance n’ait pas été causée ou aggravée par des dommages
            apportés délibérément ou par négligence, ni par un accident alors
            que les produits étaient sous votre contrôle, ni par des matériels
            logiciels tiers, non fournis par Norand qui auraient été connectés
            au produit.
          </p>
          <p className="paraTxtPrivacyPol">
            En cas d’échange ou de réparation, les frais de retour seront à la
            charge d’Norand.
          </p>
          <p className="paraTxtPrivacyPol">
            Si Norand n’est pas en mesure d’assurer la réparation ou l’échange
            des produits, vous avez la possibilité de demander la résiliation du
            contrat ou d’exiger une réduction du prix de vente des produits.
          </p>
          <p className="paraTxtPrivacyPol">
            La présente garantie s’applique sous réserve des droits conférés par
            les législations nationales en matière de protection des
            consommateurs. Norand reste tenue de la garantie légale de
            conformité prévue par votre législation nationale.
          </p>
          <p className="paraTxtPrivacyPol">
            Dans le cas d’une livraison en France, Norand reste tenue de la
            garantie légale de conformité mentionnée aux articles L. 217-4 à L.
            217-12 du Code de la consommation et de celle relative aux défauts
            de la chose vendue, dans les conditions prévues aux articles 1641 à
            1648 et 2232 du Code civil.
          </p>
          <p className="paraTxtPrivacyPol">
            Les articles L. 217-4, L. 217-5, L. 217-12 et L. 217-16 du Code de
            la Consommation ainsi que les articles 1641 et 1648 du Code civil
            sont reproduits ci-après : Article L217-4 du Code de la consommation
            :
          </p>
          <p className="paraTxtPrivacyPol">
            « Le vendeur livre un bien conforme au contrat et répond des défauts
            de conformité existant lors de la délivrance.
          </p>
          <p className="paraTxtPrivacyPol">
            Il répond également des défauts de conformité résultant de
            l'emballage, des instructions de montage ou de l'installation
            lorsque celle-ci a été mise à sa charge par le contrat ou a été
            réalisée sous sa responsabilité. »
          </p>
          <p className="paraTxtPrivacyPol">Article L217-5 du Code de la consommation</p>
          <p className="paraTxtPrivacyPol">« Le bien est conforme au contrat :</p>
          <p className="paraTxtPrivacyPol">
            1° S'il est propre à l'usage habituellement attendu d'un bien
            semblable et, le cas échéant :
          </p>
          <p className="paraTxtPrivacyPol">
            - s'il correspond à la description donnée par le vendeur et possède
            les qualités que celui-ci a présentées à l'acheteur sous forme
            d'échantillon ou de modèle ;
          </p>
          <p className="paraTxtPrivacyPol">
            - s'il présente les qualités qu'un acheteur peut légitimement
            attendre eu égard aux déclarations publiques faites par le vendeur,
            par le producteur ou par son représentant, notamment dans la
            publicité ou l'étiquetage ;
          </p>
          <p className="paraTxtPrivacyPol">
            2° Ou s'il présente les caractéristiques définies d'un commun accord
            par les parties ou est propre à tout usage spécial recherché par
            l'acheteur, porté à la connaissance du vendeur et que ce dernier a
            accepté. »
          </p>
          <p className="paraTxtPrivacyPol">Article L217-12 du Code de la consommation</p>
          <p className="paraTxtPrivacyPol">
            « L'action résultant du défaut de conformité se prescrit par deux
            ans à compter de la délivrance du bien. »
          </p>
          <p className="paraTxtPrivacyPol">Article L217-16 du Code de la consommation</p>
          <p className="paraTxtPrivacyPol">
            « Lorsque l'acheteur demande au vendeur, pendant le cours de la
            garantie commerciale qui lui a été consentie lors de l'acquisition
            ou de la réparation d'un bien meuble, une remise en état couverte
            par la garantie, toute période d'immobilisation d'au moins sept
            jours vient s'ajouter à la durée de la garantie qui restait à
            courir.
          </p>
          <p className="paraTxtPrivacyPol">
            Cette période court à compter de la demande d'intervention de
            l'acheteur ou de la mise à disposition pour réparation du bien en
            cause, si cette mise à disposition est postérieure à la demande
            d'intervention. »
          </p>
          <p className="paraTxtPrivacyPol">Article 1641 du Code civil</p>
          <p className="paraTxtPrivacyPol">
            « Le vendeur est tenu de la garantie à raison des défauts cachés de
            la chose vendue qui la rendent impropre à l'usage auquel on la
            destine, ou qui diminuent tellement cet usage que l'acheteur ne
            l'aurait pas acquise, ou n'en aurait donné qu'un moindre prix, s'il
            les avait connus. »
          </p>
          <p className="paraTxtPrivacyPol">Article 1648 du Code civil</p>
          <p className="paraTxtPrivacyPol">
            « L'action résultant des vices rédhibitoires doit être intentée par
            l'acquéreur dans un délai de deux ans à compter de la découverte du
            vice. »
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 6. Force majeure</p>
          <p className="paraTxtPrivacyPol">
            En cas de force majeure, de fait imprévisible et insurmontable d’un
            tiers au contrat ou de comportement fautif de l’acheteur, Norand se
            réserve le droit de suspendre l’exécution du présent contrat, ou de
            le résilier, sans qu’aucun dommage et intérêt ne puisse être
            réclamé.
          </p>
          <p className="paraTxtPrivacyPol">
            Constituent des cas de force majeure, toutes circonstances ou faits
            irrésistibles, imprévisibles et extérieures aux parties qui
            empêchent l’exécution du contrat dans des conditions normales. Sont
            notamment considérés comme des cas de force majeure, les incendies,
            blocages des moyens de transports ou d’approvisionnements, tempête,
            arrêt des réseaux de télécommunications.
          </p>
          <p className="paraTxtPrivacyPol">
            Dans ce cas, vous pouvez vous rapprocher d’Norand afin de savoir si
            nous procéderons à la livraison du produit dans un délai de
            raisonnable ou si le contrat sera résilié. En l’absence de réponse
            de notre part dans un délai d’un mois, vous pourrez résilier le
            contrat.
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 7. Communication et conservation du contrat</p>
          <p className="paraTxtPrivacyPol">
            Norand vous fournit, sur votre demande, un exemplaire du contrat
            constatant la vente du produit. Norand assure la conservation de ce
            contrat pendant une période de dix (10) ans à compter de la
            livraison du bien et garantit l’accès à l’acheteur à ce contrat à
            tout moment.
          </p>
          <p className="subHeadingPrivacyPolicy"> ARTICLE 8. Propriété intellectuelle</p>
          <p className="paraTxtPrivacyPol"> 
            Le contenu du site est la propriété d’Norand, seul titulaire des
            droits de propriété intellectuelle sur ce contenu.
          </p>
          <p className="paraTxtPrivacyPol">
            Vous vous engagez à ne pas reproduire ou utiliser tout ou partie de
            ce contenu.
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 9. Données personnelles</p>
          <p className="paraTxtPrivacyPol">
            Les modalités du traitement et de la collecte de vos données
            personnelles sont indiquées dans notre politique de confidentialité
            accessible à l’adresse suivante :
            https://www.norand.io/privacy-policy
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 10. Divisibilité</p>
          <p className="paraTxtPrivacyPol">
            Si une stipulation des présentes conditions générales de vente est
            annulée en tout ou en partie, la validité des autres dispositions ne
            sera pas affectée.
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 11. Médiation</p>
          <p className="paraTxtPrivacyPol">
            En cas de litige, vous pouvez recourir à un médiateur de la
            consommation en vue de la résolution amiable du litige dans les
            conditions prévues au titre Ier du livre VI du Code de la
            consommation ou à tout mode alternatif de règlement des différends.
          </p>
          <p className="subHeadingPrivacyPolicy">ARTICLE 12. Loi applicable et langue du contrat</p>
          <p className="paraTxtPrivacyPol">
            Les présentes conditions générales de vente sont régies par le droit
            français, à l’exclusion des dispositions de la Convention des
            Nations Unies sur les contrats de vente internationale de
            marchandises.
          </p>
          <p className="paraTxtPrivacyPol">
            Les présentes conditions générales de vente sont rédigées en langue
            française.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsCondi;
