import React from "react";
import leftPic from "./../../images/Morphic3DPic1.png";

import picNew from "./../../images/NEW.svg";
import "./ThreeDMorphingProduct.css";
import ThreeMorphingProductCard from "./ThreeMorphingProductCard";
const ThreeDMorphingProduct = () => {
  const DiscoverTxt = "Discover MORPHOS";

  const d3Text = "3D morphing at your fingertips";
  const mTxt = "MORPHOS";
  const mLink = "/morphos";

  return (
    <>
      <ThreeMorphingProductCard
        mTxt={mTxt}
        mLink={mLink}
        picNew={picNew}
        d3Text={d3Text}
        leftPic={leftPic}
        DiscoverTxt={DiscoverTxt}
      />
    </>
  );
};

export default ThreeDMorphingProduct;
