import React from 'react'
import "./Downloads.css";
import FooterNorand1 from '../../Components/FooterNorand/FooterNorand1'
import Header from '../../Components/Header/Header'
import ContactUs1 from './../../Components/ContactUs/ContactUs'
import { AiFillFilePdf, AiOutlineCloudDownload } from "react-icons/ai";

const DownloadPage = () => {
  return (
    <>
    <div className='downloadPage'>
    <Header/>
        <div backdrop-trigger="true"></div>
    <Header />
    <div className="main-bg">
      <h1 className="discover-heading">
        DOWN
        <span className="discover-heading-bold">LOADS</span>
      </h1>
    </div>
    <div className="download-container">
      <div className="about-section-1">
        <div>
          <a
            className="download-link"
            href="https://storage.googleapis.com/norand_public_files/mono_MK2_V1.1.3r.zip"
          >
            Mono Mk2 Firmware V1.1.3r    <AiOutlineCloudDownload
              size={"40px"}
              style={{ "padding-left": "10px" }}
            />
          </a>
        <br />
          <a
            className="download-link"
            href="https://storage.googleapis.com/norand_public_files/mono_V2.1.2r.zip"
          >
            Mono MK1 Firmware V2.1.2r     <AiOutlineCloudDownload
              size={"40px"}
              style={{ "padding-left": "10px" }}
            />
          </a>
        <br />
          {/* <a
            className="download-link"
            href="https://storage.googleapis.com/norand_public_files/Firmware_V1.2.3r.zip"
          >
            Morphos Firmware V1.2.3r    <AiOutlineCloudDownload
              size={"40px"}
              style={{ "padding-left": "10px" }}
            />
          </a> */}
        </div>
      </div>
    </div>
    <FooterNorand1/>
    </div>
    </>
  )
}

export default DownloadPage