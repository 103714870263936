import React from "react";
import "./TechinalSpecification.css";
import Icon from "./../../images/ArrowLongWhite.svg";
const TechinalSpecification = () => {
  const array = [
    {
      head: "Synth voice Features",
      sub: [
        {
          key: "",
          message:
            "Fully analog signal path\n2x analog oscillators\nContinuously variable waveform\nThru-zero frequency modulation\nHard-sync\nFilter overdrive\n1x 3-pole multi-mode filter\nContinuously variable filter color\n1x Main ADSR envelope",
          flag: true
        },
        {
          key: "",
          message: "",
          flag: false
        },
        {
          key: "",
          message: "",
          flag: false
        }
      ]
    },
    {
      head: "Modulation Features",
      sub: [
        {
          key: "Controls",
          message: "13x dedicated AD envelopes 20x dedicated modulator",
          flag: true
        },
        {
          key: "Modulator",
          message:
            "4 waveforms\nType settings : Free/Synced/Audio-Rate\nAudio-Rate anti-aliased, quantized to OSC1\nFree from 0.1 to 80 seconds",
          flag: true
        },
        {
          key: "Envelope",
          message: "Attack-Decay segment from 10us to 20s",
          flag: true
        }
      ]
    },
    {
      head: "Sequencer",
      sub: [
        {
          key: "",
          message:
            "Up to 64 step per pattern\nIndividual length/scale/beat-division per pattern\nIndividual microshift/ratchet/probability per\nstep\nPer step parameter automation (offsets)\nSwing/Slide/Accent\nCopy/paste anything\nUndo/Redo anything with 1024 events history\nMod Note mode\nFast, clock independent project Load/Save",
          flag: true
        },
        {
          key: "",
          message: "",
          flag: false
        },
        {
          key: "",
          message: "",
          flag: false
        }
      ]
    },

    {
      head: "Randomizer",
      sub: [
        {
          key: "",
          message:
            "Quandtized note randomizer\nRanged parameter automation randomizer\nStep randomizer\nMod note randomizer\nMicroshift/Probability/Ratchet randomizer",
          flag: true
        },
        {
          key: "",
          message: "",
          flag: false
        },
        {
          key: "",
          message: "",
          flag: false
        }
      ]
    },

    {
      head: "Storage",
      sub: [
        {
          key: "",
          message:
            "32 projects\n64 patterns with patch memory per projects\n3200 parameter automation per pattern\n40 mod note per projects",
          flag: true
        },
        {
          key: "",
          message: "",
          flag: false
        },
        {
          key: "",
          message: "",
          flag: false
        }
      ]
    },

    {
      head: "Hardware",
      sub: [
        {
          key: "",
          message:
            "23x transilluminated potentiometer\n24x illuminated silent mechanical switch\n23x 808-style switch\nMIDI In/Out/Thru\n1x 1/4” balanced main audio out jack\n2x 1/8” eurorack Clock/Reset input\n2x 1/8” eurorack CV outputs\n2x 1/8” eurorack Gate outputs\n48kHz internal CV D/A converters\nFlash-EEPROM upgradable OS\nMIDI class compliant USB-B port",
          flag: true
        },
        {
          key: "",
          message: "",
          flag: false
        },
        {
          key: "",
          message: "",
          flag: false
        }
      ]
    },
    {
      head: "Outputs",
      sub: [
        {
          key: "Balanced audio outputs",

          message: "Output level: +15dBu maximum\nOutput impedance: 200 Ohm",
          flag: true
        },
        {
          key: "CV outputs",
          message:
            "Voltage range: -8V/+8V\n1V/oct resolution : 3 cents of a semitone",
          flag: true
        },
        {
          key: "",
          message: "",
          flag: false
        }
      ]
    },
    {
      head: "Electrical Specification",
      sub: [
        {
          key: "",
          message:
            "Unit consumption: 10W typical\nHigh-quality power supply included\nRecommanded power supply: 15V DC, 1A",
          flag: true
        },
        {
          key: "",
          message: "",
          flag: false
        },
        {
          key: "",
          message: "",
          flag: false
        }
      ]
    },
    {
      head: "Physical Specifications",
      sub: [
        {
          key: "",
          message:
            " Machined aluminum front panel\nABS-PC back panel\nDimmensions: W320xD150xH42\nWeight: approx. 1.6kg ",
          flag: true
        },
        {
          key: "",
          message: "",
          flag: false
        },
        {
          key: "",
          message: "",
          flag: false
        }
      ]
    },
    {
      head: "Warranty",
      sub: [
        {
          key: "",
          message: "2-year Norand warranty",
          flag: true
        },
        {
          key: "",
          message: "",
          flag: false
        },
        {
          key: "",
          message: "",
          flag: false
        }
      ]
    }
  ];

  return (
    <>
      <div className="mainTechSpec">
        <div className="technicalSpecHeader">
          <img src={Icon} alt="" className="dropArrowTech" />

          <p className="txtTechSpec"> Technical Specifications</p>
        </div>
        {array.map((item, index) => {
          return (
            <div className="mainTechDetailPart" key={index}>
              <div className="topLineWhite"></div>
              <div className="textTechSpecDetailM">
                <p className="firstTxtTechSpec">{item.head}</p>
                <div className="w100Main">
                  {item.sub.map((data, i) => {
                    return (
                      <div
                        key={i}
                        className={data.flag === true ? "txtPad" : "none"}
                      >
                        <div className="pTopMediumTxt">
                          <p className="mediumTextTech">{data.key}</p>
                        </div>
                        <div className="pTopEndTxt">
                          <p className="txtEndTechSpec">{data.message}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TechinalSpecification;
