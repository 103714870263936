import React from "react";
import _ from "lodash";

import ApplyingMorphing from "../../Components/ApplyingMorphing/ApplyingMorphing";
import AudioMonoBlackish from "../../Components/AudioMonoBlackish/AudioMonoBlackish";
import MorphosInspiration from "../../Components/MorphosInspiration/MorphosInspiration";
import ContextualMorphosMod from "../../Components/ContextualMorphosMod/ContextualMorphosMod";
import ExpressiveKeyboard from "../../Components/ExpressiveKeyboard/ExpressiveKeyboard";
import FrontBackAngMorphos from "../../Components/FrontBackMorphos/FrontBackMorphos";
import Header from "../../Components/Header/Header";
import Intro3DMorphing from "../../Components/Intro3DMorphing/Intro3DMorphing";
import LoremIpsumMor from "../../Components/LoremIpsumMor/LoremIpsumMor";
import MorhphosTab from "../../Components/MorhphosTab/MorhphosTab";
import MorphosProduct3DMorphing from "../../Components/MorphosProduct3DMorphing/MorphosProduct3DMorphing";
import MorphosProductTab from "../../Components/MorphosProductTab/MorphosProductTab";
import MorphosVCO from "../../Components/MorphosVCO/MorphosVCO";
import SaveSpaceMorhpos from "../../Components/SaveSpaceMorhpos/SaveSpaceMorhpos";
import TechinalSpecificationMorphos from "../../Components/TechinalSpecificationMorphos/TechinalSpecificationMorphos";
import TZFMMorphos from "../../Components/TZFMMorphos/TZFMMorphos";
import FooterNorand1 from "../../Components/FooterNorand/FooterNorand1";
import NorandMonoMorphosProd from "../../Components/NorandMonoMorphosProd/NorandMonoMorphosProd";

import trigger from "../../ScrollTrigger";

class MorphosProduct extends React.Component {
  constructor(props) {
    super(props);
    // Set initial state (ONLY ALLOWED IN CONSTRUCTOR)
    this.state = {
      switchHeader: false,
      currentSection: 0,
    };
  }

  SwitchHeader(bb) {
    this.setState({
      switchHeader: bb,
    });
  }

  componentDidMount() {
    trigger.add("[switch-header-morphos-trigger]", {
      toggle: {
        callback: {
          in: (trigger) => {
            // `trigger` contains the Trigger object that goes out
            // of the viewport
            return new Promise((resolve, reject) => {
              this.SwitchHeader(false);
            });
          },
          out: (trigger) => {
            // `trigger` contains the Trigger object that goes out
            // of the viewport
            return new Promise((resolve, reject) => {
              this.SwitchHeader(true);
            });
          },
        },
      },
    });
    trigger.add("[section-trigger-morphos]", {
      toggle: {
        callback: {
          in: (trigger) => {
            return new Promise((resolve, reject) => {
              this.setState({
                currentSection: _.toNumber(
                  trigger.element.getAttribute("currentsection")
                ),
              });
            });
          },
          out: (trigger) => {
            return new Promise((resolve, reject) => {});
          },
        },
      },
    });
  }

  render() {
    return (
      <>
        <div backdrop-trigger="true"></div>
        {!this.state.switchHeader && <Header />}
        <div id="morphoshead" section-trigger-morphos="true" currentsection={0}>
          <MorphosProduct3DMorphing />
        </div>
        <MorphosProductTab
          fixed={this.state.switchHeader}
          currentSection={this.state.currentSection}
        />
        <div switch-header-morphos-trigger="true">
          <MorphosVCO />
        </div>
        <div
          id="morphostdmorphing"
          section-trigger-morphos="true"
          currentsection={1}
        >
          <Intro3DMorphing />
        </div>
        <ApplyingMorphing />
        <SaveSpaceMorhpos />
        <div
          id="morphoscontextual"
          section-trigger-morphos="true"
          currentsection={2}
        >
          <ContextualMorphosMod />
        </div>
        <MorhphosTab />
        <div className="topClaudeMor">
          <MorphosInspiration />
        </div>
        <LoremIpsumMor />
        <div
          id="morphososcillators"
          section-trigger-morphos="true"
          currentsection={3}
        >
          <TZFMMorphos />
        </div>
        <div hidden>
          <ExpressiveKeyboard />
          <AudioMonoBlackish />
        </div>
        <FrontBackAngMorphos />
        <TechinalSpecificationMorphos />

        <NorandMonoMorphosProd />

        <FooterNorand1 />

        {/* <VideoPlayer1/> */}
      </>
    );
  }
}

export default MorphosProduct;
